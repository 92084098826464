<div class="row mx-0">
  <div class="col-12 my-2">

    <div class="row mx-0 w-100">
      <div class="col-12 p-0">
        <span>{{ "Amministrazione" | translate }}</span>
        <h3 class="color-theme font-weight-bold">
          {{ "Gestione fornitori" | translate }}
        </h3>
      </div>
    </div>

    <div class="row mx-0 w-100">
      <div class="form-group col-1 p-0">
        <div>
          <label for="search">{{ "Cerca" | translate }}:</label>
          <input id="search" [(ngModel)]="searchValue" />
        </div>
      </div>

      <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>
    </div>

    <div class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Account</th>
                <th scope="col">Email</th>
                <th scope="col">URL Hech Romeo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let user of usersList | filter: searchValue; index as i"
                style="cursor: pointer"
              >
                <td
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(user.account)"
                >
                  {{ user.account }}
                </td>
                <td
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(user.account)"
                >
                  {{ user.email }}
                </td>
                <td
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(user.account)"
                >
                  {{ user.urlhechromeo }}
                </td>
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    width: 10%;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <div class="modifyImg" *ngIf="role == 'Vendor' && i == 0" 
                      (click)="infoUser(user.account)">
                      <svg
                        class="bi bi-info-circle"
                        width="30px"
                        height="30px"
                        viewBox="0 0 16 16"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                        />
                        <circle cx="8" cy="4.5" r="1" />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
