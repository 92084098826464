import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TYPOGRAPHY } from 'src/app/constants/typography.enum';
import { error } from 'protractor';
import { EncryptService } from 'src/app/services/utility/encrypt.service';
import {AccountDetailService} from '../../../services/account/account-detail.service';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  account = '';
  // account = 'alpitour-tanka1';
  password = '';
  email = '';
  isResetPassword = false;
  isChangePassword = false;
  TYPOGRAPHY = TYPOGRAPHY;
  showError = false;
  resetAccount = '';
  constructor(
    private router: Router,
    private authService: AuthService,
    private encryptService: EncryptService, private accountDetailService: AccountDetailService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {}

  login(event?) {
    if (event) this.password = event;
    sessionStorage.setItem('IgnoreErrorHech', '535');
    this.authService.login(this.account, this.password).subscribe(
      (data) => {
        sessionStorage.setItem('HechRomeoToken', data.token);
        localStorage.setItem('HechRomeoRole', data.role);

        // salvo dati utente con password criptata
        localStorage.setItem(
          'HechRomeoPassword',
          this.encryptService.encrypt(this.password)
        );
        localStorage.setItem('HechRomeoEmail', this.account);
        localStorage.setItem('HechRomeoAccount', data.vendor);
        switch (data.role) {
          case 'Manager':
            localStorage.removeItem('HechRomeoVendor');
            this.router.navigate(['vendorList']);
            break;
          case 'Vendor':
            localStorage.removeItem('HechRomeoVendor');
            this.router.navigate(['categoriesAndServices']);
            break;
          case 'User':
            this.accountService.getLinkedVendors(this.accountService.account).subscribe(res => {
              const vendorAccount = res.users[0].account;
              localStorage.setItem('HechRomeoVendor', vendorAccount);
            })
            this.router.navigate(['userInfo']);
            break;
          default:
            break;
        }
        // se sono admin NON mi salvo la variabile dell'account

        this.accountDetailService.setAccount(localStorage.getItem('HechRomeoAccount'))
      },
      (error) => {
        if (error.status == 535) {
          this.showChangePassword();
        }
      }
    );
  }

  resetPassword() {
    if (this.validateEmail(this.email) && this.resetAccount != '') {
      this.showError = false;
      this.authService.resetPassword(this.resetAccount, this.email).subscribe(
        (data) => {},
        (error) => {}
      );
    } else {
      this.showError = true;
    }
  }

  showResetPassword(t = true) {
    this.isResetPassword = t;
  }

  showChangePassword(t = true) {
    this.isChangePassword = t;
    this.showResetPassword(t);
  }

  validateEmail(email) {
    if (email) {
      const regex =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      return regex.test(email);
    }
    return false;
  }
}
