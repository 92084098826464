<div class="container-fluid my-2">
    <div class="row mx-0 w-100">
      <div class="col-12 p-0">
        <span>{{ "Gestione account" | translate }}</span>
        <h3 class="color-theme font-weight-bold">
          {{ "Anagrafica utente" | translate }}
        </h3>
      </div>
    </div>
    <div class="my-4">
      <div *ngIf="!admin">
        <span>Email: </span> <span>{{ vendorData.email }}</span>
      </div>
    </div>
  
    <form [formGroup]="registerForm">
      <div class="row mx-0 my-4">
        <div class="col-6 col-xl-4 mb-2">
          <div class="form-group">
            <label for="name">{{ "Nome" | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              formControlName="referrername"
              required
              (input)="
                registerForm.controls['referrername'].updateValueAndValidity()
              "
            />
            <ng-container
              *ngIf="
                registerForm.controls['referrername'].invalid &&
                (registerForm.controls['referrername'].dirty ||
                  registerForm.controls['referrername'].touched)
              "
            >
              <app-small-text
                [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
              ></app-small-text>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="lastName">{{ "Cognome" | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              name="lastName"
              formControlName="referrerlastname"
              required
              (input)="
                registerForm.controls['referrerlastname'].updateValueAndValidity()
              "
            />
            <ng-container
              *ngIf="
                registerForm.controls['referrerlastname'].invalid &&
                (registerForm.controls['referrerlastname'].dirty ||
                  registerForm.controls['referrerlastname'].touched)
              "
            >
              <app-small-text
                [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
              ></app-small-text>
            </ng-container>
          </div>
  
          <div class="info-button-box">
            <button
              type="button"
              class="pl-4 pr-4 btn btn-primary"
              (click)="updateVendorData()"
            >
              {{ "Salva" | translate }}
            </button>
          </div>
  
        </div>
  
        <!-- <div class="col-6 col-xl-4 mb-2">
          <div class="form-group">
            <label for="bannerbackgroundcolor">{{ "Colore Background" | translate }}</label>
            <input
              type="color"
              class="form-control w-25"
              id="bannerbackgroundcolor"
              name="bannerbackgroundcolor"
              formControlName="bannerbackgroundcolor"
              value="#ffffff"
              (input)="registerForm.controls['bannerbackgroundcolor'].updateValueAndValidity()"
            />
          </div>
          <div class="form-group">
            <label for="bannercolor">{{ "Colore testo titolo" | translate }}</label>
            <input
              type="color"
              class="form-control w-25"
              id="bannercolor"
              name="bannercolor"
              formControlName="bannercolor"
              value="#e66465"
              (input)="registerForm.controls['bannercolor'].updateValueAndValidity()"
            />
          </div>
          <div>
            <div class="mb-2">
              <img
              class="mediaobject-img"
              *ngIf="mediaobject"
                [src]="'data:image/png;base64,' + mediaobject"
                alt=""
              />
            </div>
  
            <div class="mb-3" style="display: flex; flex-direction: row">
              <input
                #image
                type="file"
                accept="image/png, image/jpeg"
                (change)="encodeImageFileAsURL( $event.target.files )"
              />
              <button
                type="button"
                (click)="deleteImage(image)"
                class="btn btn-danger ml-2"
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
          </div>
  
        </div> -->
      </div>
    </form>
  </div>
  