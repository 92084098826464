import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Channel } from 'src/app/models/tv/tv';
import { AccountService } from 'src/app/services/account/account.service';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-tv-channels',
  templateUrl: './tv-channels.component.html',
  styleUrls: ['./tv-channels.component.scss'],
})
export class TvChannelsComponent implements OnInit {
  channels: Array<Channel> = [];
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  copiedElement;
  contextElement;
  searchValue: string;
  channelToDelete;

  constructor(
    private tvService: TvService,
    private router: Router,
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.getChannels();
  }

  getChannels() {
    this.tvService.getChannels(this.accountService.vendor).subscribe((data) => {
      this.channels = data;
    });
  }

  editChannel(id) {
    this.router.navigate(['editChannel'], { queryParams: { id } });
  }

  deleteChannel() {
    this.tvService
      .deleteChannel(this.channelToDelete.id)
      .subscribe(() => {
        this.getChannels();
      });
  }

  //activates the menu with the coordinates
  onrightClick(event, element?, project?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    console.log('context menu', element);
    if (element) this.contextmenu = 'copy';
    else this.contextmenu = 'paste';
    this.contextElement = element;
    if (this.contextElement) {
      this.contextElement.vendor = localStorage.getItem('HechRomeoAccount');
      if (project) this.contextElement.project = true;
    }
  }

  disableContextMenu() {
    this.contextmenu = undefined;
  }
}
