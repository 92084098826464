import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/account/login/login.component';
import { RegisterComponent } from './pages/account/register/register.component';
import { AccountInfoComponent } from './pages/account/account-info/account-info.component';
import { ListComponent } from './pages/categories/list/list.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { GuestsFunctionalitiesComponent } from './pages/guests/guests-functionalities/guests-functionalities.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { MenageServicesComponent } from './pages/categories/list/components/menage-services/menage-services.component';
import { VendorListComponent } from './pages/admin/vendor-list/vendor-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CalendarComponent } from './pages/calendar/calendar-reservations/calendar.component';
import { SettingsComponent } from './pages/account/settings/settings.component';
import { SmallTextComponent } from './components/small-text/small-text.component';
import { ChangePasswordComponent } from './pages/account/login/components/change-password/change-password.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { ManageCategoriesVariablesComponent } from './pages/admin/manage-categories-variables/manage-categories-variables.component.component';
import { ManageLanguagesComponent } from './pages/admin/manage-categories-variables/components/manage-languages/manage-languages.component';
import { ManageCategoryTypesComponent } from './pages/admin/manage-categories-variables/components/manage-category-types/manage-category-types.component';
import { ManageIconsComponent } from './pages/admin/manage-categories-variables/components/manage-icons/manage-icons.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationMessageComponent } from './components/notification/notification-message/notification-message.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AdminListComponent } from './pages/account/admin-list/admin-list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CalendarAdvancedComponent } from './pages/calendar/calendar-advanced/calendar-advanced.component';
import { DatePipe } from '@angular/common';
import { CalendarReportComponent } from './pages/calendar/calendar-report/calendar-report.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SignageComponent } from './pages/categories/signage/signage.component';
import { CreateSignageComponent } from './pages/categories/create-signage/create-signage.component';
import { SafePipe } from './pipes/safe.pipe';
import { HeaderFooterComponent } from './components/header-footer/header-footer.component';
import { SelectServiceToBookComponent } from './components/select-service-to-book/select-service-to-book.component';
import { TvChannelsComponent } from './pages/tv/tv-channels/tv-channels.component';
import { ClonesComponent } from './pages/tv/clones/clones.component';
import { FirmwareComponent } from './pages/tv/firmware/firmware.component';
import { TvSettingsComponent } from './pages/tv/tv-settings/tv-settings.component';
import { TvListComponent } from './pages/tv/tv-list/tv-list.component';
import { CloneDetailsComponent } from './pages/tv/clone-details/clone-details.component';
import { FirmwareDetailsComponent } from './pages/tv/firmware-details/firmware-details.component';
import { TvChannelsDetailsComponent } from './pages/tv/tv-channels-details/tv-channels-details.component';
import { TvSettingsDetailsComponent } from './pages/tv/tv-settings-details/tv-settings-details.component';
import { TvDetailsComponent } from './pages/tv/tv-details/tv-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { DeleteButtonRendererComponent } from './components/delete-button-renderer/delete-button-renderer';
import {
  AgendaService,
  DayService,
  MonthService,
  ScheduleModule, TimelineMonthService,
  TimelineViewsService, TimelineYearService,
  WeekService,
  WorkWeekService
} from '@syncfusion/ej2-angular-schedule';
import { DownloadPdfModule } from './pages/categories/download-pdf/download-pdf.module';
import { AssistanceComponent } from './pages/assistance/assistance.component';
import { WebServicesComponent } from './pages/web-services/web-services.component';
import { AddWsProjectComponent } from './pages/web-services/add-ws-project/add-ws-project.component';
import { LinkedVendorsListComponent } from './pages/account/vendors-list/linked-vendors-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersListComponent } from './pages/account/users-list/users-list.component';
import { SharedModule } from './shared/shared.module';
import { UsersManagementComponent } from './pages/admin/users-management/users-management.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordGeneratorComponent } from './components/password-generator/password-generator.component';
import { AdminUserListComponent } from './pages/admin/admin-user-list/admin-user-list.component';
import { RegisterUserComponent } from './pages/account/register-user/register-user.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UserInfoComponent } from './pages/account/user-info/user-info.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AccountInfoComponent,
    ListComponent,
    GuestsFunctionalitiesComponent,
    CalendarComponent,
    LayoutComponent,
    MenageServicesComponent,
    VendorListComponent,
    SettingsComponent,
    SmallTextComponent,
    ChangePasswordComponent,
    TermsOfServiceComponent,
    ManageCategoriesVariablesComponent,
    ManageLanguagesComponent,
    ManageCategoryTypesComponent,
    ManageIconsComponent,
    NotificationComponent,
    NotificationMessageComponent,
    AdminListComponent,
    CalendarAdvancedComponent,
    LoadingComponent,
    CalendarReportComponent,
    SafeHtmlPipe,
    SignageComponent,
    CreateSignageComponent,
    SafePipe,
    HeaderFooterComponent,
    TvChannelsComponent,
    ClonesComponent,
    FirmwareComponent,
    TvSettingsComponent,
    TvListComponent,
    CloneDetailsComponent,
    FirmwareDetailsComponent,
    TvChannelsDetailsComponent,
    TvSettingsDetailsComponent,
    TvDetailsComponent,
    SelectServiceToBookComponent,
    DeleteButtonRendererComponent,
    AssistanceComponent,
    WebServicesComponent,
    AddWsProjectComponent,
    UsersListComponent,
    LinkedVendorsListComponent,
    UsersManagementComponent,
    PasswordGeneratorComponent,
    AdminUserListComponent,
    RegisterUserComponent,
    UserInfoComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AgGridModule,
    EditorModule,
    ReactiveFormsModule,
    NgxYoutubePlayerModule,
    Ng2SearchPipeModule,
    DownloadPdfModule,
    MatDialogModule,
    BrowserAnimationsModule,
    SharedModule,
    MatTooltipModule,
    MatAutocompleteModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      // defaultLanguage: 'en',
    }),
    TranslateModule,
    ScheduleModule
  ],
  exports: [TranslateModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    AgendaService, DayService, WeekService, WorkWeekService, MonthService, TimelineViewsService, TimelineMonthService, TimelineYearService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
