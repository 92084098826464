<div class="container-fluid w-100 p-0">
  <div class="row mx-0 w-100">
    <div class="col-12 py-2">
      <span (click)="endMenageService()" style="cursor: pointer">
        < {{ "Torna alla lista" | translate }}
      </span>
    </div>
  </div>

  <div class="row mx-0 w-100">
    <div class="col-12 col-xl-6">
      <div class="row mx-0 w-100">
        <div class="col-6 pl-0 form-group">
          <label for="button">{{ "Aggiungi Lingua" | translate }}</label>
          <button
            data-toggle="modal"
            data-target="#languageModal"
            class="pl-4 pr-4 btn btn-secondary"
            style="font-size: 12px; width: 100%; height: 38px"
            (click)="emitFilterLanguages()"
          >
            + {{ "Aggiungi Lingua" | translate }}
          </button>
        </div>

        <div class="col-6 pr-0 form-group">
          <label for="categorytype">{{ "Seleziona lingua" | translate }}</label>
          <div style="display: flex; flex-direction: row">
            <select
              [(ngModel)]="currentLanguage"
              class="form-control"
              name="categorytype"
            >
              <option
                *ngFor="let element of activatedLanguages"
                [selected]="element.abr === currentLanguage"
                [value]="element.abr"
              >
                {{ element.language }}
              </option>
            </select>
            <button (click)="deleteLanguage()" class="btn btn-danger ml-2">
              {{ "Elimina" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="title">{{ "Nome" | translate }}</label>
          <input
            [(ngModel)]="service.title[currentLanguage]"
            type="text"
            class="form-control"
            id="title"
            name="title"
            placeholder="Inserici il nome del servizio"
          />
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="description">{{ "Descrizione" | translate }}</label>
          <editor
            name="description"
            apiKey="1sdv0g430kwfa3fbpe8x98vr7eyu3x9il416pnmm3mjo3s52"
            [init]="{
              font_formats: 'Barlow=Barlow; Roboto=Roboto; Barlow Condensed=Barlow-condensed; Gilda Display=Gilda-display; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MSX=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
              plugins: ['link image hr ']
            }"
            [(ngModel)]="service.description[currentLanguage]"
            (onInit)="onInitEditor()"
          ></editor>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="title">{{ "Nota" | translate }}</label>
          <input
            [(ngModel)]="service.mlNote[currentLanguage]"
            type="text"
            class="form-control"
            id="noteForReservation"
            name="noteForReservation"
            placeholder="Inserici nota per il cliente"
          />
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="">{{ "Tipologia servizio" | translate }}</label>
          <div>
            <span>{{ "Selezionare servizio" | translate }}</span>

            <div class="form-check">
              <input
                [value]="false"
                [(ngModel)]="service.isAdvanced"
                type="radio"
                class="form-check-input"
                id="isadvanced"
                name="isadvanced"
              />
              <label for="form-check-label">Standard</label>
              <small>
                ({{
                  "Gestione dei servizi liberi, esempio: Informazioni Generiche, Menù Ristoranti, Listino Spa, Acquisto Prodotti, Servizi, Escursioni, Room Service, Delivery"
                    | translate
                }}.)</small
              >
            </div>
            <div class="form-check">
              <input
                [value]="true"
                [(ngModel)]="service.isAdvanced"
                (change)="service.serviceType = 1"
                type="radio"
                class="form-check-input"
                id="isadvanced"
                name="isadvanced"
              />
              <label for="form-check-label">{{ "Avanzato" | translate }}</label>
              <small>
                ({{
                  "Gestione del servizio a periodi e fasce orarie di disponibilità, esempi: Prenotazione Tavoli, Servizi Spiaggia, Attrezzature Sportive, Noleggio Bici e Automezzi"
                    | translate
                }}.)</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="refId">{{
            "Scegli il reparto a cui affidare la gestione del servizio"
              | translate
          }}</label>
          <br />

          <small class="my-3">
            {{
              "Il reparto deve indicare a quale indirizzo E-Mail o Contatto inviare la notifica. Puoi inserire i vari reparti in"
                | translate
            }}
            <b>{{ "Gestione Account - Impostazioni" | translate }}</b
            >.
          </small>
          <div class="form-group">
            <div class="form-check">
              <input
                [(ngModel)]="sendNotification"
                type="checkbox"
                class="form-check-input"
                id="label"
                name="label"
              />
              <label class="form-check-label">{{
                "Abilita notifiche" | translate
              }}</label>
            </div>
            <small>
              {{
                "Se questo parametro non è selezionato, verrà bloccato l’invio delle notifiche per email di questo servizio"
                  | translate
              }}
            </small>
            <br />
            <span>{{ "Aggiungi reparto" | translate }}</span>
            <div class="d-flex flex-wrap">
              <div class="col-4" *ngFor="let element of service.departments">
                <div class="department">
                  <span> {{ displayDepartment(element) }} </span>

                  <span (click)="removeDepartment(element.iddepartments)"
                    >-</span
                  >
                </div>
              </div>
              <div class="col-1">
                <button
                  class="btn btn-secondary"
                  style="font-size: 18px"
                  data-toggle="modal"
                  data-target="#departmentModal"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="refId">{{
            "Inserisci attributo etichetta del servizio" | translate
          }}</label>
          <br />
          <small>
            {{
              "L’ etichetta deve indicare le modalità di fruizione o consegna del prodotto o servizio, come per esempio Numero di Tavolo, Numero di Ombrellone, Indirizzo di Consegna ecc. Puoi inserire le varie etichette in"
                | translate
            }}
            <b>{{ "Gestione Account - Impostazioni" | translate }}</b>
          </small>
          <br />
          <div class="d-flex flex-row form-check align-items-center">
            <div class="">
              <span>{{
                "Scegli tra le etichette del servizio disponibili" | translate
              }}</span>
              <div class="d-flex flex-wrap">
                <div class="col-5" *ngFor="let element of service.labels">
                  <div class="department">
                    <span>
                      {{ displayLabel(element.labelid ? element.labelid : element.id)}}{{ element.mandatory ? "*" : "" }}
                    </span>

                    <span (click)="removeLabel(element.labelid ? element.labelid : element.id)">-</span>
                  </div>
                </div>
                <div class="col-1">
                  <button
                    class="btn btn-secondary"
                    style="font-size: 18px"
                    data-toggle="modal"
                    data-target="#labelModal"
                  >
                    +
                  </button>
                </div>
              </div>
              <small>* {{ "Campo obbligatorio" | translate }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="orientamento">{{
            "Orientamento media desktop" | translate
          }}</label>
          <div>
            <span>{{
              "Orientamento immagini e video nella versione desktop" | translate
            }}</span>

            <div class="d-flex">
              <div class="col-6 p-0">
                <div class="form-check">
                  <input
                    [value]="0"
                    [(ngModel)]="service.alignment"
                    type="radio"
                    class="form-check-input"
                    id="orientamento"
                    name="orientamento"
                  />
                  <label for="form-check-label">{{
                    "Sinistra" | translate
                  }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="1"
                    [(ngModel)]="service.alignment"
                    type="radio"
                    class="form-check-input"
                    id="orientamento"
                    name="orientamento"
                  />
                  <label for="form-check-label">{{
                    "Destra" | translate
                  }}</label>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="form-check">
                  <input
                    [value]="2"
                    [(ngModel)]="service.alignment"
                    type="radio"
                    class="form-check-input"
                    id="orientamento"
                    name="orientamento"
                  />
                  <label for="form-check-label">{{
                    "Sopra" | translate
                  }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="3"
                    [(ngModel)]="service.alignment"
                    type="radio"
                    class="form-check-input"
                    id="orientamento"
                    name="orientamento"
                  />
                  <label for="form-check-label">{{
                    "Sotto" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 mb-3 w-100">
        <div class="col-12 p-0">
          <label for="image">{{ "Foto o Video" | translate }}</label>
          <small>(Max 50Mb)</small>
          <div>
            <!-- titpi 1: immagine, 2: video, 3: icona e string in b64-->
            <div>
              <div class="form-check">
                <input
                  [value]="1"
                  [(ngModel)]="service.imageType"
                  (change)="fileToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="imagetype"
                  name="imagetype"
                />
                <label for="form-check-label">{{
                  "Immagine" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="2"
                  [(ngModel)]="service.imageType"
                  (change)="fileToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="imagetype"
                  name="imagetype"
                />
                <label for="form-check-label">{{ "Video" | translate }}</label>
              </div>
            </div>
            <div *ngIf="service.imageType == 1">
              <div *ngFor="let element of imageToLoad; index as i">
                <div class="mb-2">
                  <img
                    *ngIf="element.image"
                    [src]="'data:image/jpg;base64,' + element.image"
                    style="width: 450px"
                    alt=""
                  />
                </div>
                <div class="mb-3" style="display: flex; flex-direction: row">
                  <input
                    type="file"
                    accept=".jpg"
                    (change)="encodeImageFileAsURL($event.target.files, 1, i)"
                  />
                  <button
                    [disabled]="!element.image"
                    type="button"
                    (click)="deleteImage(i)"
                    class="btn btn-danger ml-2"
                  >
                    {{ "Elimina" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="service.imageType == 2">
            <div class="mb-3">
              <youtube-player
                [ngClass]="{ hideElement: id == '' || id == undefined }"
                id="playerService"
                (ready)="loadPlayer()"
              >
              </youtube-player>

              <video
                [ngClass]="{ hideElement: id != '' && id != undefined }"
                width="450px"
                controls
                *ngIf="videoToLoad"
                [src]="'data:video/mp4;base64,' + videoToLoad"
              >
              </video>
            </div>
            <div class="youtubeInput">
              <div class="row mx-0 w-100">
                <div class="col-8 p-0">
                  <input
                    [(ngModel)]="url"
                    type="text"
                    class="form-control"
                    id="url"
                    name="url"
                    placeholder="Inserisci un link di youtube"
                  />
                </div>
                <div class="col-4 p-0">
                  <button
                    [disabled]="url == ''"
                    type="button"
                    class="ml-2 mr-2 btn btn-secondary"
                    (click)="loadNewYoutubeVideo()"
                  >
                    Carica
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    (click)="deleteYoutubeVideo()"
                    [disabled]="playerLoaded == false"
                  >
                    {{ "Elimina" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <small *ngIf="urlError" class="errorUrl">{{
              "L'url non è valida" | translate
            }}</small>
            <div class="mt-2 mb-2">
              <span>{{ "Oppure" | translate }}</span>
            </div>

            <div style="display: flex; flex-direction: row">
              <input
                type="file"
                accept=".mp4"
                (change)="encodeImageFileAsURL($event.target.files, 2)"
              />
              <button
                [disabled]="!videoToLoad"
                type="button"
                (click)="
                  videoToLoad = undefined;
                  service.imageType = undefined;
                  service.image = undefined
                "
                class="btn btn-danger ml-2"
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
            <div>
              <label for="image">{{ "Live Stream" | translate }}</label>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.liveStream"
                  type="radio"
                  class="form-check-input"
                  id="livestream"
                  name="livestream"
                />
                <label for="form-check-label">{{ "Sì" | translate }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.liveStream"
                  type="radio"
                  class="form-check-input"
                  id="livestream"
                  name="livestream"
                />
                <label for="form-check-label">{{ "No" | translate }}</label>
              </div>
            </div>
          </div>
          <div style="height: 100px" *ngIf="!service.imageType"></div>
        </div>
      </div>
    </div>

    <div *ngIf="service.isAdvanced == true" class="col-12 col-xl-6">
      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Servizio avanzato" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se il servizio è prenotabile singolarmente o a carrello"
                    | translate
                }}
              </span>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.cartEnabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">{{
                  "Carrello" | translate
                }}</label>
              </div>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.cartEnabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">{{
                  "Singolo" | translate
                }}</label>
              </div>
              <!--               <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Carrello</label>
              </div> -->
              <!--       <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Singolo</label>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 form-group">
        <div *ngIf="!service.cartEnabled">
              <span>
                {{
                "Selezionare se questo servizio necessita di accettazione manuale oppure se si desidera renderlo accettato di default"
                  | translate
                }}.
              </span>
          <div class="form-check">
            <input
              [value]="false"
              [(ngModel)]="service.accepted"
              type="radio"
              class="form-check-input"
              id="accepted1"
              name="accepted1"
            />
            <label for="accepted1">{{
              "Accetta manualmente" | translate
              }}</label>
          </div>
          <div class="form-check">
            <input
              [value]="true"
              [(ngModel)]="service.accepted"
              type="radio"
              class="form-check-input"
              id="accepted2"
              name="accepted2"
            />
            <label for="accepted1">{{
              "Accetta di default" | translate
              }}</label>
          </div>
          <small
            *ngIf="serviceErrorMessage?.servicetypeError"
            class="error"
          >{{ serviceErrorMessage?.servicetypeError?.label }}</small
          >
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="amount">{{ "Modalità di pagamento" | translate }}</label>

          <div class="form-check">
            <input
              [value]="0"
              [(ngModel)]="service.paymentType"
              type="radio"
              class="form-check-input"
              id="paymenttype1"
              name="paymenttype1"
            />
            <label for="paymenttype1">{{
              "Il servizio è gratuito" | translate
            }}</label>
          </div>

          <div class="checkPayment">
            <div class="form-check">
              <input
                [value]="1"
                [(ngModel)]="service.paymentType"
                type="radio"
                class="form-check-input"
                id="paymenttype2"
                name="paymenttype2"
                style="pointer-events: none; opacity: 0.7"
              />
              <label for="paymenttype2" style="opacity: 0.7">{{
                "Pagamento carta di credito" | translate
              }}</label>
            </div>

            <div class="form-check">
              <input
                [value]="2"
                [(ngModel)]="service.paymentType"
                type="radio"
                class="form-check-input"
                id="paymenttype3"
                name="paymenttype3"
              />
              <label for="paymenttype3">{{
                "Pagamento alla Reception" | translate
              }}</label>
              <small
                >({{
                  "I valori vanno indicati successivamente nella fascia oraria"
                    | translate
                }})</small
              >
            </div>
          </div>

          <!-- <div *ngIf="service.paymenttype != 0" class="form-group">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-uppercase">&euro;</span>
              <input
                [(ngModel)]="service.amount"
                [disabled]="service.paymenttype == 0"
                type="number"
                class="form-control"
                id="amount"
                name="amount"
                min="0"
              />
            </div>
            <small *ngIf="serviceErrorMessage?.amountError" class="error">
              {{ serviceErrorMessage?.amountError?.label }}
            </small>
          </div> -->
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Multifascia" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se è possibile prenotare più di una fascia oraria o no"
                    | translate
                }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.isMultiband"
                  type="radio"
                  class="form-check-input"
                  id="ismultiband1"
                  name="ismultiband1"
                />
                <label for="ismultiband1">{{
                  "Singola fascia" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.isMultiband"
                  type="radio"
                  class="form-check-input"
                  id="ismultiband2"
                  name="ismultiband2"
                />
                <label for="ismultiband2">{{
                  "Multifascia" | translate
                }}</label>
              </div>
              <!--               <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Carrello</label>
              </div> -->
              <!--       <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Singolo</label>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Multiperiodo" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se è possibile prenotare più di una giornata o no"
                    | translate
                }}
              </span>

              <div class="form-check">
                <input
                  [disabled]="service.cartEnabled"
                  [value]="false"
                  [(ngModel)]="service.periodBooking"
                  type="radio"
                  class="form-check-input"
                  id="ismultiperiod1"
                  name="ismultiperiod1"
                />
                <label for="ismultiperiod1">{{
                  "Singolo giorno" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [disabled]="service.cartEnabled"
                  [value]="true"
                  [(ngModel)]="service.periodBooking"
                  type="radio"
                  class="form-check-input"
                  id="ismultiperiod2"
                  name="ismultiperiod2"
                />
                <label for="ismultiperiod2">{{
                  "Multiperiodo" | translate
                }}</label>
              </div>
              <!--               <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Carrello</label>
              </div> -->
              <!--       <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.cartenabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">Singolo</label>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="">{{ "Etichetta quantità" | translate }}</label>
          <div>
            <div class="form-check">
              <input
                [value]="false"
                [(ngModel)]="service.isguest"
                type="radio"
                class="form-check-input"
                id="isguest1"
                name="serviceisguest1"
              />
              <label for="isguest1">{{
                "Quantità" | translate
                }}</label>
            </div>
            <div class="form-check">
              <input
                [value]="true"
                [(ngModel)]="service.isguest"
                type="radio"
                class="form-check-input"
                id="isguest2"
                name="serviceisguest2"
              />
              <label for="isguest2">{{
                "Persona" | translate
                }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Lista periodi" | translate }}</label>
            <br />
            <small class="mb-2"
              >{{
                "Il periodo indica una determinata fascia di tempo ( giornata - mese - stagione - anni ) nella quale sarà disponibile il servizio"
                  | translate
              }}.</small
            >
            <div>
              <span> {{ "Crea un nuovo periodo" | translate }} </span>
              <div>
                <div class="col-1">
                  <button
                    (click)="editPeriod()"
                    class="btn btn-secondary"
                    style="font-size: 18px"
                    data-toggle="modal"
                    data-target="#periodModal"
                  >
                    +
                  </button>
                </div>
              </div>
              <div *ngIf="advancedService" class="mt-2">
                <div
                  *ngFor="let element of advancedService.periods; let i = index"
                >
                  <div *ngIf="!element.delete" class="card">
                    <div class="card-body">
                      <strong>{{ "Da" | translate }}:</strong>
                      {{ element.from }}, <br />
                      <strong>{{ "A" | translate }}:</strong> {{ element.to }},
                      <br />
                      <strong>Note:</strong> {{ element.perioddata.note }},
                      <br />
                      <strong>{{ "Quantità" | translate }}:</strong>
                      {{
                        element.perioddata.enableGlobalQty
                          ? element.perioddata.maxservicequantity
                          : element.perioddata.enableSlotQty
                          ? "Quantità per fascia oraria"
                          : "Quantità disabilitata"
                      }}
                      <br />
                      <div>
                        <button
                          class="pl-4 pr-4 m-2 btn btn-primary"
                          style="font-size: 12px"
                          (click)="editPeriod(element, false, i)"
                          data-toggle="modal"
                          data-target="#periodModal"
                        >
                          {{ "Modifica" | translate }}
                        </button>
                        <button
                          class="pl-4 pr-4 m-2 btn btn-primary"
                          style="font-size: 12px"
                          (click)="editPeriod(element, true)"
                          data-toggle="modal"
                          data-target="#timeSlotsModal"
                        >
                          {{ "Fascia oraria" | translate }}
                        </button>
                        <!--  -->
                        <button
                          class="pl-4 pr-4 m-2 btn btn-primary"
                          style="font-size: 12px"
                          (click)="
                            openModalWithavailablityAdvancedServices(element)
                          "
                          data-toggle="modal"
                          data-target="#bloccafasciaOraria"
                        >
                          {{ "Blocca fascia oraria" | translate }}
                        </button>
                        <!--  -->
                        <button
                          class="pl-4 pr-4 m-2 btn btn-danger"
                          style="font-size: 12px"
                          (click)="deletePeriod(element, i)"
                        >
                          {{ "Elimina" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tools">
        <button
          class="pl-4 pr-4 m-2 btn btn-primary"
          style="font-size: 12px"
          (click)="saveMenageService()"
        >
          {{ "Salva" | translate }}
        </button>
        <button
          class="pl-4 pr-4 btn btn-primary"
          style="font-size: 12px"
          (click)="endMenageService()"
        >
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="service.isAdvanced == false" class="col-12 col-xl-6">
      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Servizio standard" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se il servizio è prenotabile singolarmente o a carrello"
                    | translate
                }}
              </span>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.cartEnabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">{{
                  "Carrello" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.cartEnabled"
                  type="radio"
                  class="form-check-input"
                  id="cartenabled"
                  name="cartenabled"
                />
                <label for="form-check-label">{{
                  "Singolo" | translate
                }}</label>
              </div>
            </div>
            <div>
              <span>
                {{
                  "Selezionare se questo servizio necessita solamente di una descrizione informativa oppure se si desidera renderlo prenotabile"
                    | translate
                }}.
              </span>
              <div class="form-check">
                <input
                  [value]="0"
                  [(ngModel)]="service.serviceType"
                  (ngModelChange)="changedServicetype($event)"
                  type="radio"
                  class="form-check-input"
                  id="servicetype1"
                  name="servicetype"
                />
                <label for="servicetype1">{{
                  "Informativo" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="1"
                  [(ngModel)]="service.serviceType"
                  (ngModelChange)="changedServicetype($event)"
                  type="radio"
                  class="form-check-input"
                  id="servicetype2"
                  name="servicetype"
                />
                <label for="servicetype2">{{
                  "Prenotabile" | translate
                }}</label>
              </div>
              <small
                *ngIf="serviceErrorMessage?.servicetypeError"
                class="error"
                >{{ serviceErrorMessage?.servicetypeError?.label }}</small
              >
            </div>

            <div *ngIf="!service.cartEnabled && service.serviceType == 1">
              <span>
                {{
                "Selezionare se questo servizio necessita di accettazione manuale oppure se si desidera renderlo accettato di default"
                  | translate
                }}.
              </span>
              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="service.accepted"
                  type="radio"
                  class="form-check-input"
                  id="accepted1"
                  name="accepted1"
                />
                <label for="accepted1">{{
                  "Accetta manualmente" | translate
                  }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="service.accepted"
                  type="radio"
                  class="form-check-input"
                  id="accepted2"
                  name="accepted2"
                />
                <label for="accepted1">{{
                  "Accetta di default" | translate
                  }}</label>
              </div>
              <small
                *ngIf="serviceErrorMessage?.servicetypeError"
                class="error"
              >{{ serviceErrorMessage?.servicetypeError?.label }}</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="amount">{{ "Modalità di pagamento" | translate }}</label>

          <div class="form-check">
            <input
              [value]="0"
              [(ngModel)]="service.paymentType"
              type="radio"
              class="form-check-input"
              id="paymenttype1a"
              name="paymenttype1a"
            />
            <label for="paymenttype1a">{{
              "Il servizio è gratuito" | translate
            }}</label>
          </div>

          <ng-container *ngIf="service.serviceType === 1; else elseTemplate">
            <div class="checkPayment">
              <div class="form-check">
                <input
                  [value]="1"
                  [(ngModel)]="service.paymentType"
                  type="radio"
                  class="form-check-input"
                  id="paymenttype2a"
                  name="paymenttype2a"
                  style="pointer-events: none"
                />
                <label for="paymenttype2a" style="opacity: 0.7">{{
                  "Pagamento carta di credito" | translate
                }}</label>
              </div>

              <div class="form-check">
                <input
                  [value]="2"
                  [(ngModel)]="service.paymentType"
                  type="radio"
                  class="form-check-input"
                  id="paymenttype3a"
                  name="paymenttype3a"
                />
                <label for="paymenttype3a">{{
                  "Pagamento alla Reception" | translate
                }}</label>
                <small
                  >({{
                    "I valori vanno indicati successivamente nella fascia oraria"
                      | translate
                  }})</small
                >
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="checkPayment">
              <!--    <div class="form-check">
                <input
                  [value]="1"
                  [(ngModel)]="service.paymenttype"
                  type="radio"
                  class="form-check-input"
                  id="paymenttype"
                  name="paymenttype"
                  style="pointer-events: none"
                />
                <label for="form-check-label" style="opacity: 0.7"
                  >Pagamento carta di credito</label
                >
              </div> -->

              <div class="form-check">
                <input
                  [value]="2"
                  [(ngModel)]="service.paymentType"
                  type="radio"
                  class="form-check-input"
                  id="paymenttype"
                  name="paymenttype"
                />
                <label for="paymenttype">{{
                  "Prezzo informativo" | translate
                }}</label>
              </div>
            </div>
          </ng-template>

          <div *ngIf="service.paymentType != 0" class="form-group">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-uppercase">&euro;</span>
              <input
                [(ngModel)]="service.amount"
                [disabled]="service.paymentType == 0"
                type="number"
                class="form-control"
                id="amount"
                name="amount"
                min="0"
              />
            </div>
            <small *ngIf="serviceErrorMessage?.amountError" class="error">
              {{ serviceErrorMessage?.amountError?.label }}
            </small>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0 form-group">
          <label for="">{{
            "Restrizione della Visualizzazione e della prenotazione di questo servizio per gli utenti"
              | translate
          }}</label>
          <div>
            <span *ngIf="!service.categoryCalendar">{{
              "L’utente potrà visualizzare e prenotare il servizio solamente nel periodo indicato nella restrizione"
                | translate
            }}</span>
            <span *ngIf="service.categoryCalendar">{{
              "L’utente potrà visualizzare sempre il servizio, ma prenotarlo solamente nel periodo indicato nella restrizione"
                | translate
            }}</span>
            <br />
            <span>{{ "Selezionare data di inizio e fine" | translate }}</span>
            <div style="display: flex; flex-direction: row">
              <div class="form-group">
                <label for="availabledate">{{ "inizio" | translate }}</label>
                <input
                  [(ngModel)]="service.availableDate"
                  type="date"
                  class="form-control"
                  id="availabledate"
                  name="availabledate"
                />
              </div>
              <div class="form-group">
                <label for="expirationdate">{{ "fine" | translate }}</label>
                <input
                  [(ngModel)]="service.expirationDate"
                  type="date"
                  class="form-control"
                  id="expirationdate"
                  name="expirationdate"
                  min="{{ service.availableDate }}"
                />
              </div>
            </div>
            <small *ngIf="serviceErrorMessage?.dateError" class="error">
              {{ serviceErrorMessage?.dateError?.label }}</small
            >
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <!--  <label for="">Restrizione Orario</label> -->
          <div>
            <span>{{ "Selezionare orario di inizio e fine" | translate }}</span>
            <div style="display: flex; flex-direction: row">
              <div class="form-group">
                <label for="availabletime">{{ "inizio" | translate }}</label>
                <input
                  [(ngModel)]="service.availableTime"
                  type="time"
                  class="form-control"
                  id="availabletime"
                  name="availabletime"
                />
              </div>
              <div class="form-group">
                <label for="expirationtime">{{ "fine" | translate }}</label>
                <input
                  [(ngModel)]="service.expirationTime"
                  type="time"
                  class="form-control"
                  id="expirationtime"
                  name="expirationtime"
                />
              </div>
            </div>
            <small *ngIf="serviceErrorMessage?.timeError" class="error">
              {{ serviceErrorMessage?.timeError?.label }}</small
            >
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="">{{
            "Desideri che questo Servizio Prenotabile abbia un quantitativo massimo disponibile?"
              | translate
          }}</label>
          <div>
            <span>{{
              "Scegli se abilitare o no la quantità massima" | translate
            }}</span>

            <div class="form-check">
              <input
                [value]="false"
                [(ngModel)]="service.enableQty"
                (click)="changeEnableQty(false)"
                type="radio"
                class="form-check-input"
                id="enableqty"
                name="enableqty"
              />
              <label for="form-check-label">{{ "No" | translate }}</label>
            </div>

            <div class="form-check">
              <input
                [value]="true"
                [(ngModel)]="service.enableQty"
                (click)="changeEnableQty(true)"
                type="radio"
                class="form-check-input"
                id="enableqty"
                name="enableqty"
              />
              <label for="form-check-label">{{
                "Sì (Giornaliero)" | translate
              }}</label>
              <small>
                {{
                  "Ogni giorno la quantità massima disponibile torna al valore indicato"
                    | translate
                }}.</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 mb-3 w-100" *ngIf="service.enableQty === true">
        <div class="col-12 p-0">
          <label for="maxquantity">{{ "Quantità" | translate }}</label>
          <div>
            <span>{{
              "Specificare la quantità del servizio" | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="service.maxQuantity"
            [disabled]="!service.enableQty"
            type="number"
            class="form-control"
            id="maxquantity"
            name="maxquantity"
            min="{{ service.enableQty ? 1 : 0 }}"
            value="{{
              service.enableQty
                ? service.maxQuantity
                  ? service.maxQuantity
                  : 1
                : 0
            }}"
          />
          <small *ngIf="serviceErrorMessage?.quantityError" class="error">
            {{ serviceErrorMessage?.quantityError?.label }}
          </small>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="">{{ "Quantità a scalare" | translate }}</label>
          <div>
            <span>{{
              "La quantità del servizio è a scalare?" | translate
            }}</span>
            <div class="form-check">
              <input
                [value]="true"
                [disabled]="!service.enableQty"
                [(ngModel)]="service.decreaseQuantity"
                type="radio"
                class="form-check-input"
                id="servicedecresequantity1"
                name="servicedecresequantity"
              />
              <label for="servicedecresequantity1">{{
                "Sì" | translate
              }}</label>
              <small>
                {{
                  "Il servizio non sarà più prenotabile una volta scalata tutta la quantità massima"
                    | translate
                }}.</small
              >
            </div>
            <div class="form-check">
              <input
                [value]="false"
                [disabled]="!service.enableQty"
                [(ngModel)]="service.decreaseQuantity"
                type="radio"
                class="form-check-input"
                id="servicedecresequantity2"
                name="servicedecresequantity"
              />
              <label for="servicedecresequantity2">{{
                "No" | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <label for="">{{ "Etichetta quantità" | translate }}</label>
          <div>
            <div class="form-check">
              <input
                [value]="false"
                [disabled]="!service.enableQty"
                [(ngModel)]="service.isguest"
                type="radio"
                class="form-check-input"
                id="isguest1"
                name="serviceisguest1"
              />
              <label for="isguest1">{{
                "Quantità" | translate
                }}</label>
            </div>
            <div class="form-check">
              <input
                [value]="true"
                [disabled]="!service.enableQty"
                [(ngModel)]="service.isguest"
                type="radio"
                class="form-check-input"
                id="isguest2"
                name="serviceisguest2"
              />
              <label for="isguest2">{{
                "Persona" | translate
                }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="tools">
        <button
          class="pl-4 pr-4 m-2 btn btn-primary"
          style="font-size: 12px"
          (click)="saveMenageService()"
        >
          {{ "Salva" | translate }}
        </button>
        <button
          class="pl-4 pr-4 btn btn-primary"
          style="font-size: 12px"
          (click)="endMenageService()"
        >
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="timeSlotsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="timeSlotsModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title" id="exampleModalLabel">
              {{
                !timeSlotEdited
                  ? ("Fasce orarie" | translate)
                  : "Creazione/Modifica fascia oraria"
              }}
            </h5>

            <small class="my-2">{{
              "La fascia oraria indica una determinata fascia di tempo ( minuti - ore - giorno 24h ) nella quale potrà essere effettivamente prenotato il servizio"
                | translate
            }}</small>
          </div>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="!timeSlotEdited" class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th class="col-2" scope="col">{{ "Orario" | translate }}</th>
                  <th class="col-2" scope="col">{{ "Prezzo" | translate }}</th>
                  <th class="col-2" scope="col">
                    {{ "Quantità" | translate }}
                  </th>
                  <th class="col-2" scope="col">
                    {{ "Disponibilità" | translate }}
                  </th>
                  <th class="col-4" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let element of periodEdited.perioddata.timeslots;
                    let i = index
                  "
                >
                  <td>{{ element.timeslottext }}</td>
                  <td>
                    {{
                      service.paymenttype != 0 &&
                      periodEdited.perioddata.enablePricing
                        ? "&euro;" + element.price
                        : ("Gratis" | translate)
                    }}
                  </td>
                  <td>
                    {{
                      periodEdited.perioddata.enableSlotQty
                        ? element.maxquantity
                        : periodEdited.perioddata.enableGlobalQty
                        ? ("Decisa dal periodo" | translate)
                        : ("Non abilitata" | translate)
                    }}
                  </td>
                  <td>
                    {{
                      element.isavailable
                        ? ("Sì" | translate)
                        : ("No" | translate)
                    }}
                  </td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <div
                        class="modifyImg"
                        style="font-size: 12px"
                        (click)="editTimeSlot(element, i)"
                      >
                        <img
                          src="assets/img/Pittogramma modifica.png"
                          style="width: 30px"
                          alt=""
                        />
                      </div>

                      <div
                        class="deleteImg"
                        style="font-size: 12px"
                        (click)="deleteTimeSlot(i)"
                      >
                        <img
                          src="assets/img/Pittogramma elimina - cestino.png"
                          style="width: 30px"
                          alt=""
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="timeSlotEdited" class="d-flex flex-wrap">
            <div class="col-12 d-flex flex-wrap">
              <div class="form-group col-6">
                <label for="from">{{
                  "Inizio fascia oraria" | translate
                }}</label>
                <div>
                  <input
                    (change)="checkNewTimeSlot()"
                    [(ngModel)]="timeSlotTime.from"
                    type="time"
                    class="form-control"
                    id="from"
                    name="form"
                  />
                  <small
                    class="d-none"
                    id="newTimeSlotFrom"
                    style="color: red"
                    >{{ "Campo obbligatorio" | translate }}</small
                  >
                  <small
                    class="d-none"
                    id="newTimeSlotOverlapTime"
                    style="color: red"
                    >{{
                      "L'orario di inizio deve essere antecedente all'orario di fine"
                        | translate
                    }}</small
                  >
                </div>
              </div>
              <div class="form-group col-6">
                <label for="to">{{ "Fine fascia oraria" | translate }}</label>
                <div>
                  <input
                    (change)="checkNewTimeSlot()"
                    [(ngModel)]="timeSlotTime.to"
                    type="time"
                    class="form-control"
                    id="to"
                    name="to"
                  />
                  <small class="d-none" id="newTimeSlotTo" style="color: red">{{
                    "Campo obbligatorio" | translate
                  }}</small>
                </div>
              </div>

              <div class="col-6 form-group">
                <label for="languagetype">{{
                  "Seleziona lingua" | translate
                }}</label>
                <div style="display: flex; flex-direction: row">
                  <select
                    [(ngModel)]="currentLanguageTimeslot"
                    class="form-control"
                    name="languagetype"
                  >
                    <option
                      *ngFor="let element of activatedLanguages"
                      [selected]="element.abr === currentLanguageTimeslot"
                      [value]="element.abr"
                    >
                      {{ element.language }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group col-6">
                <label for="from">Note</label>
                <div style="display: flex; flex-direction: row">
                  <textarea
                    [(ngModel)]="
                      timeSlotEdited.localizednote[currentLanguageTimeslot]
                    "
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="1"
                  ></textarea>
                </div>
              </div>

              <div class="form-group col-6">
                <label for="orientamento">{{
                  "Disponibile" | translate
                }}</label>
                <div>
                  <div class="d-flex isAvailableTimeSlot">
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="true"
                          [(ngModel)]="timeSlotEdited.isavailable"
                          type="radio"
                          class="form-check-input"
                          id="isAvailable"
                          name="isAvailable"
                        />
                        <label for="isAvailable">{{ "Sì" | translate }}</label>
                      </div>
                    </div>
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="false"
                          [(ngModel)]="timeSlotEdited.isavailable"
                          type="radio"
                          class="form-check-input"
                          id="isAvailable"
                          name="isAvailable"
                        />
                        <label for="isAvailable">{{ "No" | translate }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="periodEdited.perioddata.enableSlotQty"
                class="form-group col-6"
              >
                <label for="from">{{ "Quantità" | translate }}</label>
                <div>
                  <input
                    (change)="checkNewTimeSlot()"
                    [(ngModel)]="timeSlotEdited.maxquantity"
                    type="number"
                    class="form-control"
                    id="globalQuantity"
                    name="globalQuantity"
                  />
                  <small
                    class="d-none"
                    id="newTimeSlotMaxQuantity"
                    style="color: red"
                    >{{
                      "Campo obbligatorio e maggiore di zero" | translate
                    }}</small
                  >
                </div>
              </div>

              <div
                *ngIf="periodEdited.perioddata.enablePricing"
                class="form-group col-6"
              >
                <label for="from">{{ "Prezzo" | translate }}</label>
                <div style="display: flex; align-items: center">
                  <span class="font-weight-bold text-uppercase">&euro;</span>
                  <input
                    (change)="checkNewTimeSlot()"
                    [(ngModel)]="timeSlotEdited.price"
                    type="number"
                    class="form-control"
                    id="globalQuantity"
                    name="globalQuantity"
                  />
                </div>
                <small
                  class="d-none"
                  id="newTimeSlotPrice"
                  style="color: red"
                  >{{ "Campo obbligatorio" | translate }}</small
                >
              </div>

              <div
                *ngIf="periodEdited.perioddata.enablePricing"
                class="mt-3 row mx-0 col-6"
              >
                <div class="col-12 p-0">
                  <label for="ripetizioni">{{
                    "Attiva prezzo speciale" | translate
                  }}</label>
                  <div>
                    <div class="d-flex isAvailableTimeSlot">
                      <div
                        class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                      >
                        <div class="form-check">
                          <input
                            [value]="false"
                            [(ngModel)]="timeSlotEdited.enablespecialprice"
                            type="radio"
                            class="form-check-input"
                            id="enablespecialprice"
                            name="enablespecialprice"
                          />
                          <label for="enablespecialprice">{{
                            "No" | translate
                          }}</label>
                        </div>
                      </div>
                      <div
                        class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                      >
                        <div class="form-check">
                          <input
                            [value]="true"
                            [(ngModel)]="timeSlotEdited.enablespecialprice"
                            type="radio"
                            class="form-check-input"
                            id="enablespecialprice"
                            name="enablespecialprice"
                          />
                          <label for="enablespecialprice">{{
                            "Sì" | translate
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  timeSlotEdited.enablespecialprice &&
                  periodEdited.perioddata.enablePricing
                "
                class="mt-3 row mx-0 col-6"
              >
                <div class="col-12 p-0">
                  <label for="repetitionType">{{
                    "Giorni in cui è attivo il prezzo speciale" | translate
                  }}</label>
                  <div>
                    <div class="d-flex isAvailableTimeSlot">
                      <div
                        class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                      >
                        <div class="form-check">
                          <input
                            [value]="true"
                            [(ngModel)]="timeSlotEdited.weekly"
                            (change)="timeSlotEdited.days = []"
                            type="radio"
                            class="form-check-input"
                            id="enablespecialpriceType"
                            name="enablespecialpriceType"
                          />
                          <label for="enablespecialpriceType">{{
                            "Settimanale" | translate
                          }}</label>
                        </div>
                      </div>
                      <div
                        class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                      >
                        <div class="form-check">
                          <input
                            [value]="false"
                            [(ngModel)]="timeSlotEdited.weekly"
                            (change)="timeSlotEdited.days = []"
                            type="radio"
                            class="form-check-input"
                            id="enablespecialpriceType"
                            name="enablespecialpriceType"
                          />
                          <label for="enablespecialpriceType">{{
                            "Mensile" | translate
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  timeSlotEdited.enablespecialprice &&
                  periodEdited.perioddata.enablePricing
                "
                class="mt-3 row mx-0 col-6"
              >
                <div class="col-12 p-0">
                  <label for="repetitionType">{{
                    "Seleziona i giorni" | translate
                  }}</label>
                  <div>
                    <div class="">
                      <div class="w-100" *ngIf="timeSlotEdited.weekly">
                        <div class="week-select">
                          <div
                            *ngFor="let element of weekdays; let i = index"
                            [ngClass]="{
                              'selected-element':
                                this.timeSlotEdited.days.includes(i + 1)
                            }"
                            (click)="selectDayRepetition(i + 1, true)"
                            class="d-inline-block p-2 flex-grow-1 text-center"
                          >
                            {{ element | translate }}
                          </div>
                        </div>
                      </div>
                      <div class="w-100" *ngIf="!timeSlotEdited.weekly">
                        <div class="week-select">
                          <div
                            *ngFor="let element of monthdays; let i = index"
                            [ngClass]="{
                              'selected-element':
                                this.timeSlotEdited.days.includes(i + 1)
                            }"
                            (click)="selectDayRepetition(i + 1, true)"
                            class="d-inline-block p-2 flex-grow-1 text-center monthday"
                          >
                            {{ element }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  timeSlotEdited.enablespecialprice &&
                  periodEdited.perioddata.enablePricing
                "
                class="form-group col-6"
              >
                <label for="from">{{ "Prezzo speciale" | translate }}</label>
                <div style="display: flex; align-items: center">
                  <span class="font-weight-bold text-uppercase">&euro;</span>
                  <input
                    [(ngModel)]="timeSlotEdited.specialprice"
                    type="number"
                    class="form-control"
                    id="globalQuantity"
                    name="globalQuantity"
                  />
                </div>
                <!-- <small
                  class="d-none"
                  id="newTimeSlotPrice"
                  style="color: red"
                  >{{ "Campo obbligatorio" | translate }}</small
                > -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!timeSlotEdited" class="modal-footer">
          <div class="d-flex justify-content-between w-100">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="editTimeSlot()"
            >
              + {{ "Aggiungi una fascia oraria" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ "Chiudi" | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="timeSlotEdited" class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="timeSlotEdited = undefined"
          >
            {{ "Annulla" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="saveTimeSlot()"
          >
            {{ "Salva" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="periodModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="periodModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ "Periodo" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-wrap">
            <div class="col-12">
              <div>
                <label for="orientamento">{{ "Quantità" | translate }}</label>
                <div>
                  <div class="d-flex">
                    <div class="col-4 d-flex justify-content-center p-0">
                      <div class="form-check">
                        <input
                          (change)="selectQuantityOption(0, $event)"
                          [checked]="
                            periodEdited.perioddata.enableGlobalQty == true
                          "
                          type="radio"
                          class="form-check-input"
                          id="quantitaGlobale"
                          name="quantitaGlobale"
                        />
                        <label for="form-check-label">{{
                          "Sì Globale" | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col-4 d-flex justify-content-center p-0">
                      <div class="form-check">
                        <input
                          (change)="selectQuantityOption(1, $event)"
                          [checked]="
                            periodEdited.perioddata.enableSlotQty == true
                          "
                          type="radio"
                          class="form-check-input"
                          id="quantitaGlobale"
                          name="quantitaGlobale"
                        />
                        <label for="form-check-label">{{
                          "Sì Per fascia oraria" | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col-4 d-flex justify-content-center p-0">
                      <div class="form-check">
                        <input
                          (change)="selectQuantityOption(2, $event)"
                          [checked]="
                            periodEdited.perioddata.enableSlotQty == false &&
                            periodEdited.perioddata.enableGlobalQty == false
                          "
                          type="radio"
                          class="form-check-input"
                          id="quantitaGlobale"
                          name="quantitaGlobale"
                        />
                        <label for="form-check-label">{{
                          "No Senza quantità" | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-6">
              <label for="from">{{ "Inizio periodo" | translate }}</label>
              <div>
                <input
                  (change)="checkNewPeriod()"
                  [(ngModel)]="periodDate.from"
                  type="date"
                  class="form-control"
                  id="from"
                  name="form"
                />
                <small class="d-none" id="newPeriodFrom" style="color: red">{{
                  "Campo obbligatorio" | translate
                }}</small>
                <small
                  class="d-none"
                  id="newPeriodOverlapDate"
                  style="color: red"
                  >{{
                    "la data di inizio del periodo deve essere antecedente alla data di fine"
                      | translate
                  }}</small
                >
                <small class="d-none" id="checkIncludedDate" style="color: red">
                  {{
                    "Le date non devono essere già comprese all'interno di un periodo"
                      | translate
                  }}
                </small>
              </div>
            </div>

            <div class="form-group col-6">
              <label for="to">{{ "Fine periodo" | translate }}</label>
              <div>
                <input
                  (change)="checkNewPeriod()"
                  [(ngModel)]="periodDate.to"
                  type="date"
                  class="form-control"
                  id="to"
                  name="to"
                />
                <small class="d-none" id="newPeriodTo" style="color: red">{{
                  "Campo obbligatorio" | translate
                }}</small>
              </div>
            </div>

            <div class="form-group col-6">
              <label for="from">{{ "Note Interne" | translate }}</label>
              <div style="display: flex; flex-direction: row">
                <textarea
                  [(ngModel)]="periodEdited.perioddata.note"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="1"
                ></textarea>
              </div>
            </div>

            <div class="form-group col-6">
              <div>
                <label for="from">{{ 'Orario Chiusura Prenotazioni' | translate}}</label>
                <input
                  [(ngModel)]="periodEdited.closereservationtime"
                  type="time"
                  class="form-control"
                  id="from"
                  name="form" />
              </div>
            </div>

            <div
              *ngIf="periodEdited.perioddata.enableGlobalQty"
              class="form-group col-6"
            >
              <label for="from">{{ "Quantità globale" | translate }}</label>
              <div>
                <input
                  (change)="checkNewPeriod()"
                  [(ngModel)]="periodEdited.perioddata.maxservicequantity"
                  type="number"
                  class="form-control"
                  id="globalQuantity"
                  name="globalQuantity"
                />
                <small
                  class="d-none"
                  id="newPeriodMaxServiceQuantity"
                  style="color: red"
                  >{{ "Campo obbligatorio e maggiore di zero" | translate }}
                </small>
              </div>
            </div>

            <div class="mt-3 row mx-0 col-6">
              <div class="col-12 p-0">
                <label for="orientamento">{{
                  "Nascondi termine della fascia oraria" | translate
                }}</label>
                <div>
                  <div class="d-flex isAvailableTimeSlot">
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="false"
                          [(ngModel)]="periodEdited.hideendtime"
                          type="radio"
                          class="form-check-input"
                          id="periodHideEndTime"
                          name="periodHideEndTime"
                        />
                        <label for="periodHideEndTime">{{
                          "No" | translate
                        }}</label>
                      </div>
                    </div>
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="true"
                          [(ngModel)]="periodEdited.hideendtime"
                          type="radio"
                          class="form-check-input"
                          id="periodHideEndTime"
                          name="periodHideEndTime"
                        />
                        <label for="periodHideEndTime">{{
                          "Sì" | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="service.paymentType != 0 || service.paymenttype != 0" class="mt-3 row mx-0 col-6">
              <div class="col-12 p-0">
                <label for="orientamento">{{
                  "Importo del periodo" | translate
                }}</label>
                <div>
                  <div class="d-flex isAvailableTimeSlot">
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="false"
                          [(ngModel)]="periodEdited.perioddata.enablePricing"
                          type="radio"
                          class="form-check-input"
                          id="periodPricing"
                          name="periodPricing"
                        />
                        <label for="periodPricing">{{
                          "Gratuito" | translate
                        }}</label>
                      </div>
                    </div>
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="true"
                          [(ngModel)]="periodEdited.perioddata.enablePricing"
                          type="radio"
                          class="form-check-input"
                          id="periodPricing"
                          name="periodPricing"
                        />
                        <label for="periodPricing">{{
                          "A pagamento" | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 row mx-0 col-6">
              <div class="col-12 p-0">
                <label for="ripetizioni">{{
                  "Ripetizione del periodo" | translate
                }}</label>
                <div>
                  <div class="d-flex isAvailableTimeSlot">
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="false"
                          [(ngModel)]="periodEdited.perioddata.repeat"
                          type="radio"
                          class="form-check-input"
                          id="periodRepeted"
                          name="periodRepeted"
                        />
                        <label for="periodRepeted">{{
                          "No" | translate
                        }}</label>
                      </div>
                    </div>
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="true"
                          [(ngModel)]="periodEdited.perioddata.repeat"
                          type="radio"
                          class="form-check-input"
                          id="periodRepeted"
                          name="periodRepeted"
                        />
                        <label for="periodRepeted">{{
                          "Sì" | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="periodEdited.perioddata.repeat"
              class="mt-3 row mx-0 col-6"
            >
              <div class="col-12 p-0">
                <label for="repetitionType">{{
                  "Tipo di ripetizione" | translate
                }}</label>
                <div>
                  <div class="d-flex isAvailableTimeSlot">
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="true"
                          [(ngModel)]="periodEdited.perioddata.weekly"
                          (change)="periodEdited.perioddata.days = []"
                          type="radio"
                          class="form-check-input"
                          id="repetitionType"
                          name="repetitionType"
                        />
                        <label for="repetitionType">{{
                          "Settimanale" | translate
                        }}</label>
                      </div>
                    </div>
                    <div
                      class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div class="form-check">
                        <input
                          [value]="false"
                          [(ngModel)]="periodEdited.perioddata.weekly"
                          (change)="periodEdited.perioddata.days = []"
                          type="radio"
                          class="form-check-input"
                          id="repetitionType"
                          name="repetitionType"
                        />
                        <label for="repetitionType">{{
                          "Mensile" | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="periodEdited.perioddata.repeat"
              class="mt-3 row mx-0 col-6"
            >
              <div class="col-12 p-0">
                <label for="repetitionType">{{
                  "Seleziona i giorni" | translate
                }}</label>
                <div>
                  <div class="">
                    <div class="w-100" *ngIf="periodEdited.perioddata.weekly">
                      <div class="week-select">
                        <div
                          *ngFor="let element of weekdays; let i = index"
                          [ngClass]="{
                            'selected-element':
                              this.periodEdited.perioddata.days.includes(i + 1)
                          }"
                          (click)="selectDayRepetition(i + 1)"
                          class="d-inline-block p-2 flex-grow-1 text-center"
                        >
                          {{ element | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="w-100" *ngIf="!periodEdited.perioddata.weekly">
                      <div class="week-select">
                        <div
                          *ngFor="let element of monthdays; let i = index"
                          [ngClass]="{
                            'selected-element':
                              this.periodEdited.perioddata.days.includes(i + 1)
                          }"
                          (click)="selectDayRepetition(i + 1)"
                          class="d-inline-block p-2 flex-grow-1 text-center monthday"
                        >
                          {{ element }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="newPeriodDismiss"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            {{ "Annulla" | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="savePeriod()">
            {{ "Salva" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="departmentModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="departmentModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ "Aggiungi reparto" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <div class="form-group col-6">
              <label for="departmenttype">{{
                "Seleziona reparto" | translate
              }}</label>
              <div style="display: flex; flex-direction: row">
                <select
                  class="form-control"
                  name="departmenttype"
                  [(ngModel)]="selectedDepartment"
                >
                  <option
                    *ngFor="let item of filteredDepartemnts"
                    [value]="item.id"
                  >
                    {{ item.name[activatedLanguages[0].abr] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ "Annulla" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="addDepartment()"
          >
            {{ "Salva" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="labelModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="labelModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ "Aggiungi etichetta" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-column">
            <div class="form-group col-6">
              <label for="labeltype">{{
                "Seleziona etichetta" | translate
              }}</label>
              <div style="display: flex; flex-direction: row">
                <select
                  class="form-control"
                  name="labeltype"
                  [(ngModel)]="selectedLabel.labelid"
                >
                  <option *ngFor="let item of filteredLabels" [value]="item.id">
                    {{ item.name[activatedLanguages[0].abr] }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-check col-6 checkbox-label">
              <input
                [(ngModel)]="selectedLabel.mandatory"
                class="form-check-input"
                type="checkbox"
                value=""
                id="labelCheck"
              />
              <label class="form-check-label" for="labelCheck">
                {{ "Campo obbligatorio" | translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ "Annulla" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="addLabel()"
          >
            {{ "Salva" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- blocca fascia oraria modal -->

<div
  class="modal fade col-12"
  id="bloccafasciaOraria"
  tabindex="-1"
  role="dialog"
  aria-labelledby="bloccafasciaOraria"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Blocca fascia oraria o giornata" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- tabella fascie orarie bloccate -->
        <table class="table" *ngIf="availablityAdvancedServices === undefined">
          <thead>
            <tr>
              <th scope="col">{{ "Data" | translate }}</th>
              <th scope="col">{{ "Fasce orarie" | translate }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let periodAvaibility of periodEdited.availablity;
                let i = index
              "
            >
              <td>
                {{ periodAvaibility.date }}
              </td>
              <ng-container
                *ngIf="periodAvaibility.allday === true; else elseTemplate"
              >
                <td>{{ "Tutto il giorno" | translate }}</td>
              </ng-container>
              <ng-template #elseTemplate>
                <td>
                  {{ periodAvaibility.timeslottext }}
                </td>
              </ng-template>
              <td class="text-center">
                <div
                  class="deleteImg"
                  style="
                    font-size: 12px;
                    max-width: 40px;
                    max-height: 40px;
                    justify-content: center;
                  "
                  (click)="deletePeriodAvaibility(periodAvaibility, i)"
                >
                  <img
                    src="assets/img/Pittogramma elimina - cestino.png"
                    style="width: 25px"
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- fine tabella fascie orarie bloccate -->
        <div class="row" *ngIf="availablityAdvancedServices !== undefined">
          <div class="form-group col-6">
            <label for="from">{{ "Seleziona una data" | translate }}</label>
            <div>
              <input
                (change)="checkAvailabilityPeriod()"
                [(ngModel)]="availablityAdvancedServices.date"
                type="date"
                class="form-control"
                id="from"
                name="form"
              />
              <small
                class="d-none"
                id="checkChangeAvailabilityDate"
                style="color: red"
                >{{ "Campo obbligatorio" | translate }}</small
              >
              <small
                class="d-none"
                id="checkChangeAvailabilityDateIsBetween"
                style="color: red"
                >{{
                  "La data deve essere compresa fra le date presenti nel periodo"
                    | translate
                }}</small
              >
            </div>
          </div>
          <!--
          <div class="form-group col-6 mt-4">
            <div class="form-check">
              <div class="
                        col-6
                        p-0
                        h-100
                        d-flex
                        justify-content-center
                        align-items-center
                      ">
                      <div class="form-check">

                      <label for="from">Tutto il giorno</label> <br>
                      <input class="form-check-input" type="radio" value="" id="flexCheckDefault"
                      [(ngModel)]="availablityAdvancedServices.allday" (change)="checkAvailabilityPeriod()">
                      </div>

            </div>
            </div>
          </div> -->

          <!-- provahaah -->
          <div class="row mx-0 col-6">
            <div class="col-12 p-0">
              <label for="orientamento">{{
                "Tutto il giorno" | translate
              }}</label>
              <div>
                <div class="d-flex isAvailableTimeSlot">
                  <div
                    class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                  >
                    <div class="form-check">
                      <input
                        [value]="false"
                        type="radio"
                        class="form-check-input"
                        id="allday"
                        name="allday"
                        [(ngModel)]="availablityAdvancedServices.allday"
                      />
                      <label for="allday">{{ "No" | translate }}</label>
                    </div>
                  </div>
                  <div
                    class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"
                  >
                    <div class="form-check">
                      <input
                        [value]="true"
                        type="radio"
                        class="form-check-input"
                        id="allday"
                        name="allday"
                        [(ngModel)]="availablityAdvancedServices.allday"
                      />
                      <label for="periodHideEndTime">{{
                        "Sì" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- fine provaaahahha -->

          <div
            *ngIf="availablityAdvancedServices.allday === false"
            class="form-group col-6 mt-4"
          >
            <label for="">{{
              "Seleziona una fascia oraria" | translate
            }}</label>
            <select
              [(ngModel)]="availablityAdvancedServices.timeslottext"
              class="form-control"
              (change)="checkAvailabilityPeriod()"
            >
              <option
                *ngFor="let timeSlot of periodEdited.perioddata.timeslots"
                [value]="timeSlot.timeslottext"
              >
                {{ timeSlot.timeslottext }}
              </option>
            </select>

            <small
              class="d-none"
              id="checkChangeAvailabilityTimeSlot"
              style="color: red"
              >{{ "Campo obbligatorio" | translate }}</small
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            *ngIf="availablityAdvancedServices !== undefined"
            type="button"
            id="changeAvailabilityDismiss"
            class="btn btn-secondary"
            (click)="availablityAdvancedServices = undefined"
          >
            {{ "Annulla" | translate }}
          </button>
          <button
            *ngIf="availablityAdvancedServices !== undefined"
            type="button"
            class="btn btn-primary"
            (click)="saveChangeAvailability()"
          >
            {{ "Salva" | translate }}
          </button>

          <div class="d-flex justify-content-between w-100">
            <button
              *ngIf="availablityAdvancedServices === undefined"
              type="button"
              class="btn btn-primary"
              (click)="changeAvailability()"
            >
              {{ "Blocca una fascia oraria" | translate }}
            </button>

            <button
              *ngIf="availablityAdvancedServices === undefined"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              {{ "Annulla" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- fine blocca fascia oraria modal -->
