import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page, Project } from 'src/app/models/signage/signage';
import { AccountService } from 'src/app/services/account/account.service';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';
import { MediaService } from 'src/app/services/media/media.service';
import { SignageService } from 'src/app/services/signage/signage.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import {
  Category,
  CategoryTree,
} from 'src/app/models/categoryTree/category-tree';
import * as moment from 'moment';
import { WsProjectsService } from 'src/app/services/web-services/ws-projects.service';
import { WsProject } from 'src/app/models/ws-project/ws-project';

@Component({
  selector: 'app-signage',
  templateUrl: './signage.component.html',
  styleUrls: ['./signage.component.scss'],
})
export class SignageComponent implements OnInit {
  page: Page = new Page();
  fileToLoad;
  imageToLoad;
  videoToLoad;
  player: YT.Player;
  playerLoaded: boolean = false;
  id: string = '';
  url: string = '';
  urlError: boolean = false;
  savedUrl: string;
  loadedNewFile: boolean = false;
  deletedImage: boolean;

  projectDetails: Project;
  isDetails: Boolean = false;
  projectsList = [];
  projectToDelete;
  pageToDelete;
  actualProject;
  searchValue: string;
  extUrltemp: string = '';
  connectingToCategoryOrService: boolean = false;
  rendering: boolean = false;
  categoriesLevel0: any = { categories: [], services: [] };
  categoriesLevel1: any = { categories: [], services: [] };
  categoriesLevel2: any = { categories: [], services: [] };
  categoriesLevel3: any = { categories: [], services: [] };
  categoriesLevel4: any = { categories: [], services: [] };
  selectedItem: any;
  catalogedItems = [];
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  copiedElement;
  contextElement;
  dateFrom;
  dateTo;
  errorDate = false;
  errorTime = false;
  currentMedia;
  wsProjectsList: WsProject[] = [];

  constructor(
    private accountService: AccountService,
    private adminService: AdminServiceService,
    private signageService: SignageService,
    private router: Router,
    private mediaService: MediaService,
    private categoriesService: CategoriesService,
    private wsProjectsService: WsProjectsService
  ) {}

  ngOnInit(): void {
    this.searchValue = '';
    this.getProjects();
    this.imageToLoad = [{}];
    this.getWsProjects();
  }

  newPage() {
    this.page = new Page();
    this.loadedNewFile = false;
    this.deletedImage = false;
    this.videoToLoad = undefined;
    this.deleteYoutubeVideo();
    this.imageToLoad = [{}];
  }

  drop(event: CdkDragDrop<string[]>, type: number) {
    this.projectDetails.pages[event.previousIndex].index = event.currentIndex;
    this.projectDetails.pages[event.previousIndex].categoryid = this.projectDetails.pages[event.previousIndex].category?.id,
    this.projectDetails.pages[event.previousIndex].serviceid = this.projectDetails.pages[event.previousIndex].service?.id,
    this.updatePage(this.projectDetails.pages[event.previousIndex]);
  }

  getProjects() {
    this.signageService
      .getProjects(localStorage.getItem('HechRomeoAccount'))
      .subscribe((data) => {
        this.projectsList = data;
      });
  }

  getWsProjects() {
    this.wsProjectsService.getAllWsProjects(localStorage.getItem('HechRomeoAccount')).subscribe(res => {
      this.wsProjectsList = res;
      console.log(this.wsProjectsList);
    });
  }

  getProjectDetails(id: string) {
    this.signageService.getProject(id).subscribe((data) => {
      this.projectDetails = data;
      this.isDetails = true;
      this.actualProject = id;
    });
  }

  deleteProject() {
    this.signageService
      .deleteProject(this.projectToDelete.id)
      .subscribe((data) => {
        this.getProjects();
      });
  }

  updateProject() {
    this.signageService.updateProject(this.projectDetails).subscribe((data) => {
      this.getProjectDetails(this.actualProject);
    });
  }

  editProject(id) {
    this.router.navigate(['editSignage'], { queryParams: { id } });
  }

  getPage(pageId: string) {
    this.connectingToCategoryOrService = false;
    this.rendering = false;
    this.signageService
      .getPage(this.actualProject, pageId)
      .subscribe((data) => {
        this.page = data;
        this.page.id = pageId;
        if (this.page.startdate)
          this.dateFrom = moment(this.page.startdate, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
        else this.dateFrom = undefined;
        if (this.page.enddate)
          this.dateTo = moment(this.page.enddate, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
        else this.dateTo = undefined;
        this.loadedNewFile = false;
        this.deletedImage = false;
        this.videoToLoad = undefined;
        this.deleteYoutubeVideo();
        this.imageToLoad = [{}];
        if (this.page.media.data && this.page.media.data != '') {
          if (
            this.page.media.type === 3 &&
            this.page.media.data.search('https') >= 0
          ) {
            this.url = this.page.media.data;
            this.id = this.getIdFromUrl();
          } else if (this.page.media.type === 4) {
            this.extUrltemp = this.page.media.data;
          } else {
            if (
              this.page.media.type == 2 ||
              this.page.media.data.search(';') != -1
            ) {
              this.mediaService
                .getImagesBulk(this.page.media.data)
                .subscribe((data) => {
                  this.imageToLoad = [];
                  let i = 0;
                  let imageArray = this.page.media.data.split(';');
                  for (let element of data) {
                    this.imageToLoad.push({
                      image: element,
                      id: imageArray[i],
                    });
                    i++;
                  }
                  this.imageToLoad.push({});
                  this.page.media.type = 2;
                });
            } else {
              this.mediaService
                .getMediaObject(this.page.media.data)
                .subscribe((data) => {
                  if (data.type == 1) {
                    this.imageToLoad = [{ image: data.mediaobject }, {}];
                    this.page.media.type = 2;
                  } else {
                    this.videoToLoad = data.mediaobject;
                    this.page.media.type = 3;
                  }
                });
            }
          }
        } else {
          this.videoToLoad = undefined;
          this.imageToLoad = [{}];
        }
        if (this.page.category || this.page.service) {
          this.getCategories();
        }
      });
  }

  getCategories() {
    this.categoriesService
      .getVendorTree(localStorage.getItem('HechRomeoAccount'))
      .subscribe((data) => {
        this.categoriesLevel0 = data;
        this.catalogItems(data, '');
        if (this.page.category) {
          this.page.category.level = this.catalogedItems.find(
            (obj) => obj.id == this.page.category.id
          ).level;
        } else if (this.page.service) {
          this.page.service.level = this.catalogedItems.find(
            (obj) => obj.id == this.page.service.id
          ).level;
        }
        this.categoriesLevel1 = { categories: [], services: [] };
        this.categoriesLevel2 = { categories: [], services: [] };
        this.categoriesLevel3 = { categories: [], services: [] };
        this.categoriesLevel4 = { categories: [], services: [] };
      });
  }

  selectItem(item, level, service?) {
    let previusElement = document.getElementsByClassName(
      'selected-item' + level
    )[0];
    if (previusElement) {
      previusElement.classList.remove('selected-item' + level);
    }
    document.getElementById(item.id).classList.add('selected-item' + level);
    this.selectedItem = item;
    if (level < 4 && !service) {
      this['categoriesLevel' + (level + 1)] = JSON.parse(JSON.stringify(item));

      if (level < 3)
        for (let i = level + 2; i <= 4; i++) {
          this['categoriesLevel' + i] = { categories: [], services: [] };
        }
    }
  }

  confirmSelectedItem() {
    if (this.selectedItem.serviceType != undefined) {
      this.page.serviceid = this.selectedItem.id;
      this.page.categoryid = undefined;
      this.page.service = { level: '', id: this.selectedItem.id };
      this.page.service.level = this.catalogedItems.find(
        (obj) => obj.id == this.page.service.id
      ).level;
      this.page.category = undefined;
    } else {
      this.page.categoryid = this.selectedItem.id;
      this.page.serviceid = undefined;
      this.page.category = { level: '', id: this.selectedItem.id };

      this.page.category.level = this.catalogedItems.find(
        (obj) => obj.id == this.page.category.id
      ).level;
      this.page.service = undefined;
    }

    this.connectingToCategoryOrService = false;
  }
  updatePage(page?) {
    this.signageService
      .updatePage(this.actualProject, page ? page : this.page)
      .subscribe((data) => {
        this.getProjectDetails(this.actualProject);
      });
  }

  createPage(element?) {
    this.signageService
      .createPage(this.actualProject, element ? element : this.page)
      .subscribe((data) => {
        this.getProjectDetails(this.actualProject);
      });
  }

  deletePage() {
    this.signageService
      .deletePage(this.actualProject, this.pageToDelete.id)
      .subscribe((data) => {
        this.getProjectDetails(this.actualProject);
      });
  }

  disableTimeType() {
    switch (this.page.media.type) {
      case 1:
      case 4:
        if (this.page.timetype === 2 || this.page.timetype === 3) {
          this.page.timetype = 1;
        }
        break;
      case 2:
        if (this.page.timetype === 2) {
          this.page.timetype = 1;
        }
        break;
      case 3:
        if (this.page.timetype === 3) {
          this.page.timetype = 1;
        }
        break;
    }
  }

  async savePage() {
    if (this.checkDatesAndTimes()) {
      return;
    }
    if (
      this.imageToLoad &&
      this.imageToLoad.length > 0 &&
      (this.loadedNewFile || this.deletedImage) &&
      this.page.media.type == 2
    ) {
      if (!this.loadedNewFile && this.deletedImage) {
        this.page.media.data = '';
        for (let element of this.imageToLoad) {
          if (element && element.id) {
            if (this.page.media.data != '') {
              this.page.media.data = this.page.media.data.concat(';');
            }
            this.page.media.data = this.page.media.data.concat(element.id);
          }
        }
      } else if (this.imageToLoad[0].image != undefined) {
        let images = [];
        for (let element of this.imageToLoad) {
          if (element.image) images.push(element.image);
        }
        await this.mediaService
          .addImagesBulk(localStorage.getItem('HechRomeoAccount'), images)
          .then((data) => {
            this.page.media.data = '';
            for (let element of data) {
              if (this.page.media.data != '') {
                this.page.media.data = this.page.media.data.concat(';');
              }
              this.page.media.data = this.page.media.data.concat(element);
            }
          });
      }
    } else if (this.page.media.type == 3) {
      if (this.savedUrl && this.playerLoaded) {
        this.page.media.data = this.savedUrl;
      } else if (this.videoToLoad && this.loadedNewFile) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            2,
            this.videoToLoad
          )
          .then((data) => {
            this.page.media.data = data.id;
          });
      }
    } else if (this.page.media.type == 4) {
      this.page.media.data = this.extUrltemp;
    } else if (
      this.page.media.data == undefined ||
      this.page.media.data == ''
    ) {
      this.page.media.type = 0;
    }
    if (this.dateFrom)
      this.page.startdate = moment(this.dateFrom, 'YYYY-MM-DD').format(
        'DD/MM/YYYY'
      );
    else this.page.startdate = '';
    if (this.dateTo)
      this.page.enddate = moment(this.dateTo, 'YYYY-MM-DD').format(
        'DD/MM/YYYY'
      );
    else this.page.enddate = '';

    if (this.page.category) {
      this.page.categoryid = this.page.category.id;
    } else if (this.page.service) {
      this.page.serviceid = this.page.service.id;
    }

    if (this.page.id) this.updatePage();
    else this.createPage();
    document.getElementById('closeModalPage').click();
  }

  encodeImageFileAsURL(element, type: number, i?) {
    this.fileToLoad = undefined;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.getPureB64(reader.result.toString(), type, i);
    };
    reader.readAsDataURL(file);
  }
  getPureB64(element: string, type: number, i?) {
    let index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    switch (type) {
      case 1:
        let item = this.imageToLoad[i];
        if (this.imageToLoad.length < 20 && item.image == undefined) {
          this.imageToLoad.push({});
        }
        item.image = element;
        item.new = true;
        this.loadedNewFile = true;
        break;
      case 2:
        this.id = '';
        if (this.playerLoaded) {
          this.player.destroy();
          this.playerLoaded = false;
        }

        this.videoToLoad = element;
        this.loadedNewFile = true;
        break;
      case 3:
        // this.iconToLoad = element;
        // this.loadedNewIcon = true;
        break;
    }
  }

  deleteYoutubeVideo() {
    this.id = '';
    this.url = '';
    this.urlError = false;
    this.savedUrl = '';
    if (this.playerLoaded) {
      this.player.destroy();
      this.playerLoaded = false;
    }
  }
  loadPlayer(root?) {
    const idPlayer: string = root ? 'player' : 'playerRender';
    if (this.id != '' && this.id != undefined) {
      this.player = new YT.Player(idPlayer, {
        width: '480px',
        videoId: this.id,
      });
      this.playerLoaded = true;
    }
  }
  loadNewYoutubeVideo(root?) {
    let newId = this.getIdFromUrl();
    this.id = newId;
    if (this.playerLoaded == false) {
      this.loadPlayer(root);
    } else {
      this.player.loadVideoById(newId);
    }
  }
  getIdFromUrl() {
    try {
      let _url = new URL(this.url);
      //let id = _url.searchParams.get('v');
      let id = this.youtube_parser(this.url);
      this.savedUrl = this.url;
      this.urlError = false;
      return id;
    } catch (e) {
      this.urlError = true;
    }
  }

  youtube_parser(url: string) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : undefined;
  }

  deleteImage(i) {
    this.deletedImage = true;
    this.imageToLoad.splice(i, 1);
    if (this.imageToLoad.length == 7) {
      this.imageToLoad.push({});
    }
    if (
      this.imageToLoad.lenght == 1 &&
      this.imageToLoad[0].image == undefined
    ) {
      this.page.media.data = undefined;
      this.page.media.type = undefined;
    }
  }

  toggleVisibility(page) {
    page.visible = !page.visible;
    this.updatePage(page);
  }

  catalogItems(object, level) {
    for (let element of object.categories) {
      let title = element.title.it;
      if (!title) {
        title = element.title.en;
        if (!title) {
          for (let option in element.title) {
            title = element.title[option];
            break;
          }
        }
      }
      let newLevel = level != '' ? level + ' > ' + title : title;
      this.catalogedItems.push({
        id: element.id,
        level: newLevel,
      });
      this.catalogItems(element, newLevel);
    }
    for (let element of object.services) {
      let title = element.title.it;
      if (!title) {
        title = element.title.en;
        if (!title) {
          for (let option in element.title) {
            title = element.title[option];
            break;
          }
        }
      }
      let newLevel = level != '' ? level + ' > ' + title : title;
      this.catalogedItems.push({
        id: element.id,
        level: newLevel,
      });
    }
  }

  //activates the menu with the coordinates
  onrightClick(event, element?, project?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;

    if (element) this.contextmenu = 'copy';
    else this.contextmenu = 'paste';
    this.contextElement = element;
    if (this.contextElement) {
      this.contextElement.vendor = localStorage.getItem('HechRomeoAccount');
      if (project) this.contextElement.project = true;
    }
  }
  //disables the menu
  disableContextMenu() {
    this.contextmenu = undefined;
  }

  copyElement() {
    if (this.contextElement) {
      if (this.contextElement.project) {
        this.signageService
          .getProject(this.contextElement.id)
          .subscribe((data) => {
            data.project = true;
            data.vendor = localStorage.getItem('HechRomeoAccount');
            delete data.id;
            sessionStorage.setItem('elementToCopy', JSON.stringify(data));
            this.contextElement = undefined;
            this.disableContextMenu();
          });
      } else {
        delete this.contextElement.id;
        sessionStorage.setItem(
          'elementToCopy',
          JSON.stringify(this.contextElement)
        );
        this.contextElement = undefined;
        this.disableContextMenu();
      }
    }
  }

  async pasteElement() {
    if (sessionStorage.getItem('elementToCopy')) {
      let elementToCopy = JSON.parse(sessionStorage.getItem('elementToCopy'));
      if (elementToCopy.project && !this.isDetails) {
        let pages = JSON.parse(JSON.stringify(elementToCopy.pages));
        this.signageService.createProject(elementToCopy).subscribe(() => {
          this.signageService
            .getProjects(localStorage.getItem('HechRomeoAccount'))
            .toPromise()
            .then(async (data) => {
              let project = data[data.length - 1];

              for (let element of pages) {
                delete element.id;
                if (
                  elementToCopy.vendor !=
                  localStorage.getItem('HechRomeoAccount')
                ) {
                  element.category = undefined;
                  element.vendor = undefined;
                } else {
                  if (element.category)
                    element.categoryid = element.category.id;
                  else if (element.service)
                    element.serviceid = element.service.id;
                  element.category = undefined;
                  element.vendor = undefined;
                }
                await this.signageService
                  .createPage(project.id, element)
                  .toPromise();
              }
              this.disableContextMenu();
              this.getProjects();
            });
        });
      } else if (!elementToCopy.project && this.isDetails) {
        if (localStorage.getItem('HechRomeoAccount') != elementToCopy.vendor) {
          elementToCopy.category = undefined;
          elementToCopy.vendor = undefined;
        } else {
          if (elementToCopy.category)
            elementToCopy.categoryid = elementToCopy.category.id;
          else if (elementToCopy.service)
            elementToCopy.serviceid = elementToCopy.service.id;
          elementToCopy.category = undefined;
          elementToCopy.vendor = undefined;
        }
        this.createPage(elementToCopy);
        this.disableContextMenu();
      }
    }
  }

  checkDatesAndTimes() {
    let err = false;
    if (this.dateFrom && this.dateTo) {
      if (
        moment(this.dateFrom, 'YYYY-MM-DD').isAfter(
          moment(this.dateTo, 'YYYY-MM-DD')
        )
      ) {
        this.errorDate = true;
        err = true;
      } else {
        this.errorDate = false;
      }
    } else {
      this.errorDate = false;
    }
    if (this.page.starttime && this.page.endtime) {
      if (
        moment(this.page.starttime, 'HH:mm').isAfter(
          moment(this.page.endtime, 'HH:mm')
        )
      ) {
        if (this.dateFrom && this.dateTo) {
          if (
            moment(this.dateFrom, 'YYYY-MM-DD').isSameOrAfter(
              moment(this.dateTo, 'YYYY-MM-DD')
            )
          ) {
            this.errorTime = true;
            err = true;
          } else {
            this.errorTime = false;
          }
        } else {
          this.errorTime = true;
          err = true;
        }
      } else {
        this.errorTime = false;
      }
    } else {
      this.errorTime = false;
    }

    return err;
  }

  setTimeToZero() {
    if (this.page.time < 0) {
      this.page.time = 0;
    }
  }

  calcHeight() {
    let height = 100;
    let decrese;
    if (this.projectDetails.layout == 'Vertical') decrese = 10;
    if (this.projectDetails.layout == 'Horizontal') decrese = 16;
    if (this.page.header) {
      height = height - decrese;
    }
    if (this.page.footer) {
      height = height - decrese;
    }
    return height + '%';
  }

  getTextFromURI(): string {
    const tempElem = document.createElement('div');

    tempElem.innerHTML = decodeURIComponent(this.page.text);
    return tempElem.textContent.replace(/\n/g, '<br />');
  }

  getMediaRender() {
    if (this.page.media.type == 2 && this.imageToLoad.length > 0) {
      this.currentMedia = 'data:image/jpg;base64,' + this.imageToLoad[0].image;
    } else if (this.page.media.type == 3 && this.videoToLoad) {
      this.currentMedia = 'data:video/mp4;base64,' + this.videoToLoad;
    } else if (this.page.media.type == 3 && this.id) {
      this.currentMedia = `https://img.youtube.com/vi/${this.id}/maxresdefault.jpg`;
    } else this.currentMedia = undefined;
  }
}
