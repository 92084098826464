<div class="row mx-0 h-100" (click)="disableContextMenu()">
  <div class="col-12 my-2">
    <div class="row mx-0 w-100">
      <div class="col-12 p-0">
        <span>{{ "Gestione TV" | translate }}</span>
        <h3 class="color-theme font-weight-bold">
          {{ "Cloni" | translate }}
        </h3>
      </div>
    </div>
    <div class="row mx-0 w-100">
      <div class="form-group col-1 p-0">
        <div>
          <label for="search">{{ "Cerca" | translate }}:</label>
          <input id="search" [(ngModel)]="searchValue" />
        </div>
      </div>

      <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

      <div *ngIf="accountService.isAdmin" class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button class="btn btn-primary mb-4 w-100" (click)="editClone('')">
          + {{ "Aggiungi clone" | translate }}
        </button>
      </div>
    </div>

    <div class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <table
            class="table"
            (contextmenu)="onrightClick($event)"
            oncontextmenu="return false;"
          >
            <thead>
              <tr>
                <th scope="col">{{ "Nome" | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                oncontextmenu="return false;"
                *ngFor="let clone of clones | filter : searchValue"
                (contextmenu)="
                  onrightClick($event, clone, true); $event.stopPropagation()
                "
                style="cursor: pointer"
              >
                <td
                  (click)="editClone(clone.id)"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  "
                >
                  {{ clone.name }}
                </td>
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    width: 10%;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <div *ngIf="accountService.isAdmin" class="modifyImg" (click)="editClone(clone.id)">
                      <img
                        src="assets/img/Pittogramma modifica.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                    <div
                    *ngIf="accountService.isAdmin"
                      data-toggle="modal"
                      data-target="#delConfirmModal"
                      class="deleteImg"
                      (click)="cloneToDelete = clone"
                    >
                      <img
                        src="assets/img/Pittogramma elimina - cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="deleteClone()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
