import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TYPOGRAPHY } from 'src/app/constants/typography.enum';
import { UpdetableAccount, ChangePwdObj } from 'src/app/models/account/account';
import { AccountService } from 'src/app/services/account/account.service';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  vendorData: UpdetableAccount = new UpdetableAccount();
  changePwdObj: ChangePwdObj = new ChangePwdObj();
  confirmNewPwd = '';
  admin: boolean =
    localStorage.getItem('HechRomeoRole') !== 'Manager' ? false : true;
  registerForm: FormGroup;
  emailForm: FormGroup;
  changePasswordForm: FormGroup;
  TYPOGRAPHY = TYPOGRAPHY;
  vendorEmailTmp;

  mediaobject: string;

  constructor(
    private accountService: AccountService,
    private mediaService: MediaService,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation().extras?.state) {
      window.location.reload();
    }
  }

  ngOnInit(): void {
    this.initializeFormGroups();
    this.getVendorData();
  }

  getVendorData() {
    this.accountService.getVendorData().subscribe((data: UpdetableAccount) => {
      this.vendorData = data;
      this.vendorEmailTmp = this.vendorData.email;
      this.initializeFormGroups();
    });
  }

  async updateVendorData() {
    for (const controlName in this.registerForm.controls) {
      if (controlName) {
        const control = this.registerForm.controls[controlName];
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
    }
    if (this.registerForm.valid) {
      for (const controlName in this.registerForm.controls) {
        if (controlName) {
          const control = this.registerForm.controls[controlName];
          if (control) {
            this.vendorData[controlName] = '' + control.value;
          }
        }
      }
      this.accountService.updateVendorData(this.vendorData).subscribe(
        () => {},
        (error) => {}
      );
    } else {
      alert(TYPOGRAPHY.INPUT_ERROR_COMPLETE_ALL_FIELDS);
    }
  }

  changeVendorEmail() {
    if (this.admin && this.emailForm.valid) {
      let vendorMail = {
        account: this.vendorData.account,
        oldmail: this.vendorData.email,
        newmail: this.vendorEmailTmp,
      };
      this.accountService.changeVendorMail(vendorMail).subscribe(() => {});
    }
  }

  private initializeFormGroups() {
    this.registerForm = new FormGroup({
      referrername: new FormControl(this.vendorData.referrername, [
        Validators.required,
      ]),
      referrerlastname: new FormControl(this.vendorData.referrerlastname, [
        Validators.required,
      ]),
    });
    if (!this.admin) {
      this.changePasswordForm = new FormGroup({
        password: new FormControl(this.changePwdObj.oldpwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
        newpassword: new FormControl(this.changePwdObj.newpwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
        confirmnewpassword: new FormControl(this.confirmNewPwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
      });
    } else {
      this.emailForm = new FormGroup({
        changemail: new FormControl(this.vendorEmailTmp, [
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          ),
        ]),
      });
    }
  }

  encodeImageFileAsURL(element) {
    const file = element[0];
    const reader = new FileReader();
    reader.onloadend = () => this.getPureB64(reader.result.toString());
    reader.readAsDataURL(file);

  }

  deleteImage(element: HTMLInputElement ) {
    element.value = ''
    this.mediaobject = '';
  }

  getPureB64(element: string ) {
    const index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    this.mediaobject = element;
  }
}
