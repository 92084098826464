<div class="row mx-0">
    <div class="col-12 my-2">

        <div class="row mx-0 w-100">
            <div class="col-12 p-0">
                <span>{{ "Amministrazione" | translate }}</span>
                <h3 class="color-theme font-weight-bold">
                {{ "Elenco Utenti" | translate }}
                </h3>
            </div>
        </div>

        <div class="row mx-0 w-100">
            <div class="form-group col-1 p-0">
                <div>
                    <label for="search">{{ "Cerca" | translate }}:</label>
                    <input id="search" [(ngModel)]="searchValue" />
                </div>
            </div>

            <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

            <ng-container *ngIf="admin">
                <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
                    <button data-toggle="modal" data-target="#add-user" class="btn btn-primary mb-4 w-100" routerLink="/register-user">
                        + {{ "Aggiungi utente" | translate }}
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="row mx-0">
            <div class="card w-100">
                <div class="card-body" style="overflow-y: auto">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Account</th>
                                <th scope="col">Email</th>
                                <th scope="col">{{ 'Permessi' | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let user of usersList | filter: searchValue">
                                <td style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 30%;
                                    cursor: pointer;
                                    "
                                    (click)="impersonate(user.account, user.email, true)">
                                    {{ user.account }}
                                </td>
                                <td style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 30%;
                                    cursor: pointer;
                                    "
                                    (click)="impersonate(user.account, user.email, true)">
                                    {{ user.email }}
                                </td>
                                <td style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 35%;
                                    ">
                                    <span style="cursor: pointer;" (click)="switchPermission(user, 'categoriesServices')"
                                        [matTooltip]="'Elenco categorie e servizi' | translate">
                                        <img class="permissions"
                                            src="assets/img/Pittogramma categorie e servizi.png"
                                            style="width: 30px"
                                            [ngStyle]="!user.categoriesServices && {'filter': 'drop-shadow(0px 1000px 0 rgb(91, 91, 91))'}"
                                            
                                        />
                                    </span>
                                    <span style="cursor: pointer;" (click)="switchPermission(user, 'digitalSignage')"
                                        [matTooltip]="'Digital Signage' | translate">
                                        <img class="permissions"
                                            src="assets/img/signage.png"
                                            style="width: 30px"
                                            [ngStyle]="!user.digitalSignage && {'filter': 'drop-shadow(0px 1000px 0 rgb(91, 91, 91))'}"
                                        />
                                    </span>
                                    <span style="cursor: pointer;" (click)="switchPermission(user, 'guestsManagement')"
                                        [matTooltip]="'Gestione ospiti' | translate">
                                        <img class="permissions"
                                            src="assets/img/Pittogramma gestione ospiti.png"
                                            style="width: 30px"
                                            [ngStyle]="!user.guestsManagement && {'filter': 'drop-shadow(0px 1000px 0 rgb(91, 91, 91))'}"
                                        />
                                    </span>
                                    <span style="cursor: pointer;" (click)="switchPermission(user, 'tvManagement')"
                                        [matTooltip]="'Gestione tv' | translate">
                                        <img class="permissions"
                                            src="assets/img/tv.png"
                                            style="width: 30px"
                                            [ngStyle]="!user.tvManagement && {'filter': 'drop-shadow(0px 1000px 0 rgb(91, 91, 91))'}"
                                        />
                                    </span>
                                    <span style="cursor: pointer;" (click)="switchPermission(user, 'integrations')"
                                        [matTooltip]="'Integrazioni' | translate">
                                        <img class="permissions"
                                            src="assets/img/Pittogramma categorie e servizi.png"
                                            style="width: 30px"
                                            [ngStyle]="!user.integrations && {'filter': 'drop-shadow(0px 1000px 0 rgb(91, 91, 91))'}"
                                        />
                                    </span>
                                </td>
                                <td style="
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    padding: 0;
                                    cursor: pointer;
                                    width: 5%;
                                    ">
                                    <div class="d-flex flex-row justify-content-end w-100">
                                        <div *ngIf="admin" data-toggle="modal" class="modifyImg" (click)="editUser(user)">
                                            <img src="assets/img/Pittogramma modifica.png"
                                                style="width: 30px" alt="" />
                                        </div>
                                        <div class="modifyImg" (click)="impersonate(user.account, user.email, true)">
                                            <svg
                                              class="bi bi-info-circle"
                                              width="30px"
                                              height="30px"
                                              viewBox="0 0 16 16"
                                              fill="white"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                              />
                                              <path
                                                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                                              />
                                              <circle cx="8" cy="4.5" r="1" />
                                            </svg>
                                        </div>
                                        <div *ngIf="admin" data-toggle="modal" data-target="#delete-admin" class="delete-img" (click)="
                                                userToDelete.id = user.id;
                                            ">
                                            <img src="assets/img/Pittogramma%20elimina%20-%20cestino.png"
                                                style="width: 30px" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="delete-admin" tabindex="-1" role="dialog" aria-labelledby="delete-admin" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "Confermare eliminazione" | translate }}</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                {{
                "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
                | translate
                }}
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ "Annulla" | translate }}
                </button>

                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteUser()">
                    {{ "Conferma" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>