import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {AccountDetailService} from '../../services/account/account-detail.service';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  admin: boolean =
    localStorage.getItem('HechRomeoRole') == 'Manager' ? true : false;
  vendor: boolean =
    localStorage.getItem('HechRomeoRole') == 'Vendor' ? true : false;
  user = 
    localStorage.getItem('HechRomeoRole') == 'User' ? true : false;
  otherVendor: boolean =
    localStorage.getItem('HechRomeoAccount') != localStorage.getItem('OriginalAccount'); 
  hasOriginalAccount: boolean = 
    localStorage.getItem('OriginalAccount') ? true : false;
  vendorReference: boolean =
    localStorage.getItem('HechRomeoVendor') ? true : false
  listUsers: boolean =
    localStorage.getItem('HechRomeoAccount') ===
    localStorage.getItem('HechRomeoEmail');
  categoriesServices = JSON.parse(localStorage.getItem('categoriesServices'));
  digitalSignage = JSON.parse(localStorage.getItem('digitalSignage'));
  guestsManagement = JSON.parse(localStorage.getItem('guestsManagement'));
  tvManagement = JSON.parse(localStorage.getItem('tvManagement'));
  integrations = JSON.parse(localStorage.getItem('integrations'));

  constructor(
    private router: Router,
    private translate: TranslateService,
    public accountDetailService: AccountDetailService,
    public accountService: AccountService
  ) {
    if (localStorage.getItem('HechRomeoRole') === 'User') {
      this.accountService.getPermissions(this.accountService.vendor, localStorage.getItem('HechRomeoEmail')).subscribe(res => {
        localStorage.setItem('categoriesServices', res.categoriesServices);
        localStorage.setItem('digitalSignage', res.digitalSignage);
        localStorage.setItem('guestsManagement', res.guestsManagement);
        localStorage.setItem('tvManagement', res.tvManagement);
        localStorage.setItem('integrations', res.integrations);

        this.categoriesServices = JSON.parse(localStorage.getItem('categoriesServices'));
        this.digitalSignage = JSON.parse(localStorage.getItem('digitalSignage'));
        this.guestsManagement = JSON.parse(localStorage.getItem('guestsManagement'));
        this.tvManagement = JSON.parse(localStorage.getItem('tvManagement'));
        this.integrations = JSON.parse(localStorage.getItem('integrations'));
      });
    }
  }

  ngOnInit(): void {}

  logout() {
    localStorage.removeItem('HechRomeoRole');
    localStorage.removeItem('HechRomeoAccount');
    sessionStorage.removeItem('HechRomeoToken');
    localStorage.removeItem('HechRomeoEmail');
    localStorage.removeItem('HechRomeoPassword');
    localStorage.removeItem('OriginalAccount');
    localStorage.removeItem('categoriesServices');
    localStorage.removeItem('digitalSignage');
    localStorage.removeItem('guestsManagement');
    localStorage.removeItem('tvManagement');
    localStorage.removeItem('integrations');
    this.accountDetailService.setAccount(undefined);
    this.router.navigate(['login']);
  }
  changeLanguage(language) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }
}
