<div class="container-fluid my-4">
  <div class="row mx-0 w-100">
    <div class="col-12 p-0">
      <span>{{ "Gestione ospiti" | translate }}</span>
      <h3 class="color-theme font-weight-bold">
        {{ "Calendario Partners" | translate }}
      </h3>
    </div>
  </div>

  <div class="row mx-0">
    <button class="btn btn-secondary mr-1 my-2" data-toggle="modal" data-target="#filtersModal" (click)="clearNewFilter()">
      {{ "Filtri" | translate }}
    </button>

    <button class="btn btn-secondary mx-1 my-2" data-toggle="modal" data-target="#newReservationModal" (click)="reservationModalOpened()">
      {{ "Crea nuova prenotazione" | translate }}
    </button>

    <button class="btn btn-secondary ml-1 my-2" (click)="print()">
      {{ "Stampa" | translate }}
    </button>
  </div>

  <div class="container-fluid my-2 p-0">
    <div class="row mx-0">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="hideCalendar" (change)="toggleCalendar()"/>

        <label class="form-check-label" for="flexCheckDefault">
          {{ "Nascondi Calendario" | translate }}
        </label>
      </div>
    </div>

    <div class="row mx-0">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="deleteReservation" [(ngModel)]="viewDeleteReservation" (ngModelChange)="searchRequest()"/>

        <label class="form-check-label" for="deleteReservation">
          {{ "Visualizza prenotazioni cancellate" | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0">
    <div class="row mx-0 w-100">
      <div id="minicalendar" class="col-sm-12 col-md-12 col-lg-4 p-0 my-3">
        <div class="row mx-0">
          <h3 class="color-theme font-weight-bold mb-0">
            {{ "Le tue prenotazioni" | translate }}
          </h3>
        </div>

        <div class="row mx-0">
          <span class="font-weight-bold my-2">
            {{ "Servizi" | translate }}: {{ totalNumber }} -
            {{ "Quantità" | translate }}: {{ totalServices }} - {{ "Totale" | translate }}: €
            {{ totalSearchRequest }} - Fee: € {{ totalFee }}
          </span>
        </div>

        <div class="container-fluid p-0 eventDetails">
          <div *ngIf="!isDetailsService">
            <div *ngFor="let searchRequest of listSearchRequest">
              <div
                *ngFor="let reservation of searchRequest.reservations"
                class="card"
              >
                <div class="card-body">
                  <strong> {{ "Cliente" | translate }}: </strong
                  >{{ reservation.name }} {{ reservation.lastname }} <br />
                  <strong>{{ "Servizio" | translate }}:</strong>
                  {{ searchRequest.servicename }}
                  <br />
                  <strong *ngIf="!searchRequest.serviceenddate"
                  >{{ "Data e ora del servizio" | translate }}:</strong
                  >
                  <strong *ngIf="searchRequest.serviceenddate"
                  >{{ "Data inizio del servizio" | translate }}:</strong
                  >
                  {{ searchRequest.servicedate }}
                  {{
                  !searchRequest.serviceenddate
                    ? ", " + searchRequest.servicetime
                    : ""
                  }}
                  <br />
                  <strong *ngIf="searchRequest.serviceenddate"
                  >{{ "Data fine del servizio" | translate }}:</strong
                  >
                  {{
                  searchRequest.serviceenddate
                    ? searchRequest.serviceenddate
                    : ""
                  }}
                  <br *ngIf="searchRequest.serviceenddate" />
                  <strong>Note:</strong> {{ searchRequest.note }} <br />
                  <div *ngIf="reservation.quantity && reservation.quantity > 0">
                    <strong>{{ "Quantità" | translate }}:</strong>
                    {{ reservation.quantity }} <br />
                  </div>
                  <div *ngIf="reservation.amount && reservation.amount > 0">
                    <strong>{{ "Valore" | translate }}:</strong> €{{
                    reservation.amount ? reservation.amount : 0
                    }}
                    <br />
                  </div>
                  <strong>Fee:</strong>
                  {{
                  searchRequest.isdiscount
                    ? searchRequest.fee + "%"
                    : "€" + searchRequest.fee
                  }}
                  <br />

                  <strong>{{ "Status" | translate }}:</strong>
                  {{ getStatusOrder(reservation.orederstatus) }} <br />
                </div>
              </div>
            </div>

            <div *ngFor="let searchRequest of listSearchRequestShopping">
              <div class="card">
                <div class="card-body">
                  <strong>{{ "Data e ora del servizio" | translate }}:</strong>
                  {{ searchRequest.shoppingcartdate }} ,
                  {{ searchRequest.shoppimgcarttime }} <br />
                  <strong>{{ "Cliente" | translate }}:</strong>
                  {{ searchRequest.name }} {{ searchRequest.lastname }} <br />
                  <strong>Email:</strong> {{ searchRequest.email }} <br />
                  <strong>Note:</strong> {{ searchRequest.notes }} <br />
                  <strong>{{ "Etichette" | translate }}:</strong>
                  <br />
                  <div *ngFor="let element of searchRequest.labels">
                    <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>
                  </div>
                  <strong>{{ "Valore" | translate }}:</strong> €{{
                  searchRequest.amount
                  }}
                  <br />
                  <strong>{{ "Status" | translate }}:</strong>
                  {{ getStatusOrder(searchRequest.orederstatus) }} <br />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isDetailsService">
            <div *ngFor="let r of services.reservations" class="card">
              <div class="card-body">
                <strong>{{ "Servizio" | translate }}:</strong>
                {{ services.servicename }} <br />
                <strong *ngIf="!services.serviceenddate"
                >{{ "Data e ora del servizio" | translate }}:</strong
                >
                <strong *ngIf="services.servicestartdate"
                >{{ "Data inizio del servizio" | translate }}:</strong
                >
                {{ services.servicestartdate ? services.servicestartdate : "" }}
                {{ !services.serviceenddate ? ", " + services.servicetime : "" }}
                <br />
                <strong *ngIf="services.serviceenddate"
                >{{ "Data fine del servizio" | translate }}:</strong
                >
                {{ services.serviceenddate ? services.serviceenddate : "" }}
                <br *ngIf="services.serviceenddate" />
                <strong> {{ "Cliente" | translate }}: </strong>{{ r.name }}
                {{ r.lastname }} <br />
                <strong>Email:</strong> {{ r.email }} <br />
                <div *ngIf="r.quantity && r.quantity > 0">
                  <strong>{{ "Quantità" | translate }}:</strong> {{ r.quantity }}
                  <br />
                </div>
                <div *ngIf="r.amount && r.amount > 0">
                  <strong>{{ "Valore" | translate }}:</strong> €{{ r.amount }}
                  <br />
                </div>
                <strong>{{ "Etichette" | translate }}:</strong>
                <br />
                <div *ngFor="let element of r.labels">
                  <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>
                </div>
                <strong>{{ "Reparti" | translate }}:</strong>
                <span *ngFor="let element of services.departments">
                {{ element }},</span
                >
                <br />
                <strong>Note:</strong> {{ r.note }} <br />
                <strong>{{ "Data e ora di prenotazione" | translate }}:</strong>
                {{ r.reservationdate }} ,
                {{ r.reservationtime }}
                <br />
                <strong>{{ "Posizione del servizio" | translate }}:</strong>
                {{ r.categoriestree }}
                <br />
                <strong>Fee:</strong>
                €{{ r.fee }} <br />
                <strong>{{ "Da fornitore" | translate }}:</strong>
                {{ r.vendor }} <br />
                <strong>{{ "A fornitore" | translate }}:</strong>
                {{ services.account }} <br />
                <strong>{{ "Status" | translate }}:</strong>
                {{ getStatusOrder(r.status) }} <br />
                <div class="mt-2"></div>
              </div>
            </div>
            <div *ngIf="services.cart" class="card">
              <div class="card-body">
                <strong>{{ "Data e ora del servizio" | translate }}:</strong>
                {{ services.reservationdate }} , {{ services.reservationtime }}
                <br />
                <strong> {{ "Cliente" | translate }}: </strong
                >{{ services.name }} {{ services.lastname }} <br />
                <strong>Email:</strong> {{ services.email }} <br />

                <strong>Note:</strong> {{ services.notes }} <br />
                <strong>{{ "Etichette" | translate }}:</strong>
                <br />
                <div *ngFor="let element of services.labels">
                  <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>
                </div>
                <strong>{{ "Valore" | translate }}:</strong> €{{
                services.amount
                }}
                <br />
                <strong>{{ "Servizi" | translate }}:</strong> <br />
                <div
                  *ngFor="let s of services.services; index as i"
                  class="cartItem"
                  [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"
                >
                  <strong>{{ "Titolo" | translate }}:</strong>
                  {{ s.servicename }} <br />
                  <strong>{{ "Posizione del servizio" | translate }}:</strong>
                  {{ s.categoriestree }}
                  <br />
                  <strong>{{ "Quantità" | translate }}:</strong> {{ s.quantity }}
                  <br />
                  <strong>{{ "Prezzo" | translate }}:</strong> €{{ s.price }}
                  <br />
                  <strong>{{ "Reparti" | translate }}:</strong>
                  <span *ngFor="let element of s.departments">
                  {{ element }},</span
                  >
                  <br />
                </div>
                <strong>{{ "Status" | translate }}:</strong>
                {{ getStatusOrder(services.status) }} <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="calendar" class="col-sm-12 col-md-12 col-lg-8 my-3">
<!--        <ejs-schedule #schedulerObj [eventSettings]="eventSettings" width='100%' height='900px' (navigating)="onNavigateChange($event)" [selectedDate]='selectedDate' [readonly]="true" (eventClick)="onEventClick($event)" (eventRendered)="onEventRendered($event)">-->
<!--          <e-views>-->
<!--            <e-view option='Day'></e-view>-->
<!--            <e-view option='TimelineDay'></e-view>-->
<!--            <e-view option='Week'></e-view>-->
<!--            <e-view option='TimelineWeek'></e-view>-->
<!--            <e-view option='Month'></e-view>-->
<!--            <e-view option='TimelineMonth'></e-view>-->
<!--          </e-views>-->
<!--        </ejs-schedule>-->
      </div>
    </div>
  </div>
</div>

<!--<div class="container-fluid">-->
<!--  <div class="row mx-0">-->
<!--    <div class="card border-0">-->
<!--      <div class="card-body">-->
<!--        <span>{{ "Gestione ospiti" | translate }}</span>-->
<!--        <h3 class="color-theme font-weight-bold mb-0">-->
<!--          {{ "Calendario Partners" | translate }}-->
<!--        </h3>-->
<!--        <div class="row mx-0">-->
<!--          <button-->
<!--            class="btn btn-secondary mr-1 my-2"-->
<!--            data-toggle="modal"-->
<!--            data-target="#filtersModal"-->
<!--            (click)="clearNewFilter()"-->
<!--          >-->
<!--            {{ "Filtri" | translate }}-->
<!--          </button>-->

<!--          <button-->
<!--            class="btn btn-secondary mx-1 my-2"-->
<!--            data-toggle="modal"-->
<!--            data-target="#newReservationModal"-->
<!--            (click)="reservationModalOpened()"-->
<!--          >-->
<!--            {{ "Crea nuova prenotazione" | translate }}-->
<!--          </button>-->
<!--          <button class="btn btn-secondary ml-1 my-2" (click)="print()">-->
<!--            {{ "Stampa" | translate }}-->
<!--          </button>-->
<!--        </div>-->

<!--        <div class="mt-3">-->
<!--          <div class="form-check">-->
<!--            <input-->
<!--              class="form-check-input"-->
<!--              type="checkbox"-->
<!--              value=""-->
<!--              id="flexCheckDefault"-->
<!--              [(ngModel)]="hideCalendar"-->
<!--              (change)="toggleCalendar()"-->
<!--            />-->
<!--            <label class="form-check-label" for="flexCheckDefault">-->
<!--              {{ "Nascondi Calendario" | translate }}-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row mx-0 w-100">-->
<!--    <div class="col-sm-12 col-md-12 col-lg-4 p-0 my-3" id="minicalendar">-->
<!--      <div class="card border-0">-->
<!--        <div class="card-body">-->
<!--          <h3 class="color-theme font-weight-bold mb-0">-->
<!--            {{ "Le tue prenotazioni" | translate }}-->
<!--          </h3>-->

<!--          <div class="my-2">-->
<!--            <span class="font-weight-bold"-->
<!--              >{{ "Servizi" | translate }}: {{ totalNumber }} - -->
<!--              {{ "Quantità" | translate }}: {{ totalServices }} - -->
<!--              {{ "Totale" | translate }}: € {{ totalSearchRequest }} - Fee: €{{-->
<!--                totalFee-->
<!--              }}</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="eventDetails">-->
<!--        <div *ngIf="!isDetailsService">-->
<!--          <div *ngFor="let searchRequest of listSearchRequest">-->
<!--            <div-->
<!--              *ngFor="let reservation of searchRequest.reservations"-->
<!--              class="card"-->
<!--            >-->
<!--              <div class="card-body">-->
<!--                <strong> {{ "Cliente" | translate }}: </strong-->
<!--                >{{ reservation.name }} {{ reservation.lastname }} <br />-->
<!--                <strong>{{ "Servizio" | translate }}:</strong>-->
<!--                {{ searchRequest.servicename }}-->
<!--                <br />-->
<!--                <strong *ngIf="!searchRequest.serviceenddate"-->
<!--                  >{{ "Data e ora del servizio" | translate }}:</strong-->
<!--                >-->
<!--                <strong *ngIf="searchRequest.serviceenddate"-->
<!--                  >{{ "Data inizio del servizio" | translate }}:</strong-->
<!--                >-->
<!--                {{ searchRequest.servicedate }}-->
<!--                {{-->
<!--                  !searchRequest.serviceenddate-->
<!--                    ? ", " + searchRequest.servicetime-->
<!--                    : ""-->
<!--                }}-->
<!--                <br />-->
<!--                <strong *ngIf="searchRequest.serviceenddate"-->
<!--                  >{{ "Data fine del servizio" | translate }}:</strong-->
<!--                >-->
<!--                {{-->
<!--                  searchRequest.serviceenddate-->
<!--                    ? searchRequest.serviceenddate-->
<!--                    : ""-->
<!--                }}-->
<!--                <br *ngIf="searchRequest.serviceenddate" />-->
<!--                <strong>Note:</strong> {{ searchRequest.note }} <br />-->
<!--                <div *ngIf="reservation.quantity && reservation.quantity > 0">-->
<!--                  <strong>{{ "Quantità" | translate }}:</strong>-->
<!--                  {{ reservation.quantity }} <br />-->
<!--                </div>-->
<!--                <div *ngIf="reservation.amount && reservation.amount > 0">-->
<!--                  <strong>{{ "Valore" | translate }}:</strong> €{{-->
<!--                    reservation.amount ? reservation.amount : 0-->
<!--                  }}-->
<!--                  <br />-->
<!--                </div>-->
<!--                <strong>Fee:</strong>-->
<!--                {{-->
<!--                  searchRequest.isdiscount-->
<!--                    ? searchRequest.fee + "%"-->
<!--                    : "€" + searchRequest.fee-->
<!--                }}-->
<!--                <br />-->

<!--                <strong>{{ "Status" | translate }}:</strong>-->
<!--                {{ getStatusOrder(reservation.orederstatus) }} <br />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div *ngFor="let searchRequest of listSearchRequestShopping">-->
<!--            <div class="card">-->
<!--              <div class="card-body">-->
<!--                <strong>{{ "Data e ora del servizio" | translate }}:</strong>-->
<!--                {{ searchRequest.shoppingcartdate }} ,-->
<!--                {{ searchRequest.shoppimgcarttime }} <br />-->
<!--                <strong>{{ "Cliente" | translate }}:</strong>-->
<!--                {{ searchRequest.name }} {{ searchRequest.lastname }} <br />-->
<!--                <strong>Email:</strong> {{ searchRequest.email }} <br />-->
<!--                <strong>Note:</strong> {{ searchRequest.notes }} <br />-->
<!--                <strong>{{ "Etichette" | translate }}:</strong>-->
<!--                <br />-->
<!--                <div *ngFor="let element of searchRequest.labels">-->
<!--                  <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>-->
<!--                </div>-->
<!--                <strong>{{ "Valore" | translate }}:</strong> €{{-->
<!--                  searchRequest.amount-->
<!--                }}-->
<!--                <br />-->
<!--                <strong>{{ "Status" | translate }}:</strong>-->
<!--                {{ getStatusOrder(searchRequest.orederstatus) }} <br />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div *ngIf="isDetailsService">-->
<!--          <div *ngFor="let r of services.reservations" class="card">-->
<!--            <div class="card-body">-->
<!--              <strong>{{ "Servizio" | translate }}:</strong>-->
<!--              {{ services.servicename }} <br />-->
<!--              <strong *ngIf="!services.serviceenddate"-->
<!--                >{{ "Data e ora del servizio" | translate }}:</strong-->
<!--              >-->
<!--              <strong *ngIf="services.servicestartdate"-->
<!--                >{{ "Data inizio del servizio" | translate }}:</strong-->
<!--              >-->
<!--              {{ services.servicestartdate ? services.servicestartdate : "" }}-->
<!--              {{ !services.serviceenddate ? ", " + services.servicetime : "" }}-->
<!--              <br />-->
<!--              <strong *ngIf="services.serviceenddate"-->
<!--                >{{ "Data fine del servizio" | translate }}:</strong-->
<!--              >-->
<!--              {{ services.serviceenddate ? services.serviceenddate : "" }}-->
<!--              <br *ngIf="services.serviceenddate" />-->
<!--              <strong> {{ "Cliente" | translate }}: </strong>{{ r.name }}-->
<!--              {{ r.lastname }} <br />-->
<!--              <strong>Email:</strong> {{ r.email }} <br />-->
<!--              <div *ngIf="r.quantity && r.quantity > 0">-->
<!--                <strong>{{ "Quantità" | translate }}:</strong> {{ r.quantity }}-->
<!--                <br />-->
<!--              </div>-->
<!--              <div *ngIf="r.amount && r.amount > 0">-->
<!--                <strong>{{ "Valore" | translate }}:</strong> €{{ r.amount }}-->
<!--                <br />-->
<!--              </div>-->
<!--              <strong>{{ "Etichette" | translate }}:</strong>-->
<!--              <br />-->
<!--              <div *ngFor="let element of r.labels">-->
<!--                <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>-->
<!--              </div>-->
<!--              <strong>{{ "Reparti" | translate }}:</strong>-->
<!--              <span *ngFor="let element of services.departments">-->
<!--                {{ element }},</span-->
<!--              >-->
<!--              <br />-->
<!--              <strong>Note:</strong> {{ r.note }} <br />-->
<!--              <strong>{{ "Data e ora di prenotazione" | translate }}:</strong>-->
<!--              {{ r.reservationdate }} ,-->
<!--              {{ r.reservationtime }}-->
<!--              <br />-->
<!--              <strong>{{ "Posizione del servizio" | translate }}:</strong>-->
<!--              {{ r.categoriestree }}-->
<!--              <br />-->
<!--              <strong>Fee:</strong>-->
<!--              €{{ r.fee }} <br />-->
<!--              <strong>{{ "Da fornitore" | translate }}:</strong>-->
<!--              {{ r.vendor }} <br />-->
<!--              <strong>{{ "A fornitore" | translate }}:</strong>-->
<!--              {{ services.account }} <br />-->
<!--              <strong>{{ "Status" | translate }}:</strong>-->
<!--              {{ getStatusOrder(r.status) }} <br />-->
<!--              <div class="mt-2"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div *ngIf="services.cart" class="card">-->
<!--            <div class="card-body">-->
<!--              <strong>{{ "Data e ora del servizio" | translate }}:</strong>-->
<!--              {{ services.reservationdate }} , {{ services.reservationtime }}-->
<!--              <br />-->
<!--              <strong> {{ "Cliente" | translate }}: </strong-->
<!--              >{{ services.name }} {{ services.lastname }} <br />-->
<!--              <strong>Email:</strong> {{ services.email }} <br />-->

<!--              <strong>Note:</strong> {{ services.notes }} <br />-->
<!--              <strong>{{ "Etichette" | translate }}:</strong>-->
<!--              <br />-->
<!--              <div *ngFor="let element of services.labels">-->
<!--                <span>&nbsp;&nbsp;&nbsp;{{ element }}</span>-->
<!--              </div>-->
<!--              <strong>{{ "Valore" | translate }}:</strong> €{{-->
<!--                services.amount-->
<!--              }}-->
<!--              <br />-->
<!--              <strong>{{ "Servizi" | translate }}:</strong> <br />-->
<!--              <div-->
<!--                *ngFor="let s of services.services; index as i"-->
<!--                class="cartItem"-->
<!--                [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"-->
<!--              >-->
<!--                <strong>{{ "Titolo" | translate }}:</strong>-->
<!--                {{ s.servicename }} <br />-->
<!--                <strong>{{ "Posizione del servizio" | translate }}:</strong>-->
<!--                {{ s.categoriestree }}-->
<!--                <br />-->
<!--                <strong>{{ "Quantità" | translate }}:</strong> {{ s.quantity }}-->
<!--                <br />-->
<!--                <strong>{{ "Prezzo" | translate }}:</strong> €{{ s.price }}-->
<!--                <br />-->
<!--                <strong>{{ "Reparti" | translate }}:</strong>-->
<!--                <span *ngFor="let element of s.departments">-->
<!--                  {{ element }},</span-->
<!--                >-->
<!--                <br />-->
<!--              </div>-->
<!--              <strong>{{ "Status" | translate }}:</strong>-->
<!--              {{ getStatusOrder(services.status) }} <br />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-sm-12 col-md-12 col-lg-8 my-3" id="calendar-report"></div>-->
<!--  </div>-->
<!--</div>-->

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            cancelReservation(
              reservationToCancel.reservationid,
              reservationToCancel.servicedate,
              reservationToCancel.servicetime,
              reservationToCancel.email,
              reservationToCancel.serviceid,
              0
            )
          "
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="filtersModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="filtersModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Filtra calendario" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Da" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilterDate.from"
                style="width: 100%"
                type="date"
                class="form-control"
                id="datefrom"
                name="datefrom"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "A" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilterDate.to"
                style="width: 100%"
                type="date"
                class="form-control"
                id="dateto"
                name="dateto"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Status prenotazione" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="newFilters.orderstatus"
              >
                <option [ngValue]="-1">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option [ngValue]="0">{{ "In Attesa" | translate }}</option>
                <option [ngValue]="1">{{ "Accettata" | translate }}</option>
                <option [ngValue]="2">{{ "Rifiutata" | translate }}</option>
                <option [ngValue]="3">{{ "Processata" | translate }}</option>
                <option [ngValue]="4">{{ "Cancellata" | translate }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Nome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customername"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customerlastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customeremail"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{ "Fornitore" | translate }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="newFilters.vendor"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option *ngFor="let element of vendorList" [value]="element">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype">{{ "Tipo" | translate }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="newFilters.type"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option [ngValue]="0">
                  {{ "Prenotazioni verso altro fornitore" | translate }}
                </option>
                <option [ngValue]="1">
                  {{ "Prenotazioni da altro fornitore" | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label for="virtualCategoryFilter">{{
              "Categoria" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="virtualCategoryFilter"
                [(ngModel)]="newFilters.categoryroot"
              >
                <option [ngValue]="''">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option
                  *ngFor="let element of virtualCategories"
                  [ngValue]="element.id"
                >
                  {{ element.title["it"] }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="clearFilter()">
          {{ "Resetta Filtri" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="searchRequest()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newReservationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newReservationModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 90vw">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Nuova prenotazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <app-select-service-to-book
        [categoryTree]="categoryTree"
        [events]="eventsSubject.asObservable()"
        [allLabels]="allLabels"
        [virtual]="true"
        (searchRequest)="searchRequest()"
      ></app-select-service-to-book>
    </div>
  </div>
</div>

<!-- <div
  class="modal fade"
  id="newReservationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newReservationModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Nuova prenotazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span *ngIf="selectedService"
          >{{ "Posizione del servizio selezionato" | translate }}:
          {{ selectedService.tree }} > {{ selectedService.title.it }}</span
        >
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona servizio" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedService"
                (change)="changeSelectedService()"
              >
                <option *ngFor="let element of allServices" [ngValue]="element">
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Quantità" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.quantity"
                style="width: 100%"
                type="number"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.email"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Nome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.name"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.lastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>{{ "Data" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.servicedate"
                (change)="newReservationChangedDate()"
                style="width: 100%"
                type="date"
                class="form-control"
                id="categoryroot"
                name="categoryroot"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div
            class="form-group col-4"
            [ngClass]="{
              'd-none': !selectedService || !selectedService.isadvanced
            }"
          >
            <label>{{ "Fasce orarie" | translate }}</label>
            <div>
              <select
                style="width: 100% !important"
                id="timeslots"
                multiple="multiple"
              >
                <option
                  *ngFor="let element of timeslots"
                  [value]="element.timeslottext"
                >
                  {{ element.timeslottext }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-group col-4"
            *ngIf="!selectedService || !selectedService.isadvanced"
          >
            <label>{{ "Orario" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.servicetime"
                style="width: 100%"
                type="time"
                class="form-control"
                id="categoryL1"
                name="categoryL"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona etichetta" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedLabel"
              >
                <option
                  *ngFor="let element of availableLabels"
                  [ngValue]="element"
                >
                  {{ element.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="makeReservation()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newCartModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newCartModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Orario" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span *ngIf="selectedService"
          >{{ "Posizione del servizio selezionato" | translate }}:
          {{ selectedService.tree }} > {{ selectedService.title.it }}</span
        >
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona servizio" | translate
            }}</label>
            <div>
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedService"
              >
                <option
                  *ngFor="let element of cartServicesNotActivated"
                  [ngValue]="element"
                >
                  {{ element.title.it }}
                </option>
              </select>
              <small style="color: red" id="err-label" class="d-none">
                {{
                  "Il servizio non è compatibile con i servizi selezionati o non ha etichette"
                    | translate
                }}</small
              >
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype" style="opacity: 0">{{
              "Seleziona servizio" | translate
            }}</label>
            <div>
              <button class="btn btn-primary" (click)="addItemToCart()">
                {{ "Aggiungi" | translate }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div class="d-flex" *ngFor="let element of newCart.items">
            <div class="form-group col-4">
              <label>{{ "Titolo" | translate }}</label>
              <div>
                <input
                  [(ngModel)]="element.title"
                  style="width: 100%"
                  type="text"
                  disabled
                  class="form-control"
                  id="customername"
                  name="customername"
                />
              </div>
            </div>
            <div class="form-group col-4">
              <label>{{ "Quantità" | translate }}</label>
              <div>
                <input
                  [(ngModel)]="element.quantity"
                  style="width: 100%"
                  type="number"
                  min="1"
                  class="form-control"
                  id="customername"
                  name="customername"
                />
                <small
                  style="color: red"
                  id="err-{{ element.serviceid }}"
                  class="d-none"
                  >{{
                    "Il minimo accettabile come quantità è uno" | translate
                  }}</small
                >
              </div>
            </div>
            <div class="form-group col-4">
              <label for="categorytype" style="opacity: 0">{{
                "Seleziona servizio" | translate
              }}</label>
              <div>
                <button
                  class="btn btn-secondary"
                  (click)="deleteItemFromCart(element)"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.email"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Nome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.name"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.lastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona etichetta" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedLabel"
              >
                <option
                  *ngFor="let element of cartLabelsList"
                  [ngValue]="element"
                >
                  {{ element.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Messaggio etichetta" | translate }}</label>
            <div>
            </div>
          </div>
          <div class="form-group col-4">
            <label>Note</label>
            <div>
              <textarea
                [(ngModel)]="newCart.notes"
                style="width: 100%"
                class="form-control"
                id="cartNotes"
                name="cartNotes"
                rows="1"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="insertCart()">
          {{ "Conferma" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary d-none"
          id="confirm-cart"
          data-dismiss="modal"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div> -->
