export class WsProject {
    id: string = '';
    account: string;
    projectname: string = '';
    wsdlendpoint: string = '';
    wscallname: string = '';
    parameters: any = {};
    responselistparameter: any[] = [];
    responselisttableselect: any;
    responsetype: string = '';
    responsetable: any = null;
    responsetableselect: any = null;
    responseparameter: any = null;
    responsevalueparameter: any;
    responsevaluetableselect: any;
    responseJson: any;
    wssubcall: WsProject = null;
}