import * as moment from 'moment';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { CategoriesService } from '../../../services/categories/categories.service';
import { LabelService } from '../../../services/label/label.service';
import { DepartmentService } from '../../../services/department/department.service';
import { Router } from '@angular/router';
import { Filters } from '../../../models/filters/filters';
import { Subject } from 'rxjs';
import { EventRenderedArgs, EventSettingsModel } from '@syncfusion/ej2-angular-schedule';
import { Label } from '../../../models/label/label';
import { Department } from '../../../models/department/department';
import { Category, CategoryTree, Service } from '../../../models/categoryTree/category-tree';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  account: any = localStorage.getItem('HechRomeoAccount');
  isUser: boolean = localStorage.getItem('HechRomeoRole') === 'User' ? true : false;
  filters: Filters = new Filters();
  categoriesSelect = {
    selectedCategory0: undefined,
    selectedCategory1: undefined,
    selectedCategory2: undefined,
    selectedCategory3: undefined,
    categoryObj0: { categories: [] },
    categoryObj1: { categories: [] },
    categoryObj2: { categories: [] },
    categoryObj3: { categories: [] },
    currentServiceList: [],
    selectedServiceInList: undefined,
  };
  filterDate = { from: '', to: '' };
  newFilters: Filters = new Filters();
  newCategoriesSelect;
  newFilterDate;
  eventsSubject: Subject<void> = new Subject<void>();
  hideCalendar = false;
  totalNumber: number = 0;
  totalServices: number = 0;
  totalSearchRequest: number = 0;
  eventSettings: EventSettingsModel = {
    dataSource: []
  };
  selectedDate: Date = new Date();
  allLabels: Label[] = [];
  allDepartments: Department[];
  categoryTree: CategoryTree;
  allCategories: Category[] = [];
  allServices: Service[] = [];
  cartServices: Service[] = [];
  cartServicesNotActivated: Service[] = [];
  isDetailsService: boolean = false;
  listSearchRequest = [];
  listSearchRequestShopping = [];
  listSearchRequestAdvancedShopping = [];
  services: any = [];
  eventSelected;
  reservationToModify;
  reservationToCancel = {
    reservationid: undefined,
    servicedate: undefined,
    servicetime: undefined,
    email: undefined,
    serviceid: undefined,
  };
  viewDeleteReservation: boolean = false;
  lastEvSelected: any = undefined;
  vendorAccount: string;

  @ViewChild('schedulerObj') schedulerObj: any;

  constructor(private calendarService: CalendarService,
    private categoriesService: CategoriesService,
    private labelService: LabelService,
    private departmentService: DepartmentService,
    private router: Router,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {

    this.filters.account = this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account;
    this.newFilters.account = this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account;
    this.newCategoriesSelect = { ...this.categoriesSelect };
    this.newFilterDate = { ...this.filterDate };
    this.isDetailsService = false;
    this.listSearchRequest = [];
    this.listSearchRequestShopping = [];
    this.listSearchRequestAdvancedShopping = [];
    this.totalSearchRequest = 0;
    this.totalServices = 0;
    this.totalNumber = 0;
    this.eventSettings = {
      dataSource: []
    };
    this.viewDeleteReservation = false;

    this.getAllLabels();
    this.getAllDepartments();
    this.getAllCategories();
  }

  ngAfterViewInit() {
    this.schedulerObj.timeScale.enable = false;

    this.searchRequest();

    setInterval(() => {
      if (this.router.url === '/calendar') {
        this.searchRequest();
      }
    }, 300000);
  }

  clearNewFilter(): void {
    this.newFilters = { ...this.filters };
    this.newCategoriesSelect = { ...this.categoriesSelect };
    this.newFilterDate = { ...this.filterDate };
  }

  reservationModalOpened(): void {
    this.eventsSubject.next();
  }

  print(): void {
    window.print();
  }

  downloadCsv(): void {
    this.calendarService.downloadCsv(this.filters).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      if (navigator?.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, 'booked_services.csv');
      } else {
        const a = document.createElement('a');

        a.href = url;
        a.download = 'booked_services.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      window.URL.revokeObjectURL(url);
    });
  }

  toggleCalendar() {
    document.getElementById('calendar').style.display = this.hideCalendar ? 'none' : 'block';
    document.getElementById('minicalendar').classList.remove(!this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
    document.getElementById('minicalendar').classList.add(this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
  }

  onNavigateChange(event: any): void {
    if (event.currentDate) {
      this.selectedDate = event.currentDate;
      console.log('this.selectedDate', this.selectedDate)
    }

    this.searchRequest();
  }

  selectCategory(currentObj): void {
    this.clearOtherObjects(currentObj);

    if (currentObj != 3) {
      this.newCategoriesSelect['categoryObj' + (currentObj + 1)] =
        this.newCategoriesSelect['categoryObj' + currentObj].categories.find(
          (obj) =>
            obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
        );
      this.newCategoriesSelect.currentServiceList =
        this.newCategoriesSelect['categoryObj' + (currentObj + 1)].services;
    } else {
      this.newCategoriesSelect.currentServiceList = this.newCategoriesSelect[
        'categoryObj' + currentObj
      ].categories.find(
        (obj) =>
          obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
      ).services;
    }
  }

  clearOtherObjects(currentObj): void {
    for (let x = currentObj + 1; x < 4; x++) {
      this.newCategoriesSelect['selectedCategory' + x] = undefined;
      this.newCategoriesSelect['categoryObj' + x] = { categories: [] };
    }

    this.newCategoriesSelect.selectedServiceInList = undefined;
    this.newCategoriesSelect.currentServiceList = [];
  }

  getAllLabels(): void {
    if (this.isUser || localStorage.getItem('HechRomeoVendor')) {
      this.labelService.getLinkedVendorLabels(this.accountService.vendor).subscribe((data) => {
        this.allLabels = data.labels;
      });
    } else {
      this.labelService.getLabels().subscribe((data) => {
        this.allLabels = data.labels;
      });
    }
  }

  getAllDepartments(): void {
    if (this.isUser || localStorage.getItem('HechRomeoVendor')) {
      this.departmentService.getLinkedVendorDepartments(this.accountService.vendor).subscribe(data => {
        this.allDepartments = data.departments;
      });
    } else {
      this.departmentService.getDepartments().subscribe((data) => {
        this.allDepartments = data.departments;
      });
    }
  }

  clearFilter(): void {
    this.newFilters = new Filters();
    this.newFilters.account = this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account;
    this.newCategoriesSelect = {
      selectedCategory0: undefined,
      selectedCategory1: undefined,
      selectedCategory2: undefined,
      selectedCategory3: undefined,
      categoryObj0: { categories: [] },
      categoryObj1: { categories: [] },
      categoryObj2: { categories: [] },
      categoryObj3: { categories: [] },
      currentServiceList: [],
      selectedServiceInList: undefined,
    };
    this.newFilterDate = { from: '', to: '' };
    this.newCategoriesSelect.categoryObj0 = this.categoriesSelect.categoryObj0;
    this.newCategoriesSelect.selectedServiceInList = this.categoriesSelect.selectedServiceInList;
  }

  searchRequest(): void {
    if (this.schedulerObj) {
      let notbefore = '';
      let notafter = '';
      const selectedDate = moment(this.selectedDate);

      this.filters.period.from = notbefore;
      this.filters.period.to = notafter;

      let currentView = this.schedulerObj.views[this.schedulerObj.getCurrentViewIndex()] ?
                        this.schedulerObj.views[this.schedulerObj.getCurrentViewIndex()].option :
                        this.schedulerObj.currentView;
      switch (currentView) {
        case 'Day':
        case 'TimelineDay':
          notbefore = selectedDate
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          notafter = selectedDate.clone()
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          break;
        case 'Week':
        case 'TimelineWeek':
          notbefore = selectedDate
            .startOf('day')
            .startOf('week')
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          console.log('notbefore', notbefore);
          notafter = selectedDate
            .startOf('day')
            .endOf('week')
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          console.log('notbefore', notafter);
          break;
        case 'Month':
        case 'TimelineMonth':
          notbefore = selectedDate
            .startOf('day')
            .startOf('month')
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          notafter = selectedDate
            .startOf('day')
            .endOf('month')
            .toISOString(true)
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('/');
          break;
      }

      this.filters.period.from = notbefore;
      this.filters.period.to = notafter;
      // this.newFilters.period.from = notbefore;
      // this.newFilters.period.to = notafter;
      this.newFilters.period.from = this.newFilterDate.from ? this.formatDate(this.newFilterDate.from) : notbefore;
      this.newFilters.period.to = this.newFilterDate.to ? this.formatDate(this.newFilterDate.to) : notafter;
      this.filters.hidden = this.viewDeleteReservation;
      this.newFilters.hidden = this.viewDeleteReservation;

      for (let x = 0; x < 4; x++) {
        if (this.categoriesSelect['selectedCategory' + x]) {
          if (x == 0) {
            this.filters.categoryroot = this.categoriesSelect.categoryObj0.categories
              .find((obj) => obj.id == this.categoriesSelect.selectedCategory0).id;
          } else {
            this.filters['categoryL' + x] = this.categoriesSelect['categoryObj' + x].categories
              .find((obj) => obj.id == this.categoriesSelect['selectedCategory' + x]).id;
          }
        } else {
          this.filters['categoryL' + x] = undefined;
        }
      }

      if (this.categoriesSelect.selectedServiceInList) {
        this.filters.service = this.categoriesSelect.selectedServiceInList;
      }

      this.calendarService.searchRequest(this.newFilters)
        .subscribe((data) => {
          const listEventCalendar = [];

          this.listSearchRequest = [];
          this.listSearchRequestShopping = [];
          this.listSearchRequestAdvancedShopping = [];
          this.totalSearchRequest = 0;
          this.totalServices = 0;
          this.totalNumber = 0;
          this.isDetailsService = false;

          Object.keys(data.days).forEach((key, index) => {
            data.days[key].forEach(ev => {
              if (ev.reservations.length > 0) {
                this.totalNumber += ev.reservations.length;

                for (const reservation of ev.reservations) {
                  if (reservation.quantity) {
                    this.totalServices += reservation.quantity;
                  } else {
                    this.totalServices += 1;
                  }
                }
              }

              if (ev.amount) {
                this.totalSearchRequest = this.totalSearchRequest + ev.amount;
              }

              const [day, month, year] = ev.servicedate.split('/');

              // if (true) {
                // let slottext = "10:00-14:00";
                // let orariosplit = slottext.split("-");
              if (ev.reservations[0]?.slottext) {
                let orario = ev.reservations[0].slottext[0];
                let orariosplit = orario.split("-");
                const [hours, minutes] = orariosplit[0].split(':');
                const [dayEnd, monthEnd, yearEnd] = ev.serviceenddate.split('/');

                const reservation = ev.reservations[0];
                const nameLabel = reservation.name ? ` - ${reservation.name}` : '';
                const lastnameLabel = reservation.lastname || '';
                const quantityLabel = reservation.quantity ? ` ( ${reservation.quantity} )` : '';

                const subject = `<div>${ev.servicename}<small> ${nameLabel} ${lastnameLabel} ${quantityLabel} </small><div>`

                listEventCalendar.push({
                  Id: ev.id,
                  Subject: subject,
                  StartTime: new Date(+year, +month - 1, +day, +hours + index, +minutes),
                  EndTime: new Date(+yearEnd, +monthEnd - 1, +dayEnd, +hours + 1, +minutes),
                  IsAllDay: false,
                  status: Math.min.apply(null, ev.reservations.map((reservation) => reservation.orederstatus)),
                  detail: ev
                });
                this.listSearchRequest.push(ev);

              }else {
                const [hours, minutes] = ['9', '31'];
                const [dayEnd, monthEnd, yearEnd] = ev.serviceenddate.split('/');

                if ( ev.reservations.length ) ev.reservations.forEach( reservation => {
                  const nameLabel = reservation.name ? ` - ${reservation.name}` : '';
                  const lastnameLabel = reservation.lastname || '';
                  const quantityLabel = reservation.quantity ? ` ( ${reservation.quantity} )` : '';
                  const subject = `<div>${ev.servicename}<small> ${nameLabel} ${lastnameLabel} ${quantityLabel}</small><div>`;

                  listEventCalendar.push({
                    Id: ev.id,
                    Subject: subject,
                    StartTime: new Date(+year, +month - 1, +day, 0, 0),
                    EndTime: new Date(+yearEnd, +monthEnd - 1, +dayEnd, 0, 0),
                    IsAllDay: false,
                    status: Math.min.apply(null, ev.reservations.map((reservation) => reservation.orederstatus)),
                    detail: ev
                  });
                  this.listSearchRequest.push(ev);

                });
              }
            });
          });

          Object.keys(data.shoppingcarts.days).forEach((key) => {
            data.shoppingcarts.days[key].forEach((ev) => {
              if (!this.filters.service) {
                if (ev.amount) {
                  this.totalSearchRequest = this.totalSearchRequest + ev.amount;
                }

                this.totalNumber += ev.items.length;

                for (const item of ev.items) {
                  if (item.quantity) {
                    this.totalServices += item.quantity;
                  } else {
                    this.totalServices += 1;
                  }
                }
              } else {
                for (const item of ev.items) {
                  if (item.serviceid === this.filters.service) {
                    this.totalNumber += 1;

                    if (item.quantity) {
                      this.totalServices += item.quantity;
                    } else {
                      this.totalServices += 1;
                    }

                    if (item.amount) {
                      this.totalSearchRequest += item.amount;
                    }
                  }
                }
              }

              const [day, month, year] = ev.shoppingcartdate.split('/');
              const [hours, minutes] = ev.shoppimgcarttime.split(':');

              listEventCalendar.push({
                Id: ev.id,
                Subject: 'CARRELLO',
                StartTime: new Date(+year, +month - 1, +day, +hours, +minutes),
                EndTime: new Date(+year, +month - 1, +day, +hours + 1, +minutes),
                IsAllDay: false,
                status: ev.orederstatus,
                detail: ev
              });

              this.listSearchRequestShopping.push(ev);
            });
          });

          Object.keys(data.advancedshoppingcarts.days).forEach((key) => {
            data.advancedshoppingcarts.days[key].forEach((ev) => {
              ev.amount =
                moment(ev.shoppingcartdateto)
                  .add(1, 'days')
                  .diff(moment(ev.shoppingcartdatefrom), 'days') * ev.amount;

              if (!this.filters.service) {
                if (ev.amount) {
                  this.totalSearchRequest = this.totalSearchRequest + ev.amount;
                }

                this.totalNumber += ev.reservations.length;

                for (const item of ev.reservations) {
                  for (const slot of item.slots) {
                    if (slot.quantity) {
                      this.totalServices += slot.quantity;
                    } else {
                      this.totalServices += 1;
                    }
                  }
                }
              } else {
                for (const item of ev.reservations) {
                  if (item.serviceid === this.filters.service) {
                    this.totalNumber += 1;

                    for (const slot of item.slots) {
                      if (slot.quantity) {
                        this.totalServices += slot.quantity;
                      } else {
                        this.totalServices += 1;
                      }

                      if (slot.amount) {
                        this.totalSearchRequest += slot.amount;
                      }
                    }
                  }
                }
              }

              if (ev.amount) {
                this.totalSearchRequest = this.totalSearchRequest + ev.amount;
              }

              const dateStart = moment(ev.shoppingcartdatefrom);
              const dateEnd = moment(ev.shoppingcartdateto).add(1, 'days');

              listEventCalendar.push({
                Id: ev.id,
                Subject: 'CARRELLO AVANZATO',
                StartTime: new Date(dateStart.year(), dateStart.month(), dateStart.date(), 0, 0),
                EndTime: new Date(dateEnd.year(), dateEnd.month(), dateEnd.date(), 0, 0),
                IsAllDay: true,
                status: ev.orederstatus,
                detail: ev
              });

              ev.shoppingcartdatefrom = moment(ev.shoppingcartdatefrom).format('DD/MM/YYYY');
              ev.shoppingcartdateto = moment(ev.shoppingcartdateto)
                .add(1, 'days')
                .format('DD/MM/YYYY');

              this.listSearchRequestAdvancedShopping.push(ev);
            });
          });
          this.eventSettings = {
            dataSource: listEventCalendar
          };
        });
    }
  }

  onEventRendered(args: EventRenderedArgs): void {
    switch (args.data.status) {
      case 0:
        args.element.style.backgroundColor = '#efdba5';
        break;
      case 1:
        args.element.style.backgroundColor = '#cadb83';
        break;
      case 2:
        args.element.style.backgroundColor = '#ea9494';
        break;
      case 3:
        args.element.style.backgroundColor = '#94bbd6';
        break;
      case 4:
        args.element.style.backgroundColor = '#b7b7b7';
        break;
    }
  }

  onEventClick(args): void {
    if (!this.isDetailsService || !this.lastEvSelected || this.lastEvSelected.id !== args.event.Id) {
      this.eventSelected = {
        ev: args.event.detail
      };

      switch (args.event.Subject) {
        case 'CARRELLO':
          this.getShoppingCartDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev, false);
          break;
        case 'CARRELLO AVANZATO':
          this.getShoppingCartDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev, true);
          break;
        default:
          this.getReservationDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev);
      }

      this.lastEvSelected = this.eventSelected.ev;
      this.isDetailsService = true;
    } else {
      this.isDetailsService = false;
      this.lastEvSelected = undefined;
      this.searchRequest();
    }
  }

  getAllCategories(): void {
      this.categoriesService.getVendorTree(this.accountService.vendor).subscribe((data) => {
        this.categoryTree = data;
        this.allCategories = data.categories;
        this.categoriesSelect.categoryObj0 = { ...data };
        this.newCategoriesSelect = { ...this.categoriesSelect };
        this.exporeCategories(this.allCategories, '');
      });
  }

  exporeCategories(array, tree): void {
    for (const element of array) {
      if (tree.length > 0) {
        tree = tree.concat(' > ');
      }

      tree = tree.concat(element.title.it);

      this.exporeCategories(element.categories, tree);

      for (const item of element.services) {
        item.tree = tree;
        if (item.deleted == false && item.cartenabled == false && item.visible == true) {
          this.allServices.push(item);
        }

        if (item.deleted == false && item.cartenabled == true) {
          this.cartServices.push(item);
          this.cartServicesNotActivated.push(item);
        }
      }

      const i = tree.lastIndexOf('>');

      tree = tree.substring(0, i - 1);
    }
  }

  getStatusOrder(orderstatus: number): string {
    if (orderstatus >= 0) {
      switch (orderstatus) {
        case 0:
          return 'In Attesa';
        case 1:
          return 'Accettata';
        case 2:
          return 'Rifiutata';
        case 3:
          return 'Processata';
        case 4:
          return 'Cancellata';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  changeReservationStatus(reservation, service, cart?): void {
    if (cart) {
      if (parseInt(reservation.status) == 4 || parseInt(reservation.orederstatus) == 4) {
        const data = {
          owner: localStorage.getItem('HechRomeoAccount'),
          reservationid: '',
          servicedate: reservation.reservationdate,
          servicetime: '',
          accountemail: reservation.email,
          serviceid: reservation.advancedshoppingcartid
            ? reservation.advancedshoppingcartid
            : reservation.shoppingcartid,
          ordertype: reservation.advancedshoppingcartid ? 2 : 1,
        };

        this.calendarService.cancelCart(data).subscribe(() => {
          this.searchRequest();
        });
      } else {
        this.calendarService.changeStatusCart(reservation.shoppingcartid ? reservation.shoppingcartid : reservation.advancedshoppingcartid,
          localStorage.getItem('HechRomeoAccount'), reservation.advancedshoppingcartid
          ? parseInt(reservation.orederstatus) : parseInt(reservation.status), reservation.advancedshoppingcartid ? true : false)
          .subscribe(() => {
            this.searchRequest();
          });
      }
    } else {
      if (parseInt(reservation.status) == 4) {
        this.cancelReservation(reservation.reservationid, service.servicedate, service.servicetime,
          reservation.email, service.serviceid, 0);
      } else {
        const changeStatus = {
          account: localStorage.getItem('HechRomeoAccount'),
          reservationid: reservation.reservationid,
          servicedate: service.servicedate,
          servicetime: service.servicetime,
          serviceid: service.serviceid,
          workedout: reservation.workedout,
          orederstatus: parseInt(reservation.status),
        };

        this.calendarService.changeReservationStatus(changeStatus)
          .subscribe(() => {
            this.searchRequest();
          });
      }
    }
  }

  cancelReservation(reservationid, servicedate, servicetiontime, accountemail, serviceid, ordertype): void {
    this.calendarService.cancelReservation(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, reservationid, servicedate, servicetiontime, accountemail, serviceid, ordertype,
      false, '').subscribe(() => {
        if (this.eventSelected.cart) {
          this.getShoppingCartDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev, false);
        } else {
          if (this.eventSelected.ev.shoppingcartdatefrom) {
            this.getShoppingCartDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev, true);
          } else {
            this.getReservationDetails(this.isUser ? localStorage.getItem('HechRomeoVendor') : this.account, this.eventSelected.ev);
          }
        }

        this.searchRequest();
      },
        (err) => {
          console.log('cancel reservation error', err);
        }
      );
  }

  getShoppingCartDetails(account, event, advanced): void {
    this.services = {};

    const searchObj = {
      account,
      shoppingcartid: event.id,
      shoppingcartadvancedid: event.id,
    };

    if (advanced) {
      this.calendarService.getAdvancedCartDetails(searchObj)
        .subscribe((calendar) => {
          this.services = calendar;
          this.services.cart = true;
          this.totalSearchRequest = 0;
          this.totalServices = 0;
          this.totalNumber = 0;
          this.services.shoppingcartdatefrom = moment(this.services.shoppingcartdatefrom).format('DD/MM/YYYY');
          this.services.shoppingcartdateto = moment(this.services.shoppingcartdateto).format('DD/MM/YYYY');

          if (!this.filters.service) {
            if (this.services.amount) {
              this.totalSearchRequest = this.totalSearchRequest + this.services.amount;
            }

            this.totalNumber += this.services.reservations.length;

            for (const item of this.services.reservations) {
              for (const slot of item.slots) {
                if (slot.quantity) {
                  this.totalServices += slot.quantity;
                } else {
                  this.totalServices += 1;
                }
              }
            }
          } else {
            for (const item of this.services.reservations) {
              if (item.serviceid === this.filters.service) {
                this.totalNumber += 1;

                for (const slot of item.slots) {
                  if (slot.quantity) {
                    this.totalServices += slot.quantity;
                  } else {
                    this.totalServices += 1;
                  }

                  if (slot.amount) {
                    this.totalSearchRequest += slot.amount;
                  }
                }
              }
            }
          }
        },
          (err) => {
            console.log('calendar daily err', err);
          }
        );
    } else {
      this.calendarService.getShoppingCartDetails(searchObj)
        .subscribe((calendar) => {
          this.services = calendar;
          this.services.cart = true;
          this.totalSearchRequest = 0;
          this.totalServices = 0;
          this.totalNumber = 0;

          if (!this.filters.service) {
            if (this.services.amount) {
              this.totalSearchRequest = this.totalSearchRequest + this.services.amount;
            }

            this.totalNumber += this.services.services.length;

            for (const item of this.services.services) {
              if (item.quantity) {
                this.totalServices += item.quantity;
              } else {
                this.totalServices += 1;
              }
            }
          } else {
            for (const item of this.services.services) {
              if (item.serviceid === this.filters.service) {
                this.totalNumber += 1;

                if (item.quantity) {
                  this.totalServices += item.quantity;
                } else {
                  this.totalServices += 1;
                }

                if (item.amount) {
                  this.totalSearchRequest += item.amount;
                }
              }
            }
          }
        },
          (err) => {
            console.log('calendar daily err', err);
          }
        );
    }
  }

  getReservationDetails(account, event): void {
        this.services = [];

    const reservationids = [];

    for (const element of event.reservations) {
      reservationids.push(element.reservationid);
    }

    const searchObj = {
      account,
      serviceid: event.serviceid,
      reservationids: reservationids,
      servicedate: event.servicedate,
      servicetime: event.servicetime,
    };

    this.calendarService.getReservationDetails(searchObj)
      .subscribe((calendar) => {
        this.services = calendar;
        this.totalSearchRequest = 0;
        this.totalServices = 0;
        this.totalNumber = 0;

        for (const ev of this.services.reservations) {
          this.totalNumber += 1;

          if (ev.quantity) {
            this.totalServices += ev.quantity;
          } else {
            this.totalServices += 1;
          }

          if (ev.amount) {
            this.totalSearchRequest = this.totalSearchRequest + ev.amount;
          }
        }
      },
        (err) => {
          console.log('calendar daily err', err);
        }
      );
  }

  formatDate(inputDate: string) {
    const date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear();

      return day + '/' + month + '/' + year;
    }
}
}
