import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TYPOGRAPHY } from 'src/app/constants/typography.enum';
import { Account } from 'src/app/models/account/account';
import { generateId } from 'src/app/models/notification/notification';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Location } from '@angular/common';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';
import { User } from 'src/app/models/user/user';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {
  user: User = new User();
  registerForm: FormGroup;
  TYPOGRAPHY = TYPOGRAPHY;
  admin: boolean = localStorage.getItem('HechRomeoRole') ? true : false;
  vendorsList = [];
  filteredVendors = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private notificationService: NotificationService,
    private readonly adminService: AdminServiceService
  ) {}

  ngOnInit(): void {
    this.adminService.getAllVendors().subscribe(res => {
      this.vendorsList = res;
      this.filteredVendors = res;
    });

    this.registerForm = new FormGroup({
      account: new FormControl(this.user.account, [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z]+[0-9]*[^\s])+([_-]+[a-zA-Z0-9]+[^\s])*$/
        ),
      ]),
      referrername: new FormControl(this.user.referrername, [
        Validators.required,
      ]),
      referrerlastname: new FormControl(this.user.referrerlastname, [
        Validators.required,
      ]),
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.pattern(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        ),
      ]),
      confirmEmail: new FormControl(this.user.email, [
        Validators.required,
        Validators.pattern(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        ),
      ]),
      password: new FormControl(this.user.password, [
        Validators.required,
        Validators.pattern(
          /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
        ),
      ]),
      confirmPassword: new FormControl(this.user.password, [
        Validators.required,
        Validators.pattern(
          /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
        ),
      ]),
    });
    if (!this.admin) {
      this.registerForm.addControl(
        'accepttermpolicy',
        new FormControl(this.user.accepttermpolicy, [Validators.required])
      );
      this.registerForm.addControl(
        'acceptdatapolicy',
        new FormControl(this.user.acceptdatapolicy, [Validators.required])
      );
    }
  }

  register() {
    for (const controlName in this.registerForm.controls) {
      if (controlName) {
        const control = this.registerForm.controls[controlName];
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
    }
    if (
      this.registerForm.valid &&
      this.registerForm.controls['email'].value ===
        this.registerForm.controls['confirmEmail'].value &&
      this.registerForm.controls['password'].value ===
        this.registerForm.controls['confirmPassword'].value
    ) {
      for (const controlName in this.registerForm.controls) {
        if (
          controlName &&
          controlName !== 'confirmEmail' &&
          controlName !== 'confirmPassword'
        ) {
          const control = this.registerForm.controls[controlName];
          if (control) {
            this.user[controlName] = control.value;
          }
        }
      }
      sessionStorage.setItem('IgnoreErrorHech', '532');
      this.user.role = 'User';
      this.user.admincreated = this.admin;
      this.authService.register(this.user).subscribe(
        (data) => {
          if (this.admin) {
            this.returnToLastLocation();
          } else {
            this.router.navigate(['login']);
          }
        },
        (error) => {
          if (error.status == 532) {
            const notification = {
              id: generateId(),
              image: undefined,
              title: "Errore: l'account o la mail sono già stati registrati",
              body: `Url: ${error.url} `,
            };
            this.notificationService.addNotification(notification);
          }
        }
      );
    } else {
      alert(TYPOGRAPHY.INPUT_ERROR_COMPLETE_ALL_FIELDS);
    }
  }

  filterOptions(value) {
    const filterValue = value.toLowerCase();
    this.filteredVendors = this.vendorsList.filter(user =>
      user.account.toLowerCase().includes(filterValue)
    );
  }

  returnToLastLocation() {
    this.location.back();
  }
}
