import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WsProject } from 'src/app/models/ws-project/ws-project';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WsProjectsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getAllWsProjects(account: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}wsProject`, {
      params: {
        account: account
      }
    });
  }

  getWsProjectById(wsProjectId: string): Observable<WsProject> {
    return this.http.get<WsProject>(`${environment.apiUrl}wsProject/${wsProjectId}`);
  }

  getSpecificWsCall(wsProjectId: string, level): Observable<any> {
    return this.http.get(`${environment.apiUrl}wsProject/call/${wsProjectId}`, {
      params: {
        level: level
      }
    });
  }

  getLastWsSubCall(wsProjectId: string): Observable<WsProject> {
    return this.http.get<WsProject>(`${environment.apiUrl}wsProject/call/${wsProjectId}`);
  }

  saveWsProject(body: WsProject): Observable<any> {
    return this.http.post(`${environment.apiUrl}wsProject`, body);
  }

  editWsProject(body: WsProject): Observable<any> {
    return this.http.put(`${environment.apiUrl}wsProject`, body);
  }

  deleteWsProject(wsProjectId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}wsProject/${wsProjectId}`);
  }
}
