export class User {
    account = '';
    referrername = '';
    referrerlastname = '';
    email = '';
    password = '';
    registrationlink = '';
    accepttermpolicy: boolean;
    acceptdatapolicy: boolean;
    role = '';
    admincreated: boolean;
}