import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TvService {
  constructor(private http: HttpClient) {}
  urlChannelList = 'http://romeo.hech.tv:3005/';
  //CHANNELS

  getChannels(account:string) {
    let params = new HttpParams().set('account',account );
    return this.http.get<any>(`${environment.apiUrl}channel`, {
      params: params,
    });
  }

  getChannel(id) {
    return this.http.get<any>(`${environment.apiUrl}channel/${id}`);
  }

  createChannel(channel) {
    let params = new HttpParams().set(
      'staging',
      `${environment.apiUrl == 'https://callupro.open-dev.it/romeo/ws/api/'}`
    );
    return this.http.post<any>(`${environment.apiUrl}channel`, channel, {
      params,
    });
  }

  updateChannelDetails(channel) {
    let params = new HttpParams().set(
      'staging',
      `${environment.apiUrl == 'https://callupro.open-dev.it/romeo/ws/api/'}`
    );
    return this.http.post<any>(`${environment.apiUrl}channel/details`, channel, {
      params,
    });
  }

  updateChannel(channel) {
    return this.http.put<any>(`${environment.apiUrl}channel`, channel);
  }

  deleteChannel(id) {
    return this.http.delete<any>(`${environment.apiUrl}channel/${id}`);
  }

  downloadChannel(id) {
    let params = new HttpParams().set(
      'staging',
      `${environment.apiUrl == 'https://callupro.open-dev.it/romeo/ws/api/'}`
    );
    return this.http.get<any>(`${environment.apiUrl}channel/download/${id}`, {
      params,
    });
  }

  downloadChannelCategories(id) {
    return this.http.get<any>(
      `${environment.apiUrl}channel/download-categories/${id}`
    );
  }

  //CLONES

  getClones( account: string) {
    let params = new HttpParams().set('account', account);
    return this.http.get<any>(`${environment.apiUrl}clone`, { params: params });
  }

  getClone(id) {
    return this.http.get<any>(`${environment.apiUrl}clone/${id}`);
  }

  createClone(channel) {
    return this.http.post<any>(`${environment.apiUrl}clone`, channel);
  }

  updateClone(channel) {
    return this.http.put<any>(`${environment.apiUrl}clone`, channel);
  }

  deleteClone(id) {
    return this.http.delete<any>(`${environment.apiUrl}clone/${id}`);
  }

  downloadClone(id) {
    return this.http.get<any>(`${environment.apiUrl}clone/download/${id}`);
  }

  //FIRMWARES

  getFirmwares() {
    return this.http.get<any>(`${environment.apiUrl}firmware`);
  }

  getFirmware(id) {
    return this.http.get<any>(`${environment.apiUrl}firmware/${id}`);
  }

  createFirmware(firmware) {
    return this.http.post<any>(`${environment.apiUrl}firmware`, firmware);
  }

  updateFirmware(firmware) {
    return this.http.put<any>(`${environment.apiUrl}firmware`, firmware);
  }

  deleteFirmware(id) {
    return this.http.delete<any>(`${environment.apiUrl}firmware/${id}`);
  }

  //SETTINGS

  getSettings( account : string) {
    let params = new HttpParams().set('account', account);
    return this.http.get<any>(`${environment.apiUrl}setting`, {
      params: params,
    });
  }

  getSetting(id) {
    return this.http.get<any>(`${environment.apiUrl}setting/${id}`);
  }

  createSetting(settings) {
    return this.http.post<any>(`${environment.apiUrl}setting`, settings);
  }

  updateSetting(settings) {
    return this.http.put<any>(`${environment.apiUrl}setting`, settings);
  }

  deleteSettings(id) {
    return this.http.delete<any>(`${environment.apiUrl}setting/${id}`);
  }

  //TV

  getTvsUpdates() {
    let params = new HttpParams().set(
      'account',
      localStorage.getItem('HechRomeoAccount')
    );
    return this.http.get<any>(`${environment.apiUrl}tv/update`, {
      params: params,
    });
  }

  getTvs( account: string) {
    let params = new HttpParams().set('account',account);
    return this.http.get<any>(`${environment.apiUrl}tv`, {
      params: params,
    });
  }

  getTv(id) {
    return this.http.get<any>(`${environment.apiUrl}tv/${id}`);
  }

  createTv(tv) {
    return this.http.post<any>(`${environment.apiUrl}tv`, tv);
  }

  updateTv(tv) {
    return this.http.put<any>(`${environment.apiUrl}tv`, tv);
  }

  deleteTv(id) {
    return this.http.delete<any>(`${environment.apiUrl}tv/${id}`);
  }
}
