import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountDetailService } from 'src/app/services/account/account-detail.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit {
  account = localStorage.getItem('HechRomeoAccount');
  role = localStorage.getItem('HechRomeoRole');
  usersList = [];
  vendorToDelete;
  searchValue: string;
  accountEmail: string;

  constructor(
    private readonly accountService: AccountService,
    private accountDetailService: AccountDetailService,
    private adminService: AdminServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.searchValue = '';
    if (!localStorage.getItem('OriginalAccount')) {
      this.accountService.getVendorData().subscribe(res => {
        this.accountEmail = res.email;
        this.getUsersList(this.accountEmail);
      });
    } else {
      this.accountService.getOriginalVendorData().subscribe(res => {
        this.accountEmail = res.email;
        this.getUsersList(this.accountEmail);
      });
    }
  }

  getUsersList(email: string) {
    this.accountService.getLinkedUsersForManagement(email).subscribe(res => {
      this.usersList = res;
    });
  }

  impersonate(account: string) {
    if (account === localStorage.getItem('OriginalAccount')) {
      localStorage.removeItem('categoriesServices');
      localStorage.removeItem('digitalSignage');
      localStorage.removeItem('guestsManagement');
      localStorage.removeItem('tvManagement');
      localStorage.removeItem('integrations');
      localStorage.removeItem('HechRomeoVendor');

      if (this.role == "Vendor") {
        if (!localStorage.getItem('OriginalAccount')) {
          localStorage.setItem('OriginalAccount', this.account);
        }
        localStorage.setItem('HechRomeoAccount', account);
        this.accountDetailService.setAccount(account);
      }
      else {
        localStorage.setItem('HechRomeoVendor', account);
      }
      window.location.reload();

    } else {
      this.accountService.getPermissions(account, this.accountEmail).subscribe(res => {
        localStorage.setItem('categoriesServices', res.categoriesServices);
        localStorage.setItem('digitalSignage', res.digitalSignage);
        localStorage.setItem('guestsManagement', res.guestsManagement);
        localStorage.setItem('tvManagement', res.tvManagement);
        localStorage.setItem('integrations', res.integrations);
        localStorage.removeItem('HechRomeoVendor');
  
        if (this.role == "Vendor") {
          if (!localStorage.getItem('OriginalAccount')) {
            localStorage.setItem('OriginalAccount', this.account);
          }
          localStorage.setItem('HechRomeoAccount', account);
          this.accountDetailService.setAccount(account);
        }
        else {
          localStorage.setItem('HechRomeoVendor', account);
        }
        window.location.reload();
      }, err => {
        console.log(err);
      });
    }
  }

  deleteUser(username: string) {
    this.adminService.deleteVendor(username).subscribe(() => {
      this.getUsersList(this.accountEmail);
    });
  }

  infoUser(username: string) {
    this.cleanPermissions();
    localStorage.setItem('HechRomeoAccount', username);
    this.accountDetailService.setAccount(username);
    this.router.navigate(['accountInfo']);
  }

  cleanPermissions() {
    localStorage.removeItem('categoriesServices');
    localStorage.removeItem('digitalSignage');
    localStorage.removeItem('guestsManagement');
    localStorage.removeItem('tvManagement');
    localStorage.removeItem('integrations');
    localStorage.removeItem('HechRomeoVendor');
  }
}
