import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';
import { Router } from '@angular/router';
import {AccountDetailService} from '../../../services/account/account-detail.service';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
})
export class VendorListComponent implements OnInit {
  vendorList = [];
  vendorToDelete;
  searchValue: string;
  constructor(
    private accountDetailService: AccountDetailService,
    private adminService: AdminServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getVendorList(0);
    this.searchValue = '';
  }

  getVendorList(page: number) {
    this.adminService.getVedorList(page).subscribe((data) => {
      this.vendorList = data;
    });
  }

  impersonate(account: string) {
    this.cleanPermissions();

    if (!localStorage.getItem('OriginalAccount'))
      localStorage.setItem('OriginalAccount', localStorage.getItem('HechRomeoAccount'));
    else if (account == localStorage.getItem('OriginalAccount'))
      localStorage.removeItem('OriginalAccount');
    
    localStorage.setItem('HechRomeoAccount', account);
    this.accountDetailService.setAccount(account);
    this.router.navigate(['categoriesAndServices'], {state: {switched: true}});
  }

  deleteVendor(username: string) {
    this.adminService.deleteVendor(username).subscribe(() => {
      this.getVendorList(0);
    });
  }

  infoVendor(username: string) {
    this.cleanPermissions();
    localStorage.setItem('HechRomeoAccount', username);
    this.accountDetailService.setAccount(username);
    this.router.navigate(['accountInfo'], {state: {switched: true}});
  }

  cleanPermissions() {
    localStorage.removeItem('categoriesServices');
    localStorage.removeItem('digitalSignage');
    localStorage.removeItem('guestsManagement');
    localStorage.removeItem('tvManagement');
    localStorage.removeItem('integrations');
    localStorage.removeItem('HechRomeoVendor');
  }
}
