<div class="row m-0 w-100">
  <div class="sidenav background-darkGrey" id="accordionExample">
    <div class="background-theme">
      <div>
        <div>
          <span class="material-icons assistance-icon" routerLink="/assistance">
            handyman
          </span>
        </div>
        <img
          src="assets/img/Logo Bianco Hech Romeo.Guide.png"
          style="width: 110px"
        />
      </div>
    </div>
    <div *ngIf="admin">
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseZero"
        aria-expanded="false"
        aria-controls="collapseZero"
      >
        <img
          src="assets/img/Pittogramma gestione ospiti.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Amministrazione" | translate
          }}</span>
      </div>
    </div>
    <div
      class="details collapse"
      id="collapseZero"
      aria-labelledby="headingZero"
    >
      <div routerLink="/vendorList">
        <span>
          {{ "Elenco fornitori" | translate }}</span
        >
      </div>
      <div class="spacer"></div>
      <div routerLink="/manageVariables">
        <span >
          {{ "Impostazioni avanzate" | translate }}
        </span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseOne"
        aria-expanded="false"
        aria-controls="collapseOne"
      >
        <img
          src="assets/img/Pittogramma categorie e servizi.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Categorie e Servizi" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseOne" aria-labelledby="headingOne">
      <div routerLink="/categoriesAndServices">
        <span>{{
          "Elenco categorie e servizi" | translate
          }}</span>
      </div>
      <div routerLink="/downloadPdf">
        <span>{{
          "Download pdf" | translate
          }}</span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseSix"
        aria-expanded="false"
        aria-controls="collapseSix"
      >
        <img
          src="assets/img/signage.png"
          style="width: 80px; filter: invert(100%);"
        />
        <span class="d-block text-white" style="font-size: 14px;">{{
          "Digital Signage" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseSix" aria-labelledby="headingOne">
      <div routerLink="/signage">
        <span>{{ "Monitor" | translate }}</span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        <img
          src="assets/img/Pittogramma gestione ospiti.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Gestione ospiti" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseTwo">
      <div routerLink="/calendar">
        <span>{{
          "Calendario prenotazioni" | translate
          }}</span>
      </div>
      <div class="spacer"></div>
      <div routerLink="/calendarReport">
        <span>{{
          "Calendario partners" | translate
          }}</span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseFive"
        aria-expanded="false"
        aria-controls="collapseFive"
      >
        <img
          src="assets/img/tv.png"
          style="width: 40px; filter: invert(100%);"
        />
        <span class="d-block text-white" style="font-size: 14px;">{{
          "Gestione tv" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseFive">
      <div routerLink="/tvChannels">
        <span>{{ "Canali tv" | translate }}</span>
      </div>
      <div class="spacer"></div>
      <div routerLink="/clones">
        <span>{{ "Cloni" | translate }}</span>
      </div>
      <div class="spacer"></div>
      <div routerLink="/firmware">
        <span>{{ "Firmware" | translate }}</span>
      </div>
      <div class="spacer"></div>
      <div routerLink="/tvSettings">
        <span>{{ "Impostazioni" | translate }}</span>
      </div>
      <div class="spacer"></div>
      <div routerLink="/tvList">
        <span>{{ "TV" | translate }}</span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        <img
          src="assets/img/Pittogramma gestione account.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Gestione account" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseThree">
      <div routerLink="/accountInfo">
        <span>{{
          "Anagrafica Fornitore" | translate
          }}</span>
      </div>
      <div class="spacer"></div>
      <div *ngIf="listUsers"  routerLink="/admin-list">
        <span>{{ "Elenco Utenti" | translate }}</span>
      </div>
      <div *ngIf="listUsers" class="spacer"></div>
      <div routerLink="/settings">
        <span>{{ "Impostazioni" | translate }}</span>
      </div>
      <div class="spacer"></div>
      <div>
        <span (click)="logout()">Logout</span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseFour"
        aria-expanded="false"
        aria-controls="collapseFour"
      >
        <img
          src="assets/img/335-3359599_fluency-world-icon-white-png-removebg-preview.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Lingua" | translate
          }}</span>
      </div>
    </div>
    <div
      class="details collapse"
      id="collapseFour"
      aria-labelledby="headingFour"
    >
      <div>
        <span (click)="changeLanguage('it')">
          {{ "Italiano" | translate }}</span
        >
      </div>
      <div class="spacer"></div>
      <div>
        <span (click)="changeLanguage('en')">
          {{ "English" | translate }}
        </span>
      </div>
    </div>
    <div>
      <div
        class="text-center"
        style="width: 100%; cursor: pointer"
        data-toggle="collapse"
        data-target="#collapseSeven"
        aria-expanded="false"
        aria-controls="collapseSeven"
      >
        <img
          src="assets/img/Pittogramma categorie e servizi.png"
          style="width: 40px"
        />
        <span class="d-block text-white" style="font-size: 14px">{{
          "Intergazioni" | translate
          }}</span>
      </div>
    </div>
    <div class="details collapse" id="collapseSeven" aria-labelledby="headingSix">
      <div>
        <span routerLink="/webServices">{{
          "Web Service"
          }}</span>
      </div>
    </div>
  </div>

  <div class="main vh-100">
    <div *ngIf="accountDetailService.haveAccount()" class="row mx-0 my-2">
      <div class="col-9">
        <span class="font-weight-bold">
          Account: {{accountDetailService.getAccount()}}
        </span>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
