<div class="container-fluid p-0 my-2">
  <div class="row mx-0">
    <div class="col-xl-1"></div>

    <div class="col-12 col-xl-11">
      <img src="assets/img/Logotipo Hech Romeo.Guide.png" style="width: 250px" />
    </div>
  </div>

  <div class="row mx-0">
    <div class="col-xl-1"></div>

    <div class="col-12 col-xl-11">
      <h4 style="
            margin: 20px 0 60px 0;
            color: rgb(174, 202, 65);
            line-height: normal;
            font-size: 24px;
            font-weight: bold;
          ">
        {{
        "Registrati per avere tutti i servizi a portata di click" | translate
        }}!
      </h4>
    </div>
  </div>

  <form [formGroup]="registerForm" class="w-100">
    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>

      <div class="col-6 col-xl-5 w-100" style="border-right: 0.1px solid #aeca41">
        <div class="form-group">
          <label for="account">
            {{ "Seleziona il tuo progetto account" | translate }}: Account</label>
          <input type="text" class="form-control" id="account" name="account"
            placeholder="{{ 'Inserici il nome del tuo account' | translate }}" formControlName="account" required [matAutocomplete]="autoAccount"
            (input)="registerForm.controls['account'].updateValueAndValidity(); filterOptions($event.target.value)" />
            <mat-autocomplete #autoAccount="matAutocomplete">
              <ng-container *ngFor="let vendor of filteredVendors">
                  <mat-option [value]="vendor.account">{{vendor.account}}</mat-option>
              </ng-container>
            </mat-autocomplete>
          <ng-container *ngIf="
                registerForm.controls['account'].invalid &&
                (registerForm.controls['account'].dirty ||
                  registerForm.controls['account'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="referrername">{{ "Nome" | translate }}</label>
          <input type="text" class="form-control" id="referrername" name="referrername"
            placeholder="{{ 'Inserici il tuo nome' | translate }}" formControlName="referrername" required (input)="
                registerForm.controls['referrername'].updateValueAndValidity()
              " />
          <ng-container *ngIf="
                registerForm.controls['referrername'].invalid &&
                (registerForm.controls['referrername'].dirty ||
                  registerForm.controls['referrername'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="referrerlastname">{{ "Cognome" | translate }}</label>
          <input type="text" class="form-control" id="referrerlastname" name="referrerlastname"
            placeholder="{{ 'Inserici il tuo cognome' | translate }}" formControlName="referrerlastname" required
            (input)="
                registerForm.controls['referrerlastname'].updateValueAndValidity()
              " />
          <ng-container *ngIf="
                registerForm.controls['referrerlastname'].invalid &&
                (registerForm.controls['referrerlastname'].dirty ||
                  registerForm.controls['referrerlastname'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
      </div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="email">{{ "Indirizzo email" | translate }}</label>
          <input type="email" class="form-control" id="email" name="email"
            placeholder="{{ 'Inserici il tuo indirizzo email' | translate }}" formControlName="email" required
            (input)="registerForm.controls['email'].updateValueAndValidity()" />
          <ng-container *ngIf="
                registerForm.controls['email'].invalid &&
                (registerForm.controls['email'].dirty ||
                  registerForm.controls['email'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="confirmEmail">{{
            "Conferma indirizzo email" | translate
            }}</label>
          <input type="email" class="form-control" id="confirmEmail" name="confirmEmail"
            placeholder="{{ 'Conferma il tuo indirizzo email' | translate }}" formControlName="confirmEmail" required
            (input)="
                  registerForm.controls['confirmEmail'].updateValueAndValidity()
                " />
          <ng-container *ngIf="
                  registerForm.controls['email'].value !==
                  registerForm.controls['confirmEmail'].value
                ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"></app-small-text>
          </ng-container>
          <ng-container *ngIf="
                  registerForm.controls['confirmEmail'].invalid &&
                  (registerForm.controls['confirmEmail'].dirty ||
                    registerForm.controls['confirmEmail'].touched)
                ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="pasword">{{ "Password" | translate }}&nbsp;<abbr
              title="{{ TYPOGRAPHY.PASSWORD_HINT }}">?</abbr></label>
          <input type="password" class="form-control" id="password" name="password"
            placeholder="{{ 'Inserici la tua password' | translate }}" formControlName="password" required
            (input)="registerForm.controls['password'].updateValueAndValidity()" />
          <ng-container *ngIf="
                    registerForm.controls['password'].invalid &&
                    (registerForm.controls['password'].dirty ||
                      registerForm.controls['password'].touched)
                  ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="confirmPasword">{{
            "Conferma password" | translate
            }}</label>
          <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
            placeholder="{{ 'Conferma la tua password' | translate }}" formControlName="confirmPassword" required
            (input)="
                    registerForm.controls['confirmPassword'].updateValueAndValidity()
                  " />
          <ng-container *ngIf="
                    registerForm.controls['password'].value !==
                    registerForm.controls['confirmPassword'].value
                  ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"></app-small-text>
          </ng-container>
          <ng-container *ngIf="
                    registerForm.controls['confirmPassword'].invalid &&
                    (registerForm.controls['confirmPassword'].dirty ||
                      registerForm.controls['confirmPassword'].touched)
                  ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group form-check" *ngIf="!admin">
          <input type="checkbox" class="form-check-input" id="accepttermpolicy" name="accepttermpolicy"
            formControlName="accepttermpolicy" required (input)="
                registerForm.controls['accepttermpolicy'].updateValueAndValidity()
              " />
          <label class="form-check-label" for="accepttermpolicy">{{ "Accetto le" | translate }}&nbsp;</label>
          <a class="underlined font-weight-bold text-uppercase" target="_blank"
            href="https://hech.tv/terms-of-service/">{{ "condizioni Generali del servizio" | translate }}</a>
          <ng-container *ngIf="
                registerForm.controls['accepttermpolicy'].invalid &&
                (registerForm.controls['accepttermpolicy'].dirty ||
                  registerForm.controls['accepttermpolicy'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_ERROR_TERMS_OF_SERVICE"></app-small-text>
          </ng-container>
        </div>
        <div class="form-group form-check" *ngIf="!admin">
          <input type="checkbox" class="form-check-input" id="acceptdatapolicy" name="acceptdatapolicy"
            formControlName="acceptdatapolicy" required (input)="
                registerForm.controls['acceptdatapolicy'].updateValueAndValidity()
              " />
          <label class="form-check-label" for="acceptdatapolicy">{{
            "Accetto i termini di gestione dei dati" | translate
            }}</label>
          <ng-container *ngIf="
                registerForm.controls['acceptdatapolicy'].invalid &&
                (registerForm.controls['acceptdatapolicy'].dirty ||
                  registerForm.controls['acceptdatapolicy'].touched)
              ">
            <app-small-text [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"></app-small-text>
          </ng-container>
        </div>
        <div class="btns-registration">
          <button type="button" class="pl-4 pr-4 mt-2 btn btn-primary" (click)="register()">
            {{ admin ? ("Registra" | translate) : ("Registrati" | translate) }}
          </button>
          <button *ngIf="admin" type="button" class="pl-4 pr-4 mt-2 ml-3 btn btn-secondary"
            (click)="returnToLastLocation()">
            {{ "Annulla" | translate }}
          </button>
        </div>
      </div>

      <div class="col-xl-1"></div>
    </div>
  </form>
</div>