<div class="container-fluid vh-100 vw-100">
  <div class="row mx-0 h-100 d-flex justify-content-center">
    <div
      class="col-6 col-xl-4 h-100 d-flex justify-content-center align-items-center"
    >
      <img
        src="assets/img/Illustrazione pagina Login con sfondo.png"
        style="max-width: 100%; max-height: 100vh"
      />
    </div>

    <div class="col-6 col-xl-3 p-0 w-100 d-flex align-items-center">
      <div class="container-fluid p-0">
        <div class="row mx-0 mb-3 w-100 justify-content-end">
          <a
            href="http://hech.tv/#assistenza"
            target="_blank"
            style="
              margin: 20px 0;
              text-align: right;
              color: rgb(174, 202, 65);
              line-height: normal;
              font-size: 17px;
              cursor: pointer;
              text-decoration: none;
            "
          >
            {{ "Richiedi assistenza" | translate }}
          </a>
        </div>

        <div class="row mx-0 w-100 justify-content-center">
          <img
            src="assets/img/Logotipo Hech Romeo.Guide.png"
            style="width: 250px"
          />
        </div>

        <div class="row mx-0 w-100 justify-content-center">
          <p
            style="
              margin: 20px 0;
              text-align: center;
              color: rgb(174, 202, 65);
              line-height: normal;
              font-size: 24px;
              font-weight: bold;
            "
          >
            {{ "Il tuo Concierge e i tuoi Menu" | translate }} <br />
            {{ "non sono mai stati così digitali" | translate }}!
          </p>
        </div>

        <div class="row mx-0">
          <h4
            style="color: rgb(91, 91, 91); font-size: 20px"
            *ngIf="!isChangePassword"
          >
            {{
              isResetPassword
                ? TYPOGRAPHY.RESET_PASSWORD_LABEL
                : TYPOGRAPHY.LOGIN_LABEL
            }}
          </h4>

          <div class="form-group w-100">
            <input
              *ngIf="!isResetPassword"
              placeholder="account"
              type="text"
              class="form-control"
              id="account"
              aria-describedby="account"
              name="account"
              [(ngModel)]="account"
            />
          </div>

          <div class="form-group w-100">
            <input
              *ngIf="!isResetPassword"
              placeholder="password"
              type="password"
              class="form-control"
              id="password"
              name="password"
              [(ngModel)]="password"
            />
            <small
              *ngIf="isResetPassword"
              class="form-text text-muted text-left"
              style="cursor: pointer"
              (click)="showChangePassword(false)"
              >&#60; {{ "Indietro" | translate }}</small
            >
            <input
              *ngIf="isResetPassword && !isChangePassword"
              placeholder="email"
              type="text"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="email"
              (input)="validateEmail(email)"
            />
            <input
              *ngIf="isResetPassword && !isChangePassword"
              placeholder="account"
              type="text"
              class="form-control"
              id="resetAccount"
              name="resetAccount"
              [(ngModel)]="resetAccount"
            />
            <app-change-password
              *ngIf="isChangePassword"
              [oldPassword]="password"
              [account]="account"
              (login)="login($event)"
            >
            </app-change-password>
            <small *ngIf="showError" class="form-text error text-left">{{
              "Inserisci una mail valida" | translate
            }}</small>
            <small
              *ngIf="!isResetPassword"
              class="form-text text-muted text-right"
              style="cursor: pointer"
              (click)="showResetPassword()"
              >{{ "Hai dimenticato la password?" | translate }}</small
            >
          </div>
        </div>

        <div class="text-center" *ngIf="!isChangePassword">
          <button
            type="button"
            class="pl-4 pr-4 btn btn-primary"
            (click)="isResetPassword ? resetPassword() : login()"
          >
            {{
              isResetPassword
                ? TYPOGRAPHY.RESET_PASSWORD_BUTTON
                : TYPOGRAPHY.LOGIN_BUTTON
            }}
          </button>
        </div>
        <hr />
        <div class="centered">
          <span style="font-size: 14px">{{
            "Non hai un account?" | translate
          }}</span
          >&nbsp;
          <span
            class="color-theme font-weight-bold"
            style="font-size: 14px; cursor: pointer"
            routerLink="/register"
            >{{ "Registrati" | translate }}!</span
          >
        </div>
        <div class="mb-5"></div>
      </div>
    </div>
  </div>
</div>

<!--<div class="d-flex justify-content-center align-items-center min-height">-->
<!--  <div class="d-flex" style="flex-direction: row; max-width: 100vw">-->
<!--    <div style="width: 50%">-->
<!--      <img-->
<!--        src="assets/img/Illustrazione pagina Login con sfondo.png"-->
<!--        style="max-width: 100%; max-height: 100vh"-->
<!--      />-->
<!--    </div>-->
<!--    <div style="width: 50%">-->
<!--      <div-->
<!--        style="-->
<!--          display: flex;-->
<!--          justify-content: center;-->
<!--          align-items: center;-->
<!--          height: 100%;-->
<!--        "-->
<!--      >-->
<!--        <form>-->
<!--          <div class="form-group">-->
<!--            <div class="mb-5 d-flex" style="justify-content: flex-end">-->
<!--              <a-->
<!--                href="http://hech.tv/#assistenza"-->
<!--                target="_blank"-->
<!--                style="-->
<!--                  margin: 20px 0;-->
<!--                  text-align: right;-->
<!--                  color: rgb(174, 202, 65);-->
<!--                  line-height: normal;-->
<!--                  font-size: 17px;-->
<!--                  cursor: pointer;-->
<!--                  text-decoration: none;-->
<!--                "-->
<!--              >-->
<!--                Richiedi assistenza-->
<!--              </a>-->
<!--            </div>-->
<!--            <div style="text-align: center">-->
<!--              <img-->
<!--                src="assets/img/Logotipo Hech Romeo.Guide.png"-->
<!--                style="width: 250px"-->
<!--              />-->
<!--            </div>-->

<!--            <p-->
<!--              style="-->
<!--                margin: 20px 0;-->
<!--                text-align: center;-->
<!--                color: rgb(174, 202, 65);-->
<!--                line-height: normal;-->
<!--                font-size: 24px;-->
<!--                font-weight: bold;-->
<!--              "-->
<!--            >-->
<!--              Il tuo Concierge e i tuoi Menu <br />-->
<!--              non sono mai stati così digitali!-->
<!--            </p>-->
<!--            <h4-->
<!--              style="color: rgb(91, 91, 91); font-size: 20px"-->
<!--              *ngIf="!isChangePassword"-->
<!--            >-->
<!--              {{-->
<!--                isResetPassword-->
<!--                  ? TYPOGRAPHY.RESET_PASSWORD_LABEL-->
<!--                  : TYPOGRAPHY.LOGIN_LABEL-->
<!--              }}-->
<!--            </h4>-->
<!--            <input-->
<!--              *ngIf="!isResetPassword"-->
<!--              placeholder="account"-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              id="account"-->
<!--              aria-describedby="account"-->
<!--              name="account"-->
<!--              [(ngModel)]="account"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="form-group">-->
<!--            <input-->
<!--              *ngIf="!isResetPassword"-->
<!--              placeholder="password"-->
<!--              type="password"-->
<!--              class="form-control"-->
<!--              id="password"-->
<!--              name="password"-->
<!--              [(ngModel)]="password"-->
<!--            />-->
<!--            <small-->
<!--              *ngIf="isResetPassword"-->
<!--              class="form-text text-muted text-left"-->
<!--              style="cursor: pointer"-->
<!--              (click)="showChangePassword(false)"-->
<!--              >&#60; Indietro</small-->
<!--            >-->
<!--            <input-->
<!--              *ngIf="isResetPassword && !isChangePassword"-->
<!--              placeholder="email"-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              id="email"-->
<!--              name="email"-->
<!--              [(ngModel)]="email"-->
<!--              (input)="validateEmail(email)"-->
<!--            />-->
<!--            <input-->
<!--              *ngIf="isResetPassword && !isChangePassword"-->
<!--              placeholder="account"-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              id="resetAccount"-->
<!--              name="resetAccount"-->
<!--              [(ngModel)]="resetAccount"-->

<!--            />-->
<!--            <app-change-password-->
<!--              *ngIf="isChangePassword"-->
<!--              [oldPassword]="password"-->
<!--              [account]="account"-->
<!--              (login)="login($event)"-->
<!--            >-->
<!--            </app-change-password>-->
<!--            <small *ngIf="showError" class="form-text error text-left"-->
<!--              >Inserisci una mail valida</small-->
<!--            >-->
<!--            <small-->
<!--              *ngIf="!isResetPassword"-->
<!--              class="form-text text-muted text-right"-->
<!--              style="cursor: pointer"-->
<!--              (click)="showResetPassword()"-->
<!--              >Hai dimenticato la password?</small-->
<!--            >-->
<!--          </div>-->
<!--          <div class="text-center" *ngIf="!isChangePassword">-->
<!--            <button-->
<!--              type="button"-->
<!--              class="pl-4 pr-4 btn btn-primary"-->
<!--              (click)="isResetPassword ? resetPassword() : login()"-->
<!--            >-->
<!--              {{-->
<!--                isResetPassword-->
<!--                  ? TYPOGRAPHY.RESET_PASSWORD_BUTTON-->
<!--                  : TYPOGRAPHY.LOGIN_BUTTON-->
<!--              }}-->
<!--            </button>-->
<!--          </div>-->
<!--          <hr />-->
<!--          <span style="font-size: 14px">Non hai un account?</span>&nbsp;-->
<!--          <span-->
<!--            class="color-theme font-weight-bold"-->
<!--            style="font-size: 14px; cursor: pointer"-->
<!--            routerLink="/register"-->
<!--            >Registrati!</span-->
<!--          >-->
<!--          <div class="mb-5"></div>-->
<!--        </form>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
