import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountDetailService } from 'src/app/services/account/account-detail.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AddUserDialogComponent } from 'src/app/shared/components/add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {
  account = localStorage.getItem('HechRomeoAccount');
  searchValue: string;
  usersList = [];
  userCreate: any;
  userToDelete: any;
  admin: boolean = localStorage.getItem('HechRomeoRole') == 'Manager';
  availableUsers = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService,
    private readonly accountDetailService: AccountDetailService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.userToDelete = {};
    this.resetCreateUser();
    this.getUsersList();
  }

  saveUser() {
    this.accountService.createAccountUser(this.userCreate).subscribe(res => {
      this.resetCreateUser();
      this.getUsersList();
    }, err => {
      console.log(err);
    });
  }

  deleteUser() {
    this.accountService.deleteLinkedAccount(this.userToDelete).subscribe(res => {
      this.userToDelete = {};
      this.getUsersList();
    });
  }

  resetCreateUser(): void {
    this.userCreate = {};
    this.userCreate.account = localStorage.getItem('HechRomeoAccount');
  }

  getUsersList(): void {
    this.usersList = [];

    this.accountService.getLinkedUsers(this.account).subscribe(res => {
      this.usersList = res.users;
    });
  }

  editUser(userToEdit) {
    let user = {
      id: userToEdit.id,
      email: userToEdit.email,
      account: this.account,
      categoriesServices: userToEdit.categoriesServices,
      digitalSignage: userToEdit.digitalSignage,
      guestsManagement: userToEdit.guestsManagement,
      tvManagement: userToEdit.tvManagement,
      integrations: userToEdit.integrations
    }

    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      data: {
        title: 'Modifica un utente',
        user: user,
        isVendor: true,
        availableUsers: this.availableUsers,
        edit: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountService.editUser(user).subscribe(res => {
          this.resetCreateUser();
          this.getUsersList();
        });
      }
    });
  }

  switchPermission(userToEdit, permission: string) {

    if (this.admin) {
      userToEdit[permission] = !userToEdit[permission];

      let user = {
        id: userToEdit.id,
        email: userToEdit.email,
        account: this.account,
        categoriesServices: userToEdit.categoriesServices,
        digitalSignage: userToEdit.digitalSignage,
        guestsManagement: userToEdit.guestsManagement,
        tvManagement: userToEdit.tvManagement,
        integrations: userToEdit.integrations
      }

      this.accountService.editUser(user).subscribe(res => {});
    }
  }

  impersonate(account: string, email: string, isInfo?: boolean) {
    if (account === localStorage.getItem('OriginalAccount')) {
      localStorage.removeItem('categoriesServices');
      localStorage.removeItem('digitalSignage');
      localStorage.removeItem('guestsManagement');
      localStorage.removeItem('tvManagement');
      localStorage.removeItem('integrations');
      localStorage.removeItem('HechRomeoVendor');
      localStorage.setItem('HechRomeoAccount', account);
      this.accountDetailService.setAccount(account);
      isInfo ? this.router.navigate(['userInfo']) : window.location.reload();

    } else {
      this.accountService.getPermissions(this.account, email).subscribe(res => {
        localStorage.setItem('categoriesServices', res.categoriesServices);
        localStorage.setItem('digitalSignage', res.digitalSignage);
        localStorage.setItem('guestsManagement', res.guestsManagement);
        localStorage.setItem('tvManagement', res.tvManagement);
        localStorage.setItem('integrations', res.integrations);
        localStorage.setItem('HechRomeoVendor', localStorage.getItem('HechRomeoAccount'));
        localStorage.setItem('HechRomeoAccount', account);
        this.accountDetailService.setAccount(account);
        isInfo ? this.router.navigate(['userInfo'], {state: {switched: true}}) : window.location.reload();

      }, err => {
        console.log(err);
      });
    }
  }
}
