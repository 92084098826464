import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { Calendar } from '@fullcalendar/core';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
declare var $: any;
import * as moment from 'moment';
import { Filters } from 'src/app/models/filters/filters';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Category, Service } from 'src/app/models/categoryTree/category-tree';
import { LabelInfo, Reservation } from 'src/app/models/reservation/reservation';
import { Label } from 'src/app/models/label/label';
import { LabelService } from 'src/app/services/label/label.service';
import { Cart, CartItems } from 'src/app/models/cart/cart';
import { DepartmentService } from '../../../services/department/department.service';
import { Department } from '../../../models/department/department';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-calendar-advanced',
  templateUrl: './calendar-advanced.component.html',
  styleUrls: ['./calendar-advanced.component.scss'],
})
export class CalendarAdvancedComponent implements OnInit {
  account: any = localStorage.getItem('HechRomeoAccount');
  events: any = [];
  services: any = [];
  filter = undefined;
  calendar;
  previousDate;
  previousEvent;
  eventSelected;
  reservationToCancel = {
    reservationid: undefined,
    servicedate: undefined,
    servicetime: undefined,
    email: undefined,
    serviceid: undefined,
  };
  tzoffset = new Date().getTimezoneOffset() * 60000;

  filters: Filters = new Filters();
  newFilters: Filters = new Filters();
  allCategories: Category[] = [];
  allServices: Service[] = [];
  cartServices: Service[] = [];
  cartServicesNotActivated: Service[] = [];
  newReservation: Reservation = new Reservation();
  newCart: Cart = new Cart();
  selectedService;
  selectedLabel;
  allLabels: Label[] = [];
  availableLabels: Label[] = [];
  categoriesSelect = {
    selectedCategory0: undefined,
    selectedCategory1: undefined,
    selectedCategory2: undefined,
    selectedCategory3: undefined,
    categoryObj0: { categories: [] },
    categoryObj1: { categories: [] },
    categoryObj2: { categories: [] },
    categoryObj3: { categories: [] },
    currentServiceList: [],
    selectedServiceInList: undefined,
  };
  filterDate = { from: '', to: '' };
  newFilterDate;
  newCategoriesSelect;
  hideCalendar = false;
  cartLabelsList = [];
  isDetailsService: boolean = false;
  listSearchRequest = [];
  listSearchRequestShopping = [];
  totalSearchRequest: number = 0;
  totalServices: number = 0;
  totalNumber: number = 0;
  serviceSelected: string;
  reservations = [];

  allDepartments: Department[];
  lastEvSelected: any = undefined;
  everyService: Service[] = [];
  currentAdvancedService: Service;
  timeslots = [];
  monthView = false;

  constructor(
    private calendarService: CalendarService,
    private categoriesService: CategoriesService,
    private labelService: LabelService,
    private departmentService: DepartmentService,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private accountService: AccountService
  ) {
    const that = this;
  }

  ngOnViewInit() {}
  // ngOnDestroy() {
  //   $(document).off('click');
  // }

  ngOnInit(): void {
    let selectedDay;
    this.route.queryParams.subscribe((data) => {
      selectedDay = data['day'];
      this.serviceSelected = data['service'];

      const that = this;
      this.filters.account = this.account;
      this.newFilters.account = this.account;
      const calendarEl = document.getElementById('calendar1');
      this.newCategoriesSelect = { ...this.categoriesSelect };
      this.newFilterDate = { ...this.filterDate };
      this.getAllCategories();
      this.getAllLabels();
      this.getAllDepartments();
      this.isDetailsService = false;
      this.listSearchRequest = [];
      this.listSearchRequestShopping = [];
      this.totalSearchRequest = 0;
      this.totalServices = 0;
      this.totalNumber = 0;

      this.calendar = new Calendar(calendarEl, {
        defaultDate: moment(selectedDay, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        locales: itLocale,
        locale: 'it',
        header: {
          left: 'prev,next today',
          center: 'title',
          right:
            'listYear, resourceTimelineYear, dayGridMonth,timeGridWeek,timeGridDay',
        },
        buttonText: {
          today: 'Periodo Attuale',
        },
        viewSkeletonRender: (info) => {
          if (info.view.type == 'dayGridMonth') {
            this.monthView = true;
          } else {
            this.monthView = false;
          }
          this.getDataForCalendar();
        },
        dateClick: (info) => {
          // alert('Clicked on: ' + info.dateStr);
          // alert('Current view: ' + info.view.type);
          // change the day's background color just for fun
          // if (this.previousDate) {
          //   this.previousDate.dayEl.style.backgroundColor = 'white';
          // }
          // info.dayEl.style.backgroundColor = 'lightGrey';
          // this.previousDate = info;
        },
        eventClick: (info) => {
          // const eventclicked = new Date(
          //   info.event.start.getTime() - this.tzoffset
          // )
          //   .toISOString()
          //   .substring(0, 10)
          //   .split('-')
          //   .reverse()
          //   .join('/');

          that.eventSelected = info.event.extendedProps;
          // console.log('event', eventclicked, info.event.extendedProps);

          // that.getDaily(that.account, eventclicked, '');
          console.log('ID', that.eventSelected);
          if (
            !this.isDetailsService ||
            !this.lastEvSelected ||
            this.lastEvSelected.id !== that.eventSelected.id
          ) {
            this.detailsAdvancedService(that.account, that.eventSelected);
            // if (that.eventSelected.cart) {
            //   this.getShoppingCartDetails(that.account, that.eventSelected.ev);
            // } else {
            //   that.getReservationDetails(that.account, that.eventSelected.ev);
            // }

            // change the border color just for fun
            if (this.previousEvent) {
              this.previousEvent.el.style.borderColor = '';
            }
            info.el.style.borderColor = 'red';
            this.previousEvent = info;

            this.isDetailsService = true;
            this.lastEvSelected = that.eventSelected;
          } else {
            this.isDetailsService = false;
            this.lastEvSelected = undefined;
            info.el.style.borderColor = '';

            this.searchRequest();
          }
        },
        defaultView: 'timeGridDay',
        selectable: true,
        navLinks: true,
      });

      this.calendar.render();
      this.calendar.setOption('locale', 'it');

      $(document).on('click', '.fc-today-button', () => {
        that.calendar.removeAllEvents();

        const notbefore = moment(
          new Date(that.calendar.view.activeStart)
        ).format('DD/MM/YYYY');
        const notafter = moment(new Date(that.calendar.view.activeEnd))
          .subtract(1, 'day')
          .format('DD/MM/YYYY');

        that.filters.period.from = notbefore;
        that.filters.period.to = notafter;
        that.searchRequest();
      });

      $(document).on('click', '.fc-prev-button', () => {
        that.calendar.removeAllEvents();

        const notbefore = moment(
          new Date(that.calendar.view.activeStart)
        ).format('DD/MM/YYYY');
        const notafter = moment(new Date(that.calendar.view.activeEnd))
          .subtract(1, 'day')
          .format('DD/MM/YYYY');
        that.filters.period.from = notbefore;
        that.filters.period.to = notafter;
        console.log(
          'date',
          this.filters.period.from,
          that.calendar.view.activeStart,
          this.filters.period.to,
          that.calendar.view.activeEnd
        );
        that.searchRequest();
      });

      $(document).on('click', '.fc-next-button', () => {
        that.calendar.removeAllEvents();

        const notbefore = moment(
          new Date(that.calendar.view.activeStart)
        ).format('DD/MM/YYYY');
        const notafter = moment(new Date(that.calendar.view.activeEnd))
          .subtract(1, 'day')
          .format('DD/MM/YYYY');
        that.filters.period.from = notbefore;
        that.filters.period.to = notafter;
        that.searchRequest();
      });

      $(document).on('click', '.fc-dayGridMonth-button', () => {
        if (this.lastEvSelected) {
          this.getDataForCalendar();
        }
      });

      $(document).on('click', '.fc-timeGridWeek-button', () => {
        if (this.lastEvSelected) {
          this.getDataForCalendar();
        }
      });

      $(document).on('click', '.fc-timeGridDay-button', () => {
        if (this.lastEvSelected) {
          this.getDataForCalendar();
        }
      });

      // mini
      const today: any = new Date();
      let currentMonth: any = today.getMonth();
      let currentYear: any = today.getFullYear();
      const selectYear: any = document.getElementById('year');
      const selectMonth: any = document.getElementById('month');

      const months = [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ];

      const monthAndYear = document.getElementById('monthAndYear');
      // showCalendar(currentMonth, currentYear);

      // let mininextButton: any = document.getElementById('next');
      // mininextButton.addEventListener('click', function () {
      //   next();
      // });

      function next() {
        currentYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        currentMonth = (currentMonth + 1) % 12;
        // showCalendar(currentMonth, currentYear);
      }

      function previous() {
        currentYear = currentMonth === 0 ? currentYear - 1 : currentYear;
        currentMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        // showCalendar(currentMonth, currentYear);
      }

      // function showCalendar(month, year) {
      //   let firstDay = new Date(year, month).getDay();
      //   let daysInMonth = 32 - new Date(year, month, 32).getDate();

      //   let tbl = document.getElementById('calendar-body'); // body of the calendar

      //   // clearing all previous cells
      //   tbl.innerHTML = '';

      //   // filing data about month and in the page via DOM.
      //   monthAndYear.innerHTML = months[month] + ' ' + year;
      //   selectYear.value = year;
      //   selectMonth.value = month;

      //   // creating all cells
      //   let date: any = 1;
      //   for (let i = 0; i < 6; i++) {
      //     // creates a table row
      //     let row = document.createElement('tr');

      //     //creating individual cells, filing them up with data.
      //     for (let j = 0; j < 7; j++) {
      //       if (i === 0 && j < firstDay) {
      //         let cell = document.createElement('td');
      //         let cellText = document.createTextNode('');
      //         cell.appendChild(cellText);
      //         row.appendChild(cell);
      //       } else if (date > daysInMonth) {
      //         break;
      //       } else {
      //         let cell = document.createElement('td');
      //         let cellText = document.createTextNode(date);
      //         if (
      //           date === today.getDate() &&
      //           year === today.getFullYear() &&
      //           month === today.getMonth()
      //         ) {
      //           cell.classList.add('bg-info');
      //         } // color today's date
      //         cell.appendChild(cellText);
      //         row.appendChild(cell);
      //         date++;
      //       }
      //     }

      //     tbl.appendChild(row); // appending each row into calendar body.
      //   }
      // }

      // mini

      // var notbefore = new Date(this.calendar.view.activeStart)
      //   .toISOString()
      //   .substring(0, 10)
      //   .split('-')
      //   .reverse()
      //   .join('/');
      // var notafter = new Date(this.calendar.view.activeEnd)
      //   .toISOString()
      //   .substring(0, 10)
      //   .split('-')
      //   .reverse()
      //   .join('/');

      // this.getCalendar(this.account, notbefore, notafter);

      setInterval(() => {
        if (this.router.url === '/calendar') {
          this.searchRequest();
        }
      }, 300000);
    });
  }

  makeReservation() {
    this.newReservation.account = localStorage.getItem('HechRomeoAccount');
    this.newReservation.servicedate = new Date(this.newReservation.servicedate)
      .toISOString()
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/');
    this.newReservation.reservationdate = new Date()
      .toISOString()
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/');
    var d = new Date();
    var h = this.addZero(d.getHours());
    var m = this.addZero(d.getMinutes());
    this.newReservation.reservationtime = h + ':' + m;

    if (this.selectedLabel) {
      // this.newReservation.labelinfos.labelid = this.selectedLabel.id;
    } else {
      this.newReservation.labelinfos = undefined;
    }

    console.log(
      'risultati',
      $('#timeslots option:selected').map(function (a, item) {
        return item.value;
      })
    );

    let selectedIds = $('#timeslots option:selected').map(function (a, item) {
      return item.value;
    });
    let selectedSlots = [];
    for (let element of selectedIds) {
      let tempSlot = this.timeslots.find((obj) => obj.id == element);
      selectedSlots.push({
        timeslot: tempSlot.timeslottext,
        quantity: this.newReservation.quantity,
      });
    }

    let reservation = {
      account: this.newReservation.account,
      serviceid: this.newReservation.serviceid,
      servicedate: this.newReservation.servicedate,
      name: this.newReservation.name,
      lastname: this.newReservation.lastname,
      email: this.newReservation.email,
      transactionId: '',
      labelinfo: this.newReservation.labelinfos
        ? this.newReservation.labelinfos
        : null,
      slots: selectedSlots,
    };

    this.calendarService
      .advancedServicesMenagerReservation(reservation)
      .subscribe((data) => {
        this.resetReservation();
        this.searchRequest();
      });

    // this.calendarService
    //   .makeReservation(this.newReservation)
    //   .subscribe((data) => {
    //     this.resetReservation();
    //     this.searchRequest();
    //   });
  }

  insertCart() {
    let err = false;
    for (let element of this.newCart.items) {
      if (element.quantity < 1) {
        err = true;
        document
          .getElementById('err-' + element.serviceid)
          .classList.remove('d-none');
      } else {
        document
          .getElementById('err-' + element.serviceid)
          .classList.add('d-none');
      }
    }
    if (!err) {
      this.newCart.account = localStorage.getItem('HechRomeoAccount');
      // this.newCart.labelinfos.labelid = this.selectedLabel;
      this.calendarService
        .insertShoppingCart(this.newCart)
        .subscribe((data) => {
          this.resetCart();
          this.searchRequest();
        });
      document.getElementById('confirm-cart').click();
    }
  }

  getAllLabels() {
    this.labelService.getLabels().subscribe((data) => {
      this.allLabels = data.labels;
    });
  }

  getAllDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      this.allDepartments = data.departments;
    });
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  changeSelectedService() {
    this.newReservation.serviceid = this.selectedService.id;
    this.newReservation.decreasequantity =
      this.selectedService.decreasequantity;
    this.selectedLabel = undefined;
    if (this.selectedService.idlabels != null) {
      this.availableLabels = this.allLabels.filter((obj) => {
        let check = false;
        for (let element of this.selectedService.idlabels) {
          if (obj.id == element) {
            check = true;
          }
        }
        return check;
      });
    } else {
      this.availableLabels = [];
    }
  }

  addItemToCart() {
    let err = false;
    if (this.selectedService.idlabels) {
      if (this.cartLabelsList.length == 0) {
        for (let element of this.selectedService.idlabels) {
          this.cartLabelsList.push(element);
        }
      } else {
        const filteredArray = this.selectedService.idlabels.filter((value) =>
          this.cartLabelsList.includes(value)
        );
        if (filteredArray.length == 0) {
          err = true;
        } else {
          this.cartLabelsList = [];
          for (let element of filteredArray) {
            this.cartLabelsList.push(element);
          }
        }
      }
    } else {
      err = true;
    }

    if (err) {
      document.getElementById('err-label').classList.remove('d-none');
    } else {
      document.getElementById('err-label').classList.add('d-none');
    }
    if (!err) {
      let newItem: CartItems = new CartItems();
      newItem.serviceid = this.selectedService.id;
      newItem.title = this.selectedService.title.it;
      this.newCart.items.push(newItem);
      this.cartServicesNotActivated = this.cartServicesNotActivated.filter(
        (obj) => obj.id != this.selectedService.id
      );
      this.selectedService = undefined;
    }

    // this.newReservation.serviceid = this.selectedService.id;
    // this.newReservation.decreasequantity = this.selectedService.decreasequantity;
    // this.selectedLabel = undefined;
    // if (this.selectedService.idlabels != null) {
    //   this.availableLabels = this.allLabels.filter(obj => {
    //     let check = false;
    //     for (let element of this.selectedService.idlabels) {
    //       if (obj.id == element) {
    //         check = true;
    //       }
    //     }
    //     return check;
    // })
    // } else {
    //   this.availableLabels = [];
    // }
  }

  getLabelName(label) {
    return this.allLabels.find((obj) => obj.id == label).name.it;
  }

  deleteItemFromCart(item) {
    this.newCart.items = this.newCart.items.filter(
      (obj) => obj.serviceid != item.serviceid
    );
    this.cartServicesNotActivated.push(
      this.cartServices.find((obj) => obj.id == item.serviceid)
    );
  }

  toggleCalendar() {
    document.getElementById('calendar').style.display = this.hideCalendar
      ? 'none'
      : 'block';
    document
      .getElementById('minicalendar')
      .classList.remove(!this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
    document
      .getElementById('minicalendar')
      .classList.add(this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
  }

  getDataForCalendar() {
    this.calendar.removeAllEvents();
    // var notbefore = new Date(this.calendar.view.activeStart)
    //   .toISOString()
    //   .substring(0, 10)
    //   .split('-')
    //   .reverse()
    //   .join('/');
    // var notafter = new Date(this.calendar.view.activeEnd)
    //   .toISOString()
    //   .substring(0, 10)
    //   .split('-')
    //   .reverse()
    //   .join('/');
    let notbefore = moment(new Date(this.calendar.view.activeStart)).format(
      'DD/MM/YYYY'
    );
    let notafter = moment(new Date(this.calendar.view.activeEnd))
      .subtract(1, 'day')
      .format('DD/MM/YYYY');

    // this.getCalendar(this.account, notbefore, notafter);

    this.filters.period.from = notbefore;
    this.filters.period.to = notafter;
    this.newFilters.period.from = notbefore;
    this.newFilters.period.to = notafter;

    this.searchRequest();
  }

  detailsAdvancedService(account, event) {
    console.log('eventClicked', event, this.reservations);
    let reservationIds = [];
    let details = [];
    for (let element of event.reservationdetails) {
      reservationIds.push(element.reservationid);
    }
    for (let element of reservationIds) {
      details.push(
        this.reservations.find((obj) => obj.reservationid == element)
      );
    }
    console.log(details);
    this.services = details;
    this.services.activeSlot = event.slottext;
  }

  getReservationDetails(account, event) {
    let reservationids = [];
    for (let element of event.reservations) {
      reservationids.push(element.reservationid);
    }
    this.services = [];
    let searchObj = {
      account,
      serviceid: event.serviceid,
      reservationids: reservationids,
      servicedate: event.servicedate,
      servicetime: event.servicetime,
    };
    this.calendarService.getReservationDetails(searchObj).subscribe(
      (calendar) => {
        this.services = calendar;

        this.totalSearchRequest = 0;
        this.totalServices = 0;
        this.totalNumber = 0;

        for (let ev of this.services.reservations) {
          this.totalNumber += 1;

          if (ev.quantity) {
            this.totalServices += ev.quantity;
          } else {
            this.totalServices += 1;
          }

          if (ev.amount) {
            this.totalSearchRequest = this.totalSearchRequest + ev.amount;
          }
        }
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  getShoppingCartDetails(account, event) {
    let reservationids = [];

    this.services = {};
    let searchObj = {
      account,
      shoppingcartid: event.id,
    };
    this.calendarService.getShoppingCartDetails(searchObj).subscribe(
      (calendar) => {
        this.services = calendar;
        this.services.cart = true;
        this.totalSearchRequest = 0;
        this.totalServices = 0;
        this.totalNumber = 0;

        if (!this.filters.service) {
          if (this.services.amount) {
            this.totalSearchRequest =
              this.totalSearchRequest + this.services.amount;
          }

          this.totalNumber += this.services.services.length;

          for (let item of this.services.services) {
            if (item.quantity) {
              this.totalServices += item.quantity;
            } else {
              this.totalServices += 1;
            }
          }
        } else {
          for (let item of this.services.services) {
            if (item.serviceid === this.filters.service) {
              this.totalNumber += 1;

              if (item.quantity) {
                this.totalServices += item.quantity;
              } else {
                this.totalServices += 1;
              }

              if (item.amount) {
                this.totalSearchRequest += item.amount;
              }
            }
          }
        }
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  selectCategory(currentObj) {
    this.clearOtherObjects(currentObj);
    if (currentObj != 3) {
      this.newCategoriesSelect['categoryObj' + (currentObj + 1)] =
        this.newCategoriesSelect['categoryObj' + currentObj].categories.find(
          (obj) =>
            obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
        );
      this.newCategoriesSelect.currentServiceList =
        this.newCategoriesSelect['categoryObj' + (currentObj + 1)].services;
    } else {
      this.newCategoriesSelect.currentServiceList = this.newCategoriesSelect[
        'categoryObj' + currentObj
      ].categories.find(
        (obj) =>
          obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
      ).services;
    }
  }

  clearOtherObjects(currentObj) {
    for (let x = currentObj + 1; x < 4; x++) {
      this.newCategoriesSelect['selectedCategory' + x] = undefined;
      this.newCategoriesSelect['categoryObj' + x] = { categories: [] };
    }
    this.newCategoriesSelect.selectedServiceInList = undefined;
    this.newCategoriesSelect.currentServiceList = [];
  }

  getAllCategories() {
    this.categoriesService.getVendorTree(this.accountService.vendor).subscribe((data) => {
      this.allCategories = data.categories;
      this.categoriesSelect.categoryObj0 = { ...data };
      this.newCategoriesSelect = { ...this.categoriesSelect };
      let tree = '';
      this.exporeCategories(this.allCategories, tree);
      this.currentAdvancedService = this.everyService.find(
        (obj) => obj.id == this.serviceSelected
      );
      console.log('currentAdvancedService', this.currentAdvancedService);
    });
  }

  exporeCategories(array, tree) {
    for (let element of array) {
      if (tree.length > 0) {
        tree = tree.concat(' > ');
      }
      tree = tree.concat(element.title.it);
      this.exporeCategories(element.categories, tree);
      for (let item of element.services) {
        item.tree = tree;
        this.everyService.push(item);
        if (
          item.deleted == false &&
          item.cartenabled == false &&
          item.visible == true
        )
          this.allServices.push(item);
        if (item.deleted == false && item.cartenabled == true) {
          this.cartServices.push(item);
          this.cartServicesNotActivated.push(item);
        }
      }
      let i = tree.lastIndexOf('>');
      tree = tree.substring(0, i - 1);
    }
  }

  resetReservation() {
    this.newReservation = new Reservation();
    this.selectedService = undefined;
    this.selectedLabel = undefined;
  }
  resetCart() {
    this.newCart = new Cart();
    this.cartServicesNotActivated = [];
    for (let element of this.cartServices) {
      this.cartServicesNotActivated.push(element);
    }
    this.selectedService = undefined;
    this.selectedLabel = undefined;
    this.cartLabelsList = [];
  }

  generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  selectCurrentService() {
    $('#timeslots').multiselect();
    $('#timeslots').multiselect('dataprovider', []);
    this.selectedService = JSON.parse(
      JSON.stringify(this.currentAdvancedService)
    );
    this.changeSelectedService();
  }

  newReservationChangedDate() {
    console.log(this.newReservation.servicedate);
    this.categoriesService
      .getAdvancedServiceData(
        this.serviceSelected,
        moment(this.newReservation.servicedate, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
      )
      .subscribe((data) => {
        this.timeslots = data.servicedata.timeslots;
        let options = [];
        for (let element of this.timeslots) {
          element.id = this.generateId();
          options.push({
            label: element.timeslottext,
            title: element.timeslottext,
            value: element.id,
          });
        }
        $('#timeslots').multiselect('dataprovider', options);
      });
  }

  filterReservations(reservations: Array<any>) {
    let reservationsFilterd = reservations;
    console.log('filtering', reservationsFilterd, this.filters);
    for (let day of reservationsFilterd) {
      for (let slot of day.slots) {
        if (this.filters.orderstatus != -1) {
          slot.reservationdetails = slot.reservationdetails.filter(
            (obj) => obj.reservationstatus == this.filters.orderstatus
          );
        }
        if (this.filters.customername) {
          slot.reservationdetails = slot.reservationdetails.filter(
            (obj) => obj.name == this.filters.customername
          );
        }
        if (this.filters.customerlastname) {
          slot.reservationdetails = slot.reservationdetails.filter(
            (obj) => obj.lastname == this.filters.customerlastname
          );
        }
        if (this.filters.customeremail) {
          slot.reservationdetails = slot.reservationdetails.filter(
            (obj) => obj.email == this.filters.customeremail
          );
        }
        //  if (this.filters.label) {
        //    slot.reservationdetails = slot.reservationdetails.filter(
        //      (obj) => obj.email == this.filters.label
        //    );
        //  }
      }
    }

    return reservationsFilterd;
  }

  searchRequest() {
    this.isDetailsService = false;
    this.lastEvSelected = undefined;
    this.calendar.removeAllEvents();
    this.filters = { ...this.newFilters };
    this.categoriesSelect = { ...this.newCategoriesSelect };
    this.filterDate = { ...this.newFilterDate };
    if (
      this.filterDate.from &&
      this.filterDate.from.length > 0 &&
      this.filterDate.to &&
      this.filterDate.to.length > 0
    ) {
      let notbefore = moment(new Date(this.filterDate.from)).format(
        'DD/MM/YYYY'
      );
      let notafter = moment(new Date(this.filterDate.to)).format('DD/MM/YYYY');
      this.filters.period.from = notbefore;
      this.filters.period.to = notafter;
      console.log('date', this.filters.period.from, this.filters.period.to);
    }

    for (let x = 0; x < 4; x++) {
      if (this.categoriesSelect['selectedCategory' + x]) {
        if (x == 0) {
          this.filters.categoryroot =
            this.categoriesSelect.categoryObj0.categories.find(
              (obj) => obj.id == this.categoriesSelect.selectedCategory0
            ).id;
        } else {
          this.filters['categoryL' + x] = this.categoriesSelect[
            'categoryObj' + x
          ].categories.find(
            (obj) => obj.id == this.categoriesSelect['selectedCategory' + x]
          ).id;
        }
      } else {
        this.filters['categoryL' + x] = undefined;
      }
    }
    if (this.categoriesSelect.selectedServiceInList) {
      this.filters.service = this.categoriesSelect.selectedServiceInList;
    }

    // this.filters.period.from = "";
    // this.filters.period.to = "";
    console.log('Filters', this.filters);
    this.calendarService
      .getAdvancedServicePeriod(
        this.filters.account,
        this.serviceSelected,
        this.filters.period.from,
        this.filters.period.to
      )
      .subscribe((data) => {
        this.totalSearchRequest = 0;
        this.totalServices = 0;
        this.totalNumber = 0;
        let events = [];
        let reservationdetails = [];
        let reservations = [];
        let reservationsFiltered = this.filterReservations(data.reservations);
        for (let element of reservationsFiltered) {
          for (let item of element.slots) {
            item.day = moment(element.day).format('YYYY-MM-DD');
            if (element.endday)
              item.endday = moment(element.endday).format('YYYY-MM-DD');
            if (element.startday)
              item.startday = moment(element.startday).format('YYYY-MM-DD');
            events.push(item);
          }
        }
        console.log('EVENTS', events);
        for (let element of events) {
          let quantity = 0;
          for (let item of element.reservationdetails) {
            quantity += item.quantity;
            item.day = element.day;
            item.slottext = element.slottext;
            if (element.endday)
              item.endday = moment(element.endday).format('DD/MM/YYYY');
            if (element.startday)
              item.startday = moment(element.startday).format('DD/MM/YYYY');
            reservationdetails.push(item);
          }
          element.id =
            Date.now().toString(36) + Math.random().toString(36).substr(2);
          let className: string;
          let status = Math.min.apply(
            null,
            element.reservationdetails.map(
              (reservation) => reservation.reservationstatus
            )
          );

          switch (status) {
            case 0:
              className = 'submitted-event';
              break;
            case 1:
              className = 'accepted-event';
              break;
            case 2:
              className = 'refused-event';
              break;
            case 3:
              className = 'worked-event';
              break;
            case 4:
              className = 'aborted-event';
              break;
          }
          if (element.reservationdetails.length > 0)
            if (element.endday) {
              let days = moment(element.endday, 'YYYY-MM-DD').diff(
                moment(element.startday, 'YYYY-MM-DD'),
                'days'
              );
              let x = 0;
              do {
                let currentDay = moment(element.startday, 'YYYY-MM-DD');
                if (x > 0) {
                  currentDay.add(x, 'days');
                }
                this.calendar.addEvent({
                  title:
                    'Prenotazioni: ' +
                    element.reservationdetails.length +
                    ', Quantità: ' +
                    quantity,
                  start:
                    currentDay.format('YYYY-MM-DD') +
                    'T' +
                    element.slottext.split('-')[0],
                  end: this.monthView
                    ? element.endday + 'T' + element.slottext.split('-')[1]
                    : currentDay.format('YYYY-MM-DD') +
                      'T' +
                      element.slottext.split('-')[1],
                  extendedProps: element,
                  className,
                });
                x++;
                if (this.monthView) {
                  x = days + 1;
                }
              } while (x <= days);
            } else {
              this.calendar.addEvent({
                title:
                  'Prenotazioni: ' +
                  element.reservationdetails.length +
                  ', Quantità: ' +
                  quantity,
                start: element.day + 'T' + element.slottext.split('-')[0],
                end: element.day + 'T' + element.slottext.split('-')[1],
                extendedProps: element,
                className,
              });
            }
        }
        for (let element of reservationdetails) {
          if (
            !reservations.find(
              (obj) => obj.reservationid == element.reservationid
            )
          ) {
            let reservation = JSON.parse(JSON.stringify(element));
            // delete reservation.day;
            reservation.day = moment(reservation.day, 'YYYY-MM-DD').format(
              'DD/MM/YYYY'
            );
            delete reservation.slottext;
            reservation.reservationtimeFormatted = moment(
              reservation.reservationtime
            ).format('HH:mm');
            reservation.reservationdateFormatted = moment(
              reservation.reservationtime
            ).format('DD/MM/YYYY');
            reservation.slots = reservationdetails.filter(
              (obj) => obj.reservationid == element.reservationid
            );
            reservations.push(reservation);
          } else {
            reservations.find(
              (obj) => obj.reservationid == element.reservationid
            ).price += element.price;
          }
        }
        console.log('RESERVATIONS', reservations, reservationdetails);
        this.listSearchRequestShopping = JSON.parse(
          JSON.stringify(reservations)
        );
        console.log(
          'listSearchRequestShopping',
          this.listSearchRequestShopping
        );

        this.reservations = reservations;

        this.totalNumber = this.reservations.length;
        for (let element of this.reservations) {
          this.totalSearchRequest += element.price;
          this.totalServices += element.quantity;
        }
        this.isDetailsService = false;
      });
  }

  getCalendar(account, notbefore, notafter) {
    this.calendarService.getCalendar(account, notbefore, notafter).subscribe(
      (calendar) => {
        this.events = calendar;
        this.events = this.events.days;

        const that = this;
        Object.keys(this.events).forEach((key) => {
          const dataevento = key.split('/').reverse().join('-');

          that.events[key].forEach((ev) => {
            const oravento = ev.servicetime;

            let className: string;
            switch (ev.workstatus) {
              case 0:
                className = 'submitted-event';
                break;
              case 1:
                className = 'accepted-event';
                break;
              case 2:
                className = 'refused-event';
                break;
              case 3:
                className = 'worked-event';
                break;
              case 4:
                className = 'aborted-event';
                break;
            }
            if (oravento && oravento != '') {
              that.calendar.addEvent({
                title: ev.title,
                start: dataevento + 'T' + oravento,
                // color: 'rgb(174, 202, 65)',
                className,
              });
            } else {
              that.calendar.addEvent({
                title: ev.title,
                allDay: true,
                start: dataevento,
                className,
              });
            }
          });
        });
      },
      (err) => {
        console.log('calendar err', err);
      }
    );
  }

  getDaily(account, notbefore, notafter) {
    this.services = [];
    this.calendarService.getDaily(account, notbefore, notafter).subscribe(
      (calendar) => {
        if (this.filter != undefined && this.filter != 'undefined') {
          for (let element of calendar.serviceReservation) {
            element.reservations = element.reservations.filter(
              (obj) => obj.orederstatus == this.filter
            );
          }

          calendar.shoppingCartList = calendar.shoppingCartList.filter(
            (obj) => obj.orederstatus == this.filter
          );
        }
        for (let element of calendar.shoppingCartList) {
          element.active = moment(
            element.shoppingcartdate,
            'DD-MM-YYYY'
          ).isSame(moment(), 'day');
        }
        this.services = calendar;
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  changeReservationStatus(reservation, service, cart?) {
    if (parseInt(reservation.reservationstatus) == 4) {
      let ordertype = 0;
      console.log(service, reservation);
      this.cancelReservation(
        reservation.reservationid,
        reservation.day,
        '',
        reservation.email,
        this.currentAdvancedService.id,
        ordertype
      );
    } else {
      let slots = [];
      for (let slot of reservation.slots) {
        slots.push({
          slotstext: slot.slottext,
          reservationid: reservation.reservationid,
          reservationstatus: parseInt(reservation.reservationstatus),
        });
      }

      let changeStatus = {
        account: localStorage.getItem('HechRomeoAccount'),
        day: reservation.day,
        reservationlist: slots,
        serviceid: this.currentAdvancedService.id,
      };

      this.calendarService
        .changeReservationStatusAdvancedService(changeStatus)
        .subscribe(() => {
          this.getDataForCalendar();
        });
    }
  }

  updateDaily() {
    if (this.eventSelected) {
      // this.getDaily(this.account, this.eventSelected, '');
      if (this.eventSelected.cart) {
        this.getShoppingCartDetails(this.account, this.eventSelected.ev);
      } else {
        this.getReservationDetails(this.account, this.eventSelected.ev);
      }
    }
    // this.getDataForCalendar();
  }

  clearFilter() {
    this.newFilters = new Filters();
    this.newFilters.account = this.account;
    this.newCategoriesSelect = {
      selectedCategory0: undefined,
      selectedCategory1: undefined,
      selectedCategory2: undefined,
      selectedCategory3: undefined,
      categoryObj0: { categories: [] },
      categoryObj1: { categories: [] },
      categoryObj2: { categories: [] },
      categoryObj3: { categories: [] },
      currentServiceList: [],
      selectedServiceInList: undefined,
    };
    this.newFilterDate = { from: '', to: '' };
    this.newCategoriesSelect.categoryObj0 = this.categoriesSelect.categoryObj0;
    this.newCategoriesSelect.selectedServiceInList =
      this.categoriesSelect.selectedServiceInList;
  }

  clearNewFilter() {
    this.newFilters = { ...this.filters };
    this.newCategoriesSelect = { ...this.categoriesSelect };
    this.newFilterDate = { ...this.filterDate };
  }

  print(): void {
    window.print();
  }

  cancelReservation(
    reservationid,
    servicedate,
    servicetiontime,
    accountemail,
    serviceid,
    ordertype
  ) {
    this.calendarService
      .cancelReservation(
        this.account,
        reservationid,
        servicedate,
        servicetiontime,
        accountemail,
        serviceid,
        ordertype,
        true,
        ''
      )
      .subscribe(
        () => {
          // L'elenco si aggiorna una volta eliminata la prenotazione
          // this.getDaily(this.account, this.eventSelected, '');
          // if (this.eventSelected.cart) {
          //   this.getShoppingCartDetails(this.account, this.eventSelected.ev);
          // } else {
          //   this.getReservationDetails(this.account, this.eventSelected.ev);
          // }
          this.getDataForCalendar();
        },
        (err) => {
          console.log('cancel reservation error', err);
        }
      );
  }
  setReservationToCancel(
    reservationid,
    servicedate,
    servicetime,
    email,
    serviceid
  ) {
    this.reservationToCancel.reservationid = reservationid;
    this.reservationToCancel.servicedate = servicedate;
    this.reservationToCancel.servicetime = servicetime;
    this.reservationToCancel.email = email;
    this.reservationToCancel.serviceid = serviceid;
  }

  getStatusOrder(orderstatus: number): string {
    if (orderstatus >= 0) {
      switch (orderstatus) {
        case 0:
          return 'In Attesa';
        case 1:
          return 'Accettata';
        case 2:
          return 'Rifiutata';
        case 3:
          return 'Processata';
        case 4:
          return 'Cancellata';
        default:
          return '';
      }
    } else {
      return '';
    }
  }
}
