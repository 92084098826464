import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  AccountConfiguration,
  UpdetableAccount, VendorTvManagement,
} from 'src/app/models/account/account';
import { AdminVendor } from '../../models/admin-vendor';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}
  setAccountConfiguration(accountConfiguration: any) {
    accountConfiguration.successMessage =
      "Impostazioni dell'account modificate con successo";
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/set/options`,
      accountConfiguration
    );
  }

  getAccountConfiguration() {
    return this.http.get<AccountConfiguration>(
      `${environment.apiUrl}platform/account/get/options/${localStorage.getItem(
        'HechRomeoAccount'
      )}`
    );
  }

  getVendorData() {
    return this.http.get<UpdetableAccount>(
      `${environment.apiUrl}platform/account/get/${localStorage.getItem(
        'HechRomeoAccount'
      )}`
    );
  }

  getOriginalVendorData() {
    return this.http.get<UpdetableAccount>(
      `${environment.apiUrl}platform/account/get/${localStorage.getItem(
        'OriginalAccount'
      )}`
    );
  }

  updateVendorData(vendorData: any) {
    vendorData.successMessage = 'Anagrafica fornitore modificata con successo';
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/update`,
      vendorData
    );
  }

  changeVendorMail(vendorMail: any) {
    vendorMail.successMessage = 'Email fornitore modificata con successo';
    return this.http.post<any>(
      `${environment.apiUrl}account/change/mail`,
      vendorMail
    );
  }

  changeVendorUrl(vendorUrl: any) {
    vendorUrl.successMessage = 'URL Hech Romeo modificato con successo';

    return this.http.post<any>(
      `${environment.apiUrl}account/set/url`,
      vendorUrl
    );
  }

  getVendorTvManagement() {
    return this.http.get<VendorTvManagement>(
      `${environment.apiUrl}platform/account/vendor-tv-management/${localStorage.getItem(
        'HechRomeoAccount'
      )}`
    );
  }

  updateVendorTvManagement(vendorData: any) {
    vendorData.successMessage = 'Licenze tv aggiornata con successo';
    return this.http.put<any>(
      `${environment.apiUrl}platform/account/vendor-tv-management`,
      vendorData
    );
  }

  getAccountUser() {
    let httpParams = new HttpParams();
    httpParams = httpParams.set(
      'account',
      localStorage.getItem('HechRomeoAccount')
    );

    return this.http.get<any>(`${environment.apiUrl}account/get/users`, {
      params: httpParams,
    });
  }

  createAccountUser(adminVendor: AdminVendor) {
    return this.http.post<any>(
      `${environment.apiUrl}account/create/user`,
      adminVendor
    );
  }

  deleteAccountUser(deleteUser: any) {
    deleteUser.successMessage = 'Cancellazione utente con successo';

    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', deleteUser.email);

    return this.http.delete<any>(`${environment.apiUrl}account/delete/user`, {
      params: httpParams,
    });
  }

  getVedorAccount(account: string) {
    return this.http.get<any>(
      `${environment.apiUrl}category/vendor/${account}`
    );
  }

  generateVendorToken(account: string) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/vendor-token`,
      {
        account: account,
        successMessage: 'Token generato correttamente',
      }
    );
  }

  setVendorTokenStatus(account, active) {
    return this.http.put<any>(
      `${environment.apiUrl}platform/account/vendor-token`,
      {
        account: account,
        active: active,
        successMessage: 'Stato del token modificato correttamente',
      }
    );
  }

  getVendorToken(account) {
    return this.http.get<any>(
      `${environment.apiUrl}platform/account/vendor-token/${account}`
    );
  }

  setVendorActive(account, active) {
    return this.http.put<any>(
      `${environment.apiUrl}platform/account/vendor/active`,
      {
        account: account,
        vendoractive: active,
        successMessage: 'Stato del fornitore modificato correttamente',
      }
    );
  }

  setVendorCanReserve(account, reserve) {
    return this.http.put<any>(
      `${environment.apiUrl}platform/account/vendor/can-reserve`,
      {
        account: account,
        vendorcanreserve: reserve,
        successMessage:
          'Opzione possibilità di prenotazione modificata con successo',
      }
    );
  }

  getAllUsers() {
    return this.http.get<any>(`${environment.apiUrl}account/get/all/users`);
  }

  getLinkedUsers(account: string) {
    return this.http.get<any>(`${environment.apiUrl}account/get/users?account=${account}`);
  }

  getLinkedVendors(account: string) {
    return this.http.get<any>(`${environment.apiUrl}account/get/user/vendors?email=${account}`);
  }

  getLinkedUsersForManagement(email: string) {
    return this.http.get<any>(`${environment.apiUrl}platform/account/get/user?email=${email}`);
  }

  deleteLinkedAccount(user) {
    return this.http.delete<any>(`${environment.apiUrl}account/delete/user?id=${user.id}`);
  }

  editUser(user) {
    return this.http.put<any>(`${environment.apiUrl}account/update/user`, user);
  }

  getPermissions(account: string, email: string) {
    return this.http.get<any>(`${environment.apiUrl}account/get/user?account=${account}&email=${email}`);
  }

  get vendor():string{
    return this.userVendor || this.account;
  }

  get userVendor():string{
    return localStorage.getItem('HechRomeoVendor');
  }

  get account():string{
    return localStorage.getItem('HechRomeoAccount');
  }

  get isUser():boolean{
    return localStorage.getItem('HechRomeoRole') === 'User' || localStorage.getItem('HechRomeoRole') === 'Manager'
  }

 get isAdmin():boolean {
   return localStorage.getItem('HechRomeoRole') == 'Manager';
 }

 get isVendor():boolean {
  return localStorage.getItem('HechRomeoRole') == 'Vendor';
 }

//  get isUser():boolean {
//   return localStorage.getItem('HechRomeoRole') == 'User';
//  }
}
