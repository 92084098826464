import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ɵConsole,
} from '@angular/core';
import {
  AdvancedService,
  AdvancedServiceGet,
  availablityAdvancedServices,
  Period,
  PeriodGet,
  Service,
  ServiceLabel,
  TimeSlots,
} from 'src/app/models/categoryTree/category-tree';
import { Department } from 'src/app/models/department/department';
import { Label } from 'src/app/models/label/label';
import $ from 'jquery';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-menage-services',
  templateUrl: './menage-services.component.html',
  styleUrls: ['./menage-services.component.scss'],
})
export class MenageServicesComponent implements OnInit {
  @Output('endMenageServices') endMenageServices: EventEmitter<any> =
    new EventEmitter();
  @Output('saveMenageServices') saveMenageServices: EventEmitter<any> =
    new EventEmitter();
  @Output('filterAllLanguages') filterAllLanguages: EventEmitter<any> =
    new EventEmitter();
  @Output('deleteSelectedLanguage') deleteSelectedLanguage: EventEmitter<any> =
    new EventEmitter();

  @Input() service: Service;
  @Input() advancedService: AdvancedServiceGet;
  @Input() activatedLanguages;
  @Input() currentLanguage;
  @Input() fileToLoad;
  @Input() loadedNewFile: boolean;
  @Input() deletedImage: boolean;
  @Input() videoToLoad;
  @Input() imageToLoad;
  @Input() id: string;
  @Input() url: string;
  @Input() savedUrl: string;
  @Input() labels: Label[];
  @Input() departments: Department[];
  @Input() callAtServer: any[];

  serviceErrorMessage = {
    amountError: undefined,
    quantityError: undefined,
    dateError: undefined,
    servicetypeError: undefined,
    timeError: undefined,
  };

  urlError: boolean = false;
  playerLoaded: boolean = false;
  player: YT.Player;
  sendNotification: boolean = true;
  selectedDepartment;
  selectedLabel: ServiceLabel = new ServiceLabel();
  selectedLabelType: boolean = true;
  filteredLabels: Label[] = [];
  filteredDepartemnts: Department[] = [];
  periodEdited: PeriodGet = new PeriodGet();
  periodDate = {
    from: undefined,
    to: undefined,
  };
  timeSlotEdited: TimeSlots = new TimeSlots();
  timeSlotTime = {
    from: undefined,
    to: undefined,
  };
  firstCheck: boolean = false;
  currentLanguageTimeslot: string;

  checkedPayment: any;
  checkedPaymentPreno: any;

  availablityAdvancedServices: availablityAdvancedServices =
    new availablityAdvancedServices();

  weekdays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];
  monthdays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (this.service.departments?.length > 0) {
      this.sendNotification = this.service.departments[0].sendnotification;
    }
    if (this.service.idLabels?.length > 0) {
      this.selectedLabelType = false;
    }

    if (!this.service.mlNote) {
      this.service.mlNote = { it: '' };
    }

    this.filterDepartments();
    this.filterLabels();
    // if (this.service.isadvanced)
    //   this.generatePeriodsIds();
  }

  // advancedServiceChangePriceOptions() {
  //   if (this.service.paymenttype == 0) {
  //     for (let element of this.advancedService.periods) {
  //       element.perioddata.enablePricing = false;
  //     }
  //   } else {
  //     for (let element of this.advancedService.periods) {
  //     }
  //   }
  // }

  generateID() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  editPeriod(period?, pointer?, index?) {
    this.resetPeriodErrors();
    this.firstCheck = false;
    if (period) {
      if (!pointer) {
        this.periodEdited = JSON.parse(JSON.stringify(period));
      } else {
        this.periodEdited = period;
      }

      if (index >= 0) {
        this.periodEdited.index = index;
      }
      this.periodDate.from = moment(
        this.periodEdited.from,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
      this.periodDate.to = moment(this.periodEdited.to, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
    } else {
      this.periodEdited = new PeriodGet();
      this.periodDate.from = undefined;
      this.periodDate.to = undefined;
    }
    this.timeSlotEdited = undefined;
  }

  deletePeriod(element: PeriodGet, index) {
    if (element.periodid) {
      element.edited = true;
      element.delete = true;
    } else {
      this.advancedService.periods.splice(index, 1);
    }
  }

  editTimeSlot(timeSlot?, index?) {
    this.firstCheck = false;
    this.currentLanguageTimeslot = this.currentLanguage;
    if (timeSlot) {
      this.timeSlotEdited = JSON.parse(JSON.stringify(timeSlot));
      let timeArray = this.timeSlotEdited.timeslottext.split('-');
      this.timeSlotTime.from = timeArray[0];
      this.timeSlotTime.to = timeArray[1];
      this.timeSlotEdited.index = index;
      if (!this.timeSlotEdited.localizednote) {
        this.timeSlotEdited.localizednote = {};
        for (let element in this.service.title) {
          this.timeSlotEdited.localizednote[element] = '';
        }
      }
      for (let lng in this.timeSlotEdited.localizednote) {
        this.timeSlotEdited.localizednote[lng] =
          this.timeSlotEdited.localizednote[lng].replace('&#39;', "'");
      }
      if (!this.timeSlotEdited.days || this.timeSlotEdited.days.length == 0) {
        this.timeSlotEdited.days = [];
      }
    } else {
      this.timeSlotEdited = new TimeSlots();
      this.timeSlotTime.from = undefined;
      this.timeSlotTime.to = undefined;
      this.timeSlotEdited.localizednote = {};
      for (let element in this.service.title) {
        this.timeSlotEdited.localizednote[element] = '';
      }
    }
  }

  savePeriod() {
    if (!this.advancedService) {
      this.advancedService = new AdvancedServiceGet();
    }
    this.firstCheck = true;
    if (!this.checkNewPeriod()) {
      if (this.periodEdited.periodid || this.periodEdited.index >= 0) {
        let index = this.periodEdited.index;
        delete this.periodEdited.index;
        this.periodEdited.edited = true;
        this.periodEdited.from = moment(
          this.periodDate.from,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        this.periodEdited.to = moment(this.periodDate.to, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        );
        if (!index) {
          let object = this.advancedService.periods.findIndex(
            (obj) => obj.periodid == this.periodEdited.periodid
          );
          this.advancedService.periods[object] = this.periodEdited;
        } else {
          this.advancedService.periods[index] = this.periodEdited;
        }
      } else {
        this.periodEdited.edited = true;
        this.periodEdited.from = moment(
          this.periodDate.from,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        this.periodEdited.to = moment(this.periodDate.to, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        );
        this.advancedService.periods.push(this.periodEdited);
      }
      document.getElementById('newPeriodDismiss').click();
    }
  }

  selectDayRepetition(number, timeslot?) {
    if (timeslot) {
      if (this.timeSlotEdited.days.includes(number)) {
        const index = this.timeSlotEdited.days.indexOf(number);
        if (index > -1) {
          this.timeSlotEdited.days.splice(index, 1);
        }
      } else {
        this.timeSlotEdited.days.push(number);
      }
    } else {
      if (this.periodEdited.perioddata.days.includes(number)) {
        const index = this.periodEdited.perioddata.days.indexOf(number);
        if (index > -1) {
          this.periodEdited.perioddata.days.splice(index, 1);
        }
      } else {
        this.periodEdited.perioddata.days.push(number);
      }
    }
  }

  resetPeriodErrors() {
    document.getElementById('newPeriodFrom').classList.add('d-none');
    document.getElementById('newPeriodTo').classList.add('d-none');
    document.getElementById('checkIncludedDate').classList.add('d-none');
    document.getElementById('newPeriodOverlapDate').classList.add('d-none');
    if (document.getElementById('newPeriodMaxServiceQuantity')) {
      document
      .getElementById('newPeriodMaxServiceQuantity')
      .classList.add('d-none');
    }
  }

  checkNewPeriod() {
    if (this.firstCheck) {
      let error = false;

      if (this.periodDate.from) {
        document.getElementById('newPeriodFrom').classList.add('d-none');
      } else {
        document.getElementById('newPeriodFrom').classList.remove('d-none');
        error = true;
      }
      if (this.periodDate.to) {
        document.getElementById('newPeriodTo').classList.add('d-none');
      } else {
        document.getElementById('newPeriodTo').classList.remove('d-none');
        error = true;
      }

      if (this.periodDate.from && this.periodDate.to) {
        let dateerr = false;

        for (let p of this.advancedService.periods) {
          if (p.periodid == this.periodEdited.periodid) {
            continue;
          }
          if (
            moment(this.periodDate.from, 'YYYY-MM-DD').isBetween(
              moment(p.from, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              moment(p.to, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              undefined,
              '[]'
            ) ||
            moment(this.periodDate.to, 'YYYY-MM-DD').isBetween(
              moment(p.from, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              moment(p.to, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              undefined,
              '[]'
            )
          ) {
            //alert('no');
            document
              .getElementById('checkIncludedDate')
              .classList.remove('d-none');
            error = true;
            dateerr = true;
          }

          if (
            moment(p.from, 'DD/MM/YYYY').isBetween(
              this.periodDate.from,
              this.periodDate.to
            ) ||
            moment(p.to, 'DD/MM/YYYY').isBetween(
              moment(this.periodDate.from, 'DD/MM/YYYY'),
              moment(this.periodDate.to, 'DD/MM/YYYY'),
              undefined,
              '[]'
            )
          ) {
            //alert(`Date comprese già all'interno di un periodo`);
            document
              .getElementById('checkIncludedDate')
              .classList.remove('d-none');
            error = true;
            dateerr = true;
          }
        }
        if (!dateerr) {
          document.getElementById('checkIncludedDate').classList.add('d-none');
        }
      }

      if (this.periodDate.from && this.periodDate.to) {
        if (
          moment(this.periodDate.from, 'YYYY-MM-DD').isBefore(
            moment(this.periodDate.to, 'YYYY-MM-DD')
          )
        ) {
          document
            .getElementById('newPeriodOverlapDate')
            .classList.add('d-none');
        } else {
          document
            .getElementById('newPeriodOverlapDate')
            .classList.remove('d-none');
          error = true;
        }
      }

      if (this.periodEdited.perioddata.enableGlobalQty) {
        if (this.periodEdited.perioddata.maxservicequantity) {
          document
            .getElementById('newPeriodMaxServiceQuantity')
            .classList.add('d-none');
        } else {
          document
            .getElementById('newPeriodMaxServiceQuantity')
            .classList.remove('d-none');
          error = true;
        }
      }
      return error;
    }
  }

  saveTimeSlot() {
    this.firstCheck = true;
    if (!this.checkNewTimeSlot()) {
      for (let lng in this.timeSlotEdited.localizednote) {
        this.timeSlotEdited.localizednote[lng] =
          this.timeSlotEdited.localizednote[lng].replace("'", '&#39;');
      }
      this.timeSlotEdited.duration =
        this.calcMinutes(this.timeSlotTime.to) -
        this.calcMinutes(this.timeSlotTime.from);
      if (this.timeSlotEdited.index == undefined) {
        this.timeSlotEdited.timeslottext = `${this.timeSlotTime.from}-${this.timeSlotTime.to}`;
        this.periodEdited.perioddata.timeslots.push(this.timeSlotEdited);
        this.timeSlotEdited = undefined;
        this.timeSlotTime.from = undefined;
        this.timeSlotTime.to = undefined;
      } else {
        let index = this.timeSlotEdited.index;
        delete this.timeSlotEdited.index;
        this.timeSlotEdited.timeslottext = `${this.timeSlotTime.from}-${this.timeSlotTime.to}`;
        this.periodEdited.perioddata.timeslots[index] = this.timeSlotEdited;
        this.timeSlotEdited = undefined;
        this.timeSlotTime.from = undefined;
        this.timeSlotTime.to = undefined;
      }
      this.periodEdited.edited = true;
    }
  }

  deleteTimeSlot(index) {
    this.periodEdited.perioddata.timeslots.splice(index, 1);
    this.periodEdited.edited = true;
  }

  checkNewTimeSlot() {
    if (this.firstCheck) {
      let error = false;
      if (this.timeSlotTime.from) {
        document.getElementById('newTimeSlotFrom').classList.add('d-none');
      } else {
        document.getElementById('newTimeSlotFrom').classList.remove('d-none');
        error = true;
      }
      if (this.timeSlotTime.to) {
        document.getElementById('newTimeSlotTo').classList.add('d-none');
      } else {
        document.getElementById('newTimeSlotTo').classList.remove('d-none');
        error = true;
      }

      if (this.timeSlotTime.from && this.timeSlotTime.to) {
        let now = moment().format('YYYY-MM-DD');
        if (
          moment(
            `${now} ${this.timeSlotTime.from}`,
            'YYYY-MM-DD hh:mm'
          ).isBefore(
            moment(`${now} ${this.timeSlotTime.to}`, 'YYYY-MM-DD hh:mm')
          )
        ) {
          document
            .getElementById('newTimeSlotOverlapTime')
            .classList.add('d-none');
        } else {
          document
            .getElementById('newTimeSlotOverlapTime')
            .classList.remove('d-none');
          error = true;
        }
      }

      if (this.periodEdited.perioddata.enableSlotQty) {
        if (this.timeSlotEdited.maxquantity) {
          document
            .getElementById('newTimeSlotMaxQuantity')
            .classList.add('d-none');
        } else {
          document
            .getElementById('newTimeSlotMaxQuantity')
            .classList.remove('d-none');
          error = true;
        }
      }

      if (this.periodEdited.perioddata.enablePricing) {
        if (this.timeSlotEdited.price) {
          document.getElementById('newTimeSlotPrice').classList.add('d-none');
        } else {
          document
            .getElementById('newTimeSlotPrice')
            .classList.remove('d-none');
          error = true;
        }
      }
      return error;
    }
  }

  calcMinutes(minutes: string): number {
    let timeArray = minutes.split(':');
    let totalMinutes = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    return totalMinutes;
  }

  selectQuantityOption(type, event) {
    if (event.target.checked) {
      switch (type) {
        case 0:
          this.periodEdited.perioddata.enableGlobalQty = true;
          this.periodEdited.perioddata.enableSlotQty = false;
          break;
        case 1:
          this.periodEdited.perioddata.enableGlobalQty = false;
          this.periodEdited.perioddata.enableSlotQty = true;
          break;
        case 2:
          this.periodEdited.perioddata.enableGlobalQty = false;
          this.periodEdited.perioddata.enableSlotQty = false;
          break;
      }
    }
  }

  filterDepartments() {
    this.filteredDepartemnts = [];
    for (let element of this.departments) {
      this.filteredDepartemnts.push({ ...element });
    }

    if (this.service.departments) {
      for (let element of this.service.departments) {
        this.filteredDepartemnts = this.filteredDepartemnts.filter(
          (obj) => obj.id != element.iddepartments
        );
      }
    }
  }

  filterLabels() {
    this.filteredLabels = [];
    for (let element of this.labels) {
      this.filteredLabels.push({ ...element });
    }

    if (this.service.labels) {
      for (let element of this.service.labels) {
        this.filteredLabels = this.filteredLabels.filter(
          (obj) => obj.id != element.labelid && obj.id != element.id
        );
      }
    }
  }

  addDepartment() {
    if (this.selectedDepartment) {
      this.service.departments.push({
        iddepartments: this.selectedDepartment,
        sendnotification: this.sendNotification,
      });

      this.selectedDepartment = undefined;
      this.filterDepartments();
    }
  }

  addLabel() {
    if (this.selectedLabel.labelid) {
      if (!this.service.labels) this.service.labels = [];
      this.service.labels.push(this.selectedLabel);

      this.selectedLabel = new ServiceLabel();
      this.filterLabels();
    }
  }

  removeDepartment(id) {
    this.service.departments = this.service.departments.filter(
      (obj) => obj.iddepartments != id
    );

    for (let element of this.departments) {
      if (element.id == id)
        this.filteredDepartemnts.push({ ...element });
    }
    this.filteredDepartemnts = this.filteredDepartemnts.sort( (obj, obj2) => obj.id < obj2.id ? -1 : 1);
  }

  removeLabel(id) {
    this.service.labels = this.service.labels.filter(
      (obj) => obj.labelid != id && obj.id != id
    );

    for (let element of this.labels) {
      if (element.id == id)
        this.filteredLabels.push({ ...element });
    }
    this.filteredLabels = this.filteredLabels.sort( (obj, obj2) => obj.id < obj2.id ? -1 : 1);
  }

  displayDepartment(element) {
    try {
      return this.departments.find((obj) => obj.id == element.iddepartments)
        .name[this.activatedLanguages[0].abr];
    } catch (e) {
      console.error(e);
    }
  }

  displayLabel(element) {
    try {
      return this.labels.find((obj) => obj.id == element).name[
        this.activatedLanguages[0].abr
      ];
    } catch (e) {
      console.error(e);
    }
  }

  deleteYoutubeVideo() {
    this.id = '';
    this.url = '';
    this.savedUrl = '';
    this.urlError = false;
    if (this.playerLoaded) {
      this.player.destroy();
      this.playerLoaded = false;
    }
    this.imageToLoad = undefined;
    this.service.imagetype = undefined;
    this.service.image = undefined;
  }
  loadPlayer() {
    const idPlayer: string = 'playerService';

    if (this.id != '' && this.id != undefined) {
      this.player = new YT.Player(idPlayer, {
        width: '480px',
        videoId: this.id,
      });
      this.playerLoaded = true;
    }
  }
  loadNewYoutubeVideo() {
    let newId = this.getIdFromUrl();
    this.id = newId;
    if (this.playerLoaded == false) {
      this.loadPlayer();
    } else {
      this.player.loadVideoById(newId);
    }
  }
  getIdFromUrl() {
    try {
      let _url = new URL(this.url);

      const splitUrl = _url.pathname.split('/');

      let id = this.url?.includes('embed') ? splitUrl[splitUrl.length - 1] : _url.searchParams.get('v');

      this.savedUrl = this.url;
      this.urlError = false;
      return id;
    } catch (e) {
      this.urlError = true;
    }
  }

  deleteLanguage() {
    this.deleteSelectedLanguage.emit(this.currentLanguage);
    this.currentLanguage = this.activatedLanguages[0].abr;
  }
  emitFilterLanguages() {
    this.filterAllLanguages.emit();
  }
  saveMenageService() {
    if (this.checkQuantityAndAmountAndEndDate()) {
      if (this.service.departments) {
        for (let element of this.service.departments) {
          element.sendnotification = this.sendNotification;
        }
      }

      if (this.service.labels && this.service.labels.length > 0) {
        for (let label of this.service.labels) {
          if (!label.labelid || label.labelid.length <= 0) {
            label.labelid = label.id;
          }
        }
      }

      if (this.service.idlabels && this.service.idlabels.length == 0) {
        this.service.idlabels = null;
      }

      if (this.service.isadvanced) {
        this.service.amount = 0.0;
        this.service.maxquantity = 0;
        this.service.maxQuantity = 0;
        this.service.enableqty = false;
        this.service.enableQty = false;
        this.service.decreasequantity = false;
        this.service.decreaseQuantity = false;
        this.service.availabledate = '';
        this.service.availableDate = '';
        this.service.availabletime = '';
        this.service.availableTime = '';
        this.service.expirationdate = '';
        this.service.expirationDate = '';
        this.service.expirationtime = '';
        this.service.expirationTime = '';
        // this.service.paymenttype = 0;
        // this.service.paymentType = 0;
        this.service.servicetype = 1;
        this.service.serviceType = 1;
        if (this.service.cartenabled || this.service.cartEnabled) {
          this.service.periodBooking = true;
        }
      }
      this.saveMenageServices.emit({
        savedUrl: this.savedUrl,
        videoToLoad: this.videoToLoad,
        imageToLoad: this.imageToLoad,
        loadedNewFile: this.loadedNewFile,
        advancedService: this.advancedService ? this.advancedService : new AdvancedServiceGet(),
        deletedImage: this.deletedImage,
      });
    }
  }
  endMenageService() {
    this.endMenageServices.emit();
  }

  encodeImageFileAsURL(element, type: number, i?: number) {
    this.fileToLoad = undefined;

    const file = element[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.getPureB64(reader.result.toString(), type, i);
    };
    reader.readAsDataURL(file);
  }
  getPureB64(element: string, type: number, i?: number) {
    const index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    switch (type) {
      case 1:
        let item = this.imageToLoad[i];
        if (this.imageToLoad.length < 8 && item.image == undefined) {
          this.imageToLoad.push({});
        }
        item.image = element;
        item.new = true;
        this.loadedNewFile = true;

        break;
      case 2:
        if (this.playerLoaded) {
          this.player.destroy();
          this.playerLoaded = false;
        }
        this.videoToLoad = element;
        this.loadedNewFile = true;
        break;
    }
  }
  private checkQuantityAndAmountAndEndDate() {
    this.serviceErrorMessage.amountError = undefined;
    this.serviceErrorMessage.quantityError = undefined;
    this.serviceErrorMessage.dateError = undefined;
    let hasNoErrors = true;

    if (!this.service.isAdvanced) {
      try {
        this.service.amount = Number.parseFloat(this.service.amount.toString());
        if (this.service.amount < 0) {
          this.serviceErrorMessage.amountError = {
            label: "Inserire l'ammontare corretto",
          };
          hasNoErrors = false;
        }
      } catch (error) {
        this.serviceErrorMessage.amountError = {
          label: 'Il valore inserito non è valido',
        };
        hasNoErrors = false;
      }
      try {
        this.service.maxQuantity = Number.parseInt(
          this.service.maxQuantity.toString(),
          10
        );
        if (this.service.enableQty && this.service.maxQuantity <= 0) {
          this.serviceErrorMessage.quantityError = {
            label: 'Il valore inserito non è valido',
          };
          hasNoErrors = false;
        }
      } catch (error) {
        this.serviceErrorMessage.quantityError = {
          label: 'Il valore inserito non è valido',
        };
        return false;
      }
      if (this.service.expirationDate < this.service.availableDate) {
        this.serviceErrorMessage.dateError = {
          label: 'Inserire delle date valide',
        };
        hasNoErrors = false;
      }
      if (this.service.cartEnabled) {
        if (this.service.expirationDate || this.service.availableDate) {
          this.serviceErrorMessage.dateError = {
            label:
              'Non puoi inserire una data se hai impostato il servizio come carrello',
          };
          hasNoErrors = false;
        }
        if (this.service.serviceType != 1) {
          this.serviceErrorMessage.servicetypeError = {
            label:
              'Il servizio può essere solo su prenotazione se hai impostato il servizio come carrello',
          };
          hasNoErrors = false;
        }
        if (this.service.expirationTime || this.service.availableTime) {
          this.serviceErrorMessage.timeError = {
            label:
              'Non puoi inserire un orario se hai impostato il servizio come carrello',
          };
          hasNoErrors = false;
        }
      }
      if (hasNoErrors && !this.service.enableQty) {
        this.service.maxQuantity = 0;
      }
    }

    return hasNoErrors;
  }

  onInitEditor(): void {
    $('button[title="Align left"]').trigger('click');
  }

  changeEnableQty(enableQty: boolean) {
    this.service.maxQuantity = enableQty
      ? this.service.maxQuantity
        ? this.service.maxQuantity
        : 1
      : this.service.maxQuantity;
  }

  deleteImage(i) {
    this.deletedImage = true;
    this.imageToLoad.splice(i, 1);
    if (this.imageToLoad.length == 7) {
      this.imageToLoad.push({});
    }
    if (
      this.imageToLoad.lenght == 1 &&
      this.imageToLoad[0].image == undefined
    ) {
      this.service.image = undefined;
      this.service.imageType = undefined;
    }
  }

  openModalWithavailablityAdvancedServices(period: any) {
    this.periodEdited = period;

    this.availablityAdvancedServices = undefined;
  }

  changeAvailability() {
    this.firstCheck = false;
    this.availablityAdvancedServices = new availablityAdvancedServices();
  }

  checkAvailabilityPeriod() {
    if (this.firstCheck) {
      let error = false;
      if (!this.availablityAdvancedServices.date) {
        document
          .getElementById('checkChangeAvailabilityDate')
          .classList.remove('d-none');
        document
          .getElementById('checkChangeAvailabilityDateIsBetween')
          .classList.add('d-none');
        error = true;
      } else {
        document
          .getElementById('checkChangeAvailabilityDate')
          .classList.add('d-none');
        let checkIsBeteween = moment(
          this.availablityAdvancedServices.date
        ).isBetween(
          moment(this.periodEdited.from, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          moment(this.periodEdited.to, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          undefined,
          '[]'
        );

        if (checkIsBeteween == false) {
          document
            .getElementById('checkChangeAvailabilityDateIsBetween')
            .classList.remove('d-none');
          error = true;
        } else {
          document
            .getElementById('checkChangeAvailabilityDateIsBetween')
            .classList.add('d-none');
        }
      }

      if (!this.availablityAdvancedServices.allday) {
        if (!this.availablityAdvancedServices.timeslottext) {
          document
            .getElementById('checkChangeAvailabilityTimeSlot')
            .classList.remove('d-none');
          error = true;
        } else {
          document
            .getElementById('checkChangeAvailabilityTimeSlot')
            .classList.add('d-none');
        }
      }
      return error;
    }
  }

  saveChangeAvailability() {
    this.firstCheck = true;
    if (!this.checkAvailabilityPeriod()) {
      if (!this.periodEdited.availablity) {
        this.periodEdited.availablity = [];
      }

      let foundClone = this.periodEdited.availablity.find(
        (period) =>
          period.date === this.availablityAdvancedServices.date &&
          period.allday === this.availablityAdvancedServices.allday &&
          period.timeslottext === this.availablityAdvancedServices.timeslottext
      );

      if (foundClone) {
        //alert('è già bloccata questa fascia oraria')
        foundClone.setunavailable =
          this.availablityAdvancedServices.setunavailable;
      } else {
        this.availablityAdvancedServices.date = moment(
          this.availablityAdvancedServices.date
        ).format('DD/MM/YYYY');

        this.callAtServer.push(this.availablityAdvancedServices);
        this.periodEdited.availablity.push(this.availablityAdvancedServices);
        let filteredPeriod = this.periodEdited.availablity.filter(
          (period) =>
            period.date === this.availablityAdvancedServices.date &&
            period.allday === true
        );
        if (filteredPeriod.length > 0) {
          if (this.availablityAdvancedServices.allday) {
            this.periodEdited.availablity =
              this.periodEdited.availablity.filter(
                (period) =>
                  period.date !== this.availablityAdvancedServices.date ||
                  period.allday === true
              );
          } else {
            this.periodEdited.availablity =
              this.periodEdited.availablity.filter(
                (period) =>
                  period.date !== this.availablityAdvancedServices.date ||
                  period.allday !== true
              );
          }
        }
      }
      document.getElementById('changeAvailabilityDismiss').click();
    }
  }

  deletePeriodAvaibility(
    periodAvaibility: availablityAdvancedServices,
    i: any
  ) {
    if (periodAvaibility) {
      periodAvaibility.setunavailable = false;
      this.callAtServer.push(periodAvaibility);
      this.periodEdited.availablity.splice(i, 1);
    }
  }

  changedServicetype(event: any) {
    if (event === 0) {
      this.service.accepted = false;
    }
  } 
}