import { Component, OnInit } from '@angular/core';
import { Account } from 'src/app/models/account/account';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TYPOGRAPHY } from '../../../constants/typography.enum';
import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { generateId } from 'src/app/models/notification/notification';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  account: Account = new Account();
  registerForm: FormGroup;
  TYPOGRAPHY = TYPOGRAPHY;
  admin: boolean = localStorage.getItem('HechRomeoRole') ? true : false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.account.adminCreated = this.admin;
    this.registerForm = new FormGroup({
      account: new FormControl(this.account.account, [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z]+[0-9]*[^\s])+([_-]+[a-zA-Z0-9]+[^\s])*$/
        ),
      ]),
      referrername: new FormControl(this.account.referrername, [
        Validators.required,
      ]),
      referrerlastname: new FormControl(this.account.referrerlastname, [
        Validators.required,
      ]),
      email: new FormControl(this.account.email, [
        Validators.required,
        Validators.pattern(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        ),
      ]),
      confirmEmail: new FormControl(this.account.email, [
        Validators.required,
        Validators.pattern(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        ),
      ]),
      password: new FormControl(this.account.password, [
        Validators.required,
        Validators.pattern(
          /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
        ),
      ]),
      confirmPassword: new FormControl(this.account.password, [
        Validators.required,
        Validators.pattern(
          /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
        ),
      ]),
      factoryname: new FormControl(this.account.factoryname, [
        Validators.required,
      ]),
      piva: new FormControl(this.account.piva, [Validators.required]),
      address: new FormControl(this.account.address, [Validators.required]),
      city: new FormControl(this.account.city, [Validators.required]),
      state: new FormControl(this.account.state, [Validators.required]),
      zipcode: new FormControl(this.account.zipcode, [Validators.required]),
      country: new FormControl(this.account.country, [Validators.required]),
      banner: new FormControl(this.account.banner, [Validators.required]),
      billingcode: new FormControl(this.account.billingcode, [
        Validators.required,
      ]),
      urlhechromeo: new FormControl(this.account.urlhechromeo),
      phone: new FormControl(this.account.phone),
    });
    if (!this.admin) {
      this.registerForm.addControl(
        'accepttermpolicy',
        new FormControl(this.account.accepttermpolicy, [Validators.required])
      );
      this.registerForm.addControl(
        'acceptdatapolicy',
        new FormControl(this.account.acceptdatapolicy, [Validators.required])
      );
    }
  }

  register() {
    for (const controlName in this.registerForm.controls) {
      if (controlName) {
        const control = this.registerForm.controls[controlName];
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
    }
    if (
      this.registerForm.valid &&
      this.registerForm.controls['email'].value ===
        this.registerForm.controls['confirmEmail'].value &&
      this.registerForm.controls['password'].value ===
        this.registerForm.controls['confirmPassword'].value
    ) {
      for (const controlName in this.registerForm.controls) {
        if (
          controlName &&
          controlName !== 'confirmEmail' &&
          controlName !== 'confirmPassword'
        ) {
          const control = this.registerForm.controls[controlName];
          if (control) {
            this.account[controlName] = control.value;
          }
        }
      }
      sessionStorage.setItem('IgnoreErrorHech', '532');
      this.account['role'] = 'Vendor';
      this.authService.register(this.account).subscribe(
        (data) => {
          if (this.admin) {
            this.returnToLastLocation();
          } else {
            this.router.navigate(['login']);
          }
        },
        (error) => {
          if (error.status == 532) {
            const notification = {
              id: generateId(),
              image: undefined,
              title: "Errore: l'account o la mail sono già stati registrati",
              body: `Url: ${error.url} `,
            };
            this.notificationService.addNotification(notification);
          }
        }
      );
    } else {
      alert(TYPOGRAPHY.INPUT_ERROR_COMPLETE_ALL_FIELDS);
    }
  }

  returnToLastLocation() {
    this.location.back();
  }
}
