<div class="row mx-0 h-100" (click)="disableContextMenu()">
  <div class="col-12 my-2">
    <div
      *ngIf="isDetails"
      (click)="isDetails = false; getProjects()"
      style="cursor: pointer"
    >
      < {{ "Torna ai progetti" | translate }}
    </div>

    <div class="row mx-0 w-100">
      <div class="form-group col-1 p-0">
        <div>
          <label for="search">{{ "Cerca" | translate }}:</label>
          <input id="search" [(ngModel)]="searchValue" />
        </div>
      </div>

      <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button
          *ngIf="!isDetails"
          class="btn btn-primary mb-4 w-100"
          routerLink="/editSignage"
        >
          + {{ "Aggiungi Progetto" | translate }}
        </button>

        <button
          *ngIf="isDetails"
          class="btn btn-primary mb-4 w-100"
          data-toggle="modal"
          data-target="#editPageModal"
          (click)="newPage()"
        >
          + {{ "Aggiungi Pagina" | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="!isDetails" class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <table
            class="table"
            (contextmenu)="onrightClick($event)"
            oncontextmenu="return false;"
          >
            <thead>
              <tr>
                <th scope="col">{{ "Nome" | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                oncontextmenu="return false;"
                *ngFor="let project of projectsList | filter : searchValue"
                (contextmenu)="
                  onrightClick($event, project, true); $event.stopPropagation()
                "
                style="cursor: pointer"
              >
                <td
                  (click)="getProjectDetails(project.id)"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  "
                >
                  {{ project.name }}
                </td>
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    width: 10%;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <div class="modifyImg" (click)="editProject(project.id)">
                      <img
                        src="assets/img/Pittogramma modifica.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                    <div
                      data-toggle="modal"
                      data-target="#delConfirmModal"
                      class="deleteImg"
                      (click)="projectToDelete = project"
                    >
                      <img
                        src="assets/img/Pittogramma elimina - cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="isDetails" class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <!-- <table class="table">
            <thead>
              <tr>
                <th style="width: 105px"></th>
                <th scope="col">Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="drop($event, 0)">
              <tr
                *ngFor="let page of projectDetails.pages | filter: searchValue"
                style="cursor: pointer"
                cdkDrag
                cdkDragLockAxis="y"
              >
                <td class="p-0">
                  <div class="d-flex">
                    <div class="moveAndHide">
                      <img
                        *ngIf="page.visible"
                        (click)="toggleVisibility(page)"
                        src="assets/img/Pittogramma Visibile.png"
                        alt=""
                        style="width: 30px"
                      />
                      <img
                        *ngIf="!page.visible"
                        (click)="toggleVisibility(page)"
                        src="assets/img/Pittogramma Nascondi.png"
                        alt=""
                        style="width: 30px"
                      />
                    </div>
                    <div class="moveAndHide" cdkDragHandle>
                      <img
                        src="assets/img/Pittogramma Sposta.png"
                        alt=""
                        style="width: 30px"
                      />
                    </div>
                  </div>
                </td>
                <td
                  (click)="getPage(page.id)"
                  data-toggle="modal"
                  data-target="#editPageModal"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  "
                >
                  {{ page.title }}
                </td>
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    width: 10%;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <div
                      (click)="getPage(page.id)"
                      data-toggle="modal"
                      data-target="#editPageModal"
                      class="modifyImg"
                    >
                      <img
                        src="assets/img/Pittogramma modifica.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                    <div
                      class="deleteImg"
                      data-toggle="modal"
                      data-target="#delConfirmModal"
                      (click)="pageToDelete = page"
                    >
                      <img
                        src="assets/img/Pittogramma elimina - cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->

          <div
            cdkDropList
            style="padding: 10px 20px; overflow-y: auto"
            (cdkDropListDropped)="drop($event, 0)"
            oncontextmenu="return false;"
            (contextmenu)="onrightClick($event)"
          >
            <div
              *ngFor="let page of projectDetails.pages | filter : searchValue"
              cdkDrag
              cdkDragLockAxis="y"
              class="mb-2 listElement"
            >
              <div
                class="card"
                style="
                  width: 20%;
                  margin-right: 1%;
                  display: flex;
                  flex-direction: row;
                "
              >
                <div class="moveAndHide">
                  <img
                    *ngIf="page.visible"
                    (click)="toggleVisibility(page)"
                    src="assets/img/Pittogramma Visibile.png"
                    alt=""
                    style="width: 30px"
                  />
                  <img
                    *ngIf="!page.visible"
                    (click)="toggleVisibility(page)"
                    src="assets/img/Pittogramma Nascondi.png"
                    alt=""
                    style="width: 30px"
                  />
                </div>
                <div class="moveAndHide" cdkDragHandle>
                  <img
                    src="assets/img/Pittogramma Sposta.png"
                    alt=""
                    style="width: 30px"
                  />
                </div>
              </div>
              <div
                oncontextmenu="return false;"
                (contextmenu)="
                  onrightClick($event, page); $event.stopPropagation()
                "
                class="card"
                style="height: 50px; cursor: pointer; width: 100%"
              >
                <div
                  style="
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div class="type">
                    <span>{{ "pagina" | translate }}</span>
                  </div>

                  <div class="d-flex flex-center">
                    <span
                      class="category"
                      (click)="getPage(page.id)"
                      data-toggle="modal"
                      data-target="#editPageModal"
                      >{{ page.title }}</span
                    >
                  </div>
                  <div class="icons">
                    <div
                      class="modifyImg"
                      (click)="getPage(page.id)"
                      data-toggle="modal"
                      data-target="#editPageModal"
                    >
                      <img
                        src="assets/img/Pittogramma modifica.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                    <div
                      class="deleteImg"
                      data-toggle="modal"
                      data-target="#delConfirmModal"
                      (click)="pageToDelete = page"
                    >
                      <img
                        src="assets/img/Pittogramma elimina - cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="isDetails ? deletePage() : deleteProject()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editPageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editPageModal"
  aria-hidden="true"
>
  <div class="modal-dialog page-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Modifica pagina" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        *ngIf="!connectingToCategoryOrService && !rendering"
        class="modal-body"
      >
        <div class="row mx-0 w-100">
          <div class="d-flex justify-content-end w-100">
            <button
              class="btn btn-secondary"
              (click)="rendering = true; getMediaRender()"
            >
              {{ "Visualizza anteprima" | translate }}
            </button>
          </div>
          <div class="col-12 p-0 form-group">
            <div class="row mx-0 w-100">
              <div class="col-6 p-0 form-group">
                <div>
                  <label for="title">{{ "Nome pagina" | translate }}</label>
                  <input
                    [(ngModel)]="page.title"
                    type="text"
                    class="form-control"
                    id="title"
                    name="title"
                    placeholder="{{
                      'Inserici il nome della pagina' | translate
                    }}"
                  />
                </div>
                <div class="mt-4">
                  <label for="texttitle">{{ "Titolo" | translate }}</label>
                  <input
                    [(ngModel)]="page.texttitle"
                    type="text"
                    class="form-control"
                    id="texttitle"
                    name="texttitle"
                    placeholder="{{
                      'Inserici il titolo del testo' | translate
                    }}"
                  />
                </div>
                <div class="mt-4">
                  <label for="text">{{ "Testo" | translate }}</label>
                  <editor
                    name="text"
                    apiKey="1sdv0g430kwfa3fbpe8x98vr7eyu3x9il416pnmm3mjo3s52"
                    [init]="{
                      font_formats: 'Barlow=Barlow; Roboto=Roboto; Barlow Condensed=Barlow-condensed; Gilda Display=Gilda-display; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MSX=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                      plugins: ['link image hr ']
                    }"
                    [(ngModel)]="page.text"
                  ></editor>
                </div>
                <div class="mt-4">
                  <label for="time">{{
                    "Limitazioni data e orario" | translate
                  }}</label>
                  <div class="d-flex">
                    <div class="mr-4" style="width: 200px">
                      <label for="startdate">{{
                        "Inserici data di inizio" | translate
                      }}</label>
                      <input
                        [(ngModel)]="dateFrom"
                        (change)="checkDatesAndTimes()"
                        type="date"
                        class="form-control"
                        id="startdate"
                        name="startdate"
                      />
                    </div>
                    <div style="width: 200px">
                      <label for="enddate">{{
                        "Inserici data di fine" | translate
                      }}</label>
                      <input
                        [(ngModel)]="dateTo"
                        (change)="checkDatesAndTimes()"
                        type="date"
                        class="form-control"
                        id="enddate"
                        name="enddate"
                      />
                    </div>
                  </div>
                  <small *ngIf="errorDate" class="error col-12">{{
                    "La data di fine non deve precedere quella di inizio"
                      | translate
                  }}</small>
                  <div class="d-flex mt-3">
                    <div class="mr-4" style="width: 200px">
                      <label for="starttime">{{
                        "Inserici orario di inizio" | translate
                      }}</label>
                      <input
                        [(ngModel)]="page.starttime"
                        (change)="checkDatesAndTimes()"
                        type="time"
                        class="form-control"
                        id="starttime"
                        name="starttime"
                      />
                    </div>
                    <div style="width: 200px">
                      <label for="endtime">{{
                        "Inserici orario di fine" | translate
                      }}</label>
                      <input
                        [(ngModel)]="page.endtime"
                        (change)="checkDatesAndTimes()"
                        type="time"
                        class="form-control"
                        id="endtime"
                        name="endtime"
                      />
                    </div>
                  </div>
                  <small *ngIf="errorTime" class="error col-12">{{
                    "L'orario di fine non deve precedere quello di inizio"
                      | translate
                  }}</small>
                </div>
              </div>

              <div class="col-6 p-0 pl-5 pt-4 form-group">
                <div class="form-check pt-3">
                  <input
                    [(ngModel)]="page.header"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="header"
                  />
                  <label class="form-check-label" for="header">
                    {{ "Header" | translate }}
                  </label>
                </div>

                <div class="form-check pt-2">
                  <input
                    [(ngModel)]="page.footer"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="footer"
                  />
                  <label class="form-check-label" for="footer">
                    {{ "Footer" | translate }}
                  </label>
                </div>

                <div class="mt-4">
                  <label for="timetype">{{
                    "Tempo per pagina" | translate
                  }}</label>

                  <div>
                    <span>{{
                      "Selezionare in che modalità desideri gestire il cambio pagina"
                        | translate
                    }}</span>
                    <div class="form-check">
                      <input
                        [value]="1"
                        [(ngModel)]="page.timetype"
                        type="radio"
                        class="form-check-input"
                        id="timetype"
                        name="timetype1"
                      />
                      <label for="timetype1">{{ "Manuale" | translate }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="page.media.type != 3"
                        [value]="2"
                        [(ngModel)]="page.timetype"
                        type="radio"
                        class="form-check-input"
                        id="timetype"
                        name="timetype2"
                      />
                      <label for="timetype2">{{
                        "A fine video" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="page.media.type != 2"
                        [value]="3"
                        [(ngModel)]="page.timetype"
                        type="radio"
                        class="form-check-input"
                        id="timetype"
                        name="timetype3"
                      />
                      <label for="timetype3">
                        {{ "A fine immagini" | translate }}
                      </label>
                    </div>
                    <div *ngIf="page.timetype === 1">
                      <span>{{
                        "Inserire il tempo in secondi" | translate
                      }}</span>
                      <input
                        [(ngModel)]="page.time"
                        (change)="setTimeToZero()"
                        min="0"
                        type="number"
                        class="input-group-text"
                        id="time"
                        name="time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="w-100" />

        <div class="row mx-0 w-100">
          <div class="col-12 p-0 form-group">
            <div class="row mx-0 w-100">
              <div class="col-6 p-0 form-group">
                <div class="mt-3">
                  <label for="mediatype">{{ "Media" | translate }}</label>
                  <div>
                    <span>{{
                      "Selezionare la tipologia di media" | translate
                    }}</span>
                    <!-- <div class="form-check">
                      <input
                        [value]="1"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "Immagine" | translate
                      }}</label>
                    </div> -->
                    <div class="form-check">
                      <input
                        [value]="0"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "Nessun media" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="2"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "Immagini" | translate
                      }}</label>
                      <span> (Max 50Mb)</span>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="3"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "Video" | translate
                      }}</label>
                      <span> (Max 50Mb)</span>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="4"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "URL esterna" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="5"
                        [(ngModel)]="page.media.type"
                        (change)="disableTimeType()"
                        type="radio"
                        class="form-check-input"
                        id="mediatype"
                        name="mediatype"
                      />
                      <label for="form-check-label">{{
                        "Web Service"
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0 form-group">
                <div *ngIf="page.media.type === 2">
                  <div *ngFor="let element of imageToLoad; index as i">
                    <div class="mb-2">
                      <img
                        *ngIf="element.image"
                        [src]="'data:image/jpg;base64,' + element.image"
                        style="width: 450px"
                        alt=""
                      />
                    </div>
                    <div
                      class="mb-3"
                      style="display: flex; flex-direction: row"
                    >
                      <input
                        type="file"
                        accept=".jpg"
                        (change)="
                          encodeImageFileAsURL($event.target.files, 1, i)
                        "
                      />
                      <button
                        [disabled]="!element.image"
                        type="button"
                        (click)="deleteImage(i)"
                        class="btn btn-danger ml-2"
                      >
                        {{ "Elimina" | translate }}
                      </button>
                    </div>
                  </div>
                  <small style="font-size: 14px">{{
                    "Max 20 immagini" | translate
                  }}</small>
                  <br />
                  <br />
                  <label for="imgtime">{{
                    "Tempo per immagine" | translate
                  }}</label>
                  <br />
                  <span>
                    {{
                      "Indica la velocità in secondi dello slider" | translate
                    }}
                  </span>
                  <input
                    [(ngModel)]="page.timeforimage"
                    type="number"
                    class="form-control"
                    id="imgtime"
                    name="imgtime"
                    placeholder="{{
                      'Inserici il tempo in secondi di ogni immagine'
                        | translate
                    }}"
                  />
                  <label class="pt-3" for="media-layout">{{
                    "Layout" | translate
                  }}</label>
                  <div>
                    <div class="form-check">
                      <input
                        value="Cut"
                        [(ngModel)]="page.media.layout"
                        type="radio"
                        class="form-check-input"
                        id="media-layout"
                        name="media-layout1"
                      />
                      <label for="media-layout1">{{
                        "Taglia l'immagine" | translate
                      }}</label
                      ><br />
                      <span>
                        {{
                          "Ottimizza le dimensioni dell’immagine in base allo schermo tagliandone le bande laterali in eccesso."
                            | translate
                        }}
                      </span>
                    </div>
                    <div class="form-check">
                      <input
                        value="Stretch"
                        [(ngModel)]="page.media.layout"
                        type="radio"
                        class="form-check-input"
                        id="media-layout"
                        name="media-layout2"
                      />
                      <label for="media-layout2">{{
                        "Immagine intera" | translate
                      }}</label
                      ><br />
                      <span>
                        {{
                          "Mostra tutta l’immagine intera inserendo le bande laterali"
                            | translate
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="page.media.type === 3">
                  <div class="mb-3">
                    <video
                      [ngClass]="{ hideElement: id != '' && id != undefined }"
                      width="450px"
                      controls
                      *ngIf="videoToLoad"
                      [src]="'data:video/mp4;base64,' + videoToLoad"
                    >
                      <!-- <source
                                    type="video/webm"
                                    [src]="'data:video/webm;base64,' + fileToLoad"
                                  />
                                  <source
                                    type="video/mp4"
                                    [src]="'data:video/mp4;base64,' + fileToLoad"
                                  /> -->
                    </video>
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <input
                      type="file"
                      accept=".mp4"
                      (change)="
                        encodeImageFileAsURL($event.target.files, 2);
                        deleteYoutubeVideo()
                      "
                    />
                    <button
                      [disabled]="!videoToLoad"
                      type="button"
                      (click)="
                        videoToLoad = undefined;
                        page.media.type = 0;
                        page.media.data = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>

                  <div class="mb-3 pt-3">
                    <youtube-player
                      [ngClass]="{ hideElement: id == '' || id == undefined }"
                      id="player"
                      (ready)="loadPlayer(true)"
                    ></youtube-player>
                  </div>
                  <div class="youtubeInput">
                    <input
                      [(ngModel)]="url"
                      type="text"
                      class="form-control"
                      id="url"
                      name="url"
                      placeholder="{{
                        'Inserisci un link di youtube' | translate
                      }}"
                    />
                    <button
                      [disabled]="url == ''"
                      type="button"
                      class="ml-2 mr-2 btn btn-secondary"
                      (click)="
                        videoToLoad = undefined;
                        page.media.data = null;
                        loadNewYoutubeVideo(true)
                      "
                    >
                      {{ "Carica" | translate }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="deleteYoutubeVideo()"
                      [disabled]="playerLoaded == false"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>

                  <div class="form-check pt-3">
                    <input
                      [(ngModel)]="page.media.videorestart"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="videorestart"
                      name="videorestart"
                    />
                    <label class="form-check-label" for="videorestart">
                      {{ "Il video riparte alla fine" | translate }}
                    </label>
                  </div>
                  <div class="mt-2">
                    <div class="form-check">
                      <input
                        value="Cut"
                        [(ngModel)]="page.media.layout"
                        type="radio"
                        class="form-check-input"
                        id="media-layout"
                        name="media-layout1"
                      />
                      <label for="media-layout1">{{
                        "Taglia l'immagine" | translate
                      }}</label>
                      <br />
                      <span>
                        {{
                          "Ottimizza le dimensioni dell’immagine in base allo schermo tagliandone le bande laterali in eccesso."
                            | translate
                        }}
                      </span>
                    </div>
                    <div class="form-check">
                      <input
                        value="Stretch"
                        [(ngModel)]="page.media.layout"
                        type="radio"
                        class="form-check-input"
                        id="media-layout"
                        name="media-layout2"
                      />
                      <label for="media-layout2">{{
                        "Immagine intera" | translate
                      }}</label
                      ><br />
                      <span>
                        {{
                          "Mostra tutta l’immagine intera inserendo le bande laterali"
                            | translate
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="page.media.type === 4">
                  <div>
                    <label for="external-url">
                      {{ "Url esterna" | translate }}
                    </label>
                    <br />
                    <span>
                      {{
                        "Inserisci l'url di una pagina web che verrà intergrata nella pagina del progetto signage"
                          | translate
                      }}
                    </span>
                    <input
                      [(ngModel)]="extUrltemp"
                      type="text"
                      class="form-control"
                      id="external-url"
                      name="external-url"
                      placeholder="{{ 'Inserisci l\'url esterna' | translate }}"
                    />
                  </div>
                </div>

                <div *ngIf="page.media.type === 5">
                  <div>
                    <label for="web-service">
                      {{ "Web Service" }}
                    </label>
                    <br/>
                    <span>
                      {{ "Seleziona il web service" | translate }}
                    </span>
                    <select class="form-control" name="we-service" id="web-service" style="max-width: 75%;" [(ngModel)]="page.media.data">
                      <option *ngFor="let ws of wsProjectsList" [ngValue]="ws.id">
                        {{ ws.projectname }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr _ngcontent-ctl-c81="" class="w-100" />
        <label for="categoryServiceConnection">{{
          "Collegamento a categoria o servizio" | translate
        }}</label>
        <div class="d-flex mb-3">
          <div>
            <div class="my-3">
              {{ "Collegato a: " | translate }}
              {{
                page.category
                  ? page.category.level
                  : page.service
                  ? page.service.level
                  : ("Nessun elemento" | translate)
              }}
            </div>
          </div>
          <div class="ml-5">
            <label>{{ "Importa:" | translate }}</label>
            <div class="form-check pl-4">
              <input
                [(ngModel)]="!page.overwritetext"
                class="form-check-input"
                type="checkbox"
                value=""
                id="overwritetext"
              />
              <label class="form-check-label" for="overwritetext">
                {{ "Testo" | translate }}
              </label>
            </div>
            <div class="form-check pt-2 pl-4">
              <input
                [(ngModel)]="!page.overwritemedia"
                class="form-check-input"
                type="checkbox"
                value=""
                id="overwritemedia"
              />
              <label class="form-check-label" for="overwritemedia">
                {{ "Media" | translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <button
              type="button"
              class="btn btn-secondary"
              (click)="connectingToCategoryOrService = true; getCategories()"
            >
              {{ "Seleziona una categoria o un servizio" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger ml-2"
              (click)="
                page.category = undefined;
                page.service = undefined;
                page.categoryid = undefined;
                page.serviceid = undefined
              "
            >
              {{ "Elimina" | translate }}
            </button>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            id="closeModalPage"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            {{ "Annulla" | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="savePage()">
            {{ "Salva" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="connectingToCategoryOrService" class="modal-body">
        <div class="w-100 d-flex">
          <div style="width: 20%; border: solid 1px black">
            <div class="w-100">
              <div
                *ngFor="let element of categoriesLevel0.categories"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 0)"
                  >
                    <div class="type">
                      <span>{{ "Categoria" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let element of categoriesLevel0.services"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 0, true)"
                  >
                    <div class="type">
                      <span>{{ "Servizio" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%; border: solid 1px black">
            <div class="w-100">
              <div
                *ngFor="let element of categoriesLevel1.categories"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 1)"
                  >
                    <div class="type">
                      <span>{{ "Categoria" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let element of categoriesLevel1.services"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 1, true)"
                  >
                    <div class="type">
                      <span>{{ "Servizio" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%; border: solid 1px black">
            <div class="w-100">
              <div
                *ngFor="let element of categoriesLevel2.categories"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 2)"
                  >
                    <div class="type">
                      <span>{{ "Categoria" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let element of categoriesLevel2.services"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 2, true)"
                  >
                    <div class="type">
                      <span>{{ "Servizio" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%; border: solid 1px black">
            <div class="w-100">
              <div
                *ngFor="let element of categoriesLevel3.categories"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 3)"
                  >
                    <div class="type">
                      <span>{{ "Categoria" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let element of categoriesLevel3.services"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 3, true)"
                  >
                    <div class="type">
                      <span>{{ "Servizio" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%; border: solid 1px black">
            <div class="w-100">
              <div
                *ngFor="let element of categoriesLevel4.categories"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 4)"
                  >
                    <div class="type">
                      <span>{{ "Categoria" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let element of categoriesLevel4.services"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="height: 50px; cursor: pointer; width: 100%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                    (click)="selectItem(element, 4, true)"
                  >
                    <div class="type">
                      <span>{{ "Servizio" | translate }}</span>
                    </div>

                    <div
                      class="d-flex flex-center w-100 h-100 align-items-center"
                      id="{{ element.id }}"
                    >
                      <span class="category text-center">{{
                        element.title.it
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="connectingToCategoryOrService = false"
          >
            {{ "Annulla" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="confirmSelectedItem()"
          >
            {{ "Seleziona" | translate }}
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="rendering">
        <div class="w-100 d-flex justify-content-center">
          <!--

            horizontal

           -->
          <div
            class="render-box-h render-box"
            *ngIf="projectDetails.layout == 'Horizontal'"
            [ngClass]="{
              light: projectDetails.theme == 'Light',
              dark: projectDetails.theme == 'Dark'
            }"
          >
            <div
              [ngClass]="{ 'w-100': !page.text, 'w-45': page.text }"
              *ngIf="
                page.media.type != 0 &&
                (page.media.data ||
                  videoToLoad ||
                  currentMedia ||
                  id ||
                  extUrltemp)
              "
            >
              <div
                class="header-h d-flex justify-content-center align-items-center"
                *ngIf="page.header && !page.text"
              >
                <div class="text-center">HEADER</div>
              </div>
              <div
                class="media-container"
                [ngStyle]="{ height: calcHeight() }"
                [ngClass]="{
                  'media-cut':
                    page.media.layout == 'Cut' || page.media.layout == null,
                  'media-full': page.media.layout == 'Stretch',
                  'h-100': page.text
                }"
              >
                <img
                  *ngIf="
                    (page.media.type == 2 || page.media.type == 1) &&
                    currentMedia
                  "
                  [src]="currentMedia"
                  alt=""
                  class="img-stretch"
                /><img
                  *ngIf="page.media.type == 3 && currentMedia && id"
                  [src]="currentMedia"
                  alt=""
                  class="img-stretch"
                />
                <video
                  id="video"
                  *ngIf="page.media.type == 3 && currentMedia && videoToLoad"
                  autoplay="true"
                  muted="muted"
                  class="img-stretch"
                  [loop]="page.media.videorestart && page.timetype != 2"
                  oncanplay="this.play()"
                  onloadedmetadata="this.muted = true"
                >
                  <source [src]="currentMedia" type="video/mp4" />
                  <source [src]="currentMedia" type="video/webm" />
                </video>
                <div
                  class="external-url"
                  *ngIf="page.media.type == 4 && extUrltemp"
                >
                  <img src="assets/img/www icon.png" alt="" />
                </div>
              </div>
              <div
                class="footer-h d-flex justify-content-center align-items-center"
                *ngIf="page.footer && !page.text"
              >
                <div class="text-center">FOOTER</div>
              </div>
            </div>
            <div
              *ngIf="page.text"
              [ngClass]="{
                'w-55':
                  (page.media.data ||
                    videoToLoad ||
                    currentMedia ||
                    id ||
                    extUrltemp) &&
                  page.media.type != 0,
                'w-100': !(
                  (page.media.data ||
                    videoToLoad ||
                    currentMedia ||
                    id ||
                    extUrltemp) &&
                  page.media.type != 0
                )
              }"
            >
              <div
                class="header-h d-flex justify-content-center align-items-center"
                *ngIf="page.header"
              >
                <div class="text-center">HEADER</div>
              </div>

              <div
                class="d-flex justify-content-end flex-column"
                [ngStyle]="{ height: calcHeight() }"
              >
                <div class="texttitle-h">{{ page.texttitle }}</div>
                <div
                  class="h-100 overflow-hidden description-box description-box-h"
                >
                  <div class="text-h" [innerHTML]="getTextFromURI()"></div>
                </div>
              </div>
              <div
                class="footer-h d-flex justify-content-center align-items-center"
                *ngIf="page.footer"
              >
                <div class="text-center">FOOTER</div>
              </div>
            </div>
          </div>
          <!--

            vertical

           -->
          <div
            class="render-box-v render-box"
            *ngIf="projectDetails.layout == 'Vertical'"
            [ngClass]="{
              light: projectDetails.theme == 'Light',
              dark: projectDetails.theme == 'Dark'
            }"
          >
            <div class="d-flex flex-column h-100">
              <div
                class="header-v d-flex justify-content-center align-items-center"
                *ngIf="page.header"
              >
                <div class="text-center">HEADER</div>
              </div>
              <div class="content" [ngStyle]="{ height: calcHeight() }">
                <div
                  class="media-container"
                  *ngIf="
                    page.media.type != 0 &&
                    (page.media.data ||
                      videoToLoad ||
                      currentMedia ||
                      id ||
                      extUrltemp)
                  "
                  [ngClass]="{
                    'v-50': page.text,
                    'v-100': !page.text,
                    'media-cut':
                      page.media.layout == 'Cut' || page.media.layout == null,
                    'media-full': page.media.layout == 'Stretch'
                  }"
                >
                  <img
                    *ngIf="
                      (page.media.type == 2 || page.media.type == 1) &&
                      currentMedia
                    "
                    [src]="currentMedia"
                    alt=""
                    class="img-stretch"
                  /><img
                    *ngIf="page.media.type == 3 && currentMedia && id"
                    [src]="currentMedia"
                    alt=""
                    class="img-stretch"
                  />
                  <video
                    id="video"
                    *ngIf="page.media.type == 3 && currentMedia && videoToLoad"
                    autoplay="true"
                    muted="muted"
                    class="img-stretch"
                    [loop]="page.media.videorestart && page.timetype != 2"
                    oncanplay="this.play()"
                    onloadedmetadata="this.muted = true"
                  >
                    <source [src]="currentMedia" type="video/mp4" />
                    <source [src]="currentMedia" type="video/webm" />
                  </video>
                  <div
                    class="external-url"
                    *ngIf="page.media.type == 4 && extUrltemp"
                  >
                    <img src="assets/img/www icon.png" alt="" />
                  </div>
                </div>
                <div
                  class="text"
                  *ngIf="page.text"
                  [ngClass]="{
                    'v-50':
                      page.media.type != 0 &&
                      (page.media.data ||
                        imageToLoad.length > 0 ||
                        videoToLoad),
                    'v-100': !(
                      page.media.type != 0 &&
                      (page.media.data || imageToLoad.length > 0 || videoToLoad)
                    )
                  }"
                >
                  <div class="h-100 d-flex justify-content-end flex-column">
                    <div class="texttitle-v">{{ page.texttitle }}</div>
                    <div class="h-100 overflow-hidden description-box">
                      <div class="text-v" [innerHTML]="getTextFromURI()"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="footer-v d-flex justify-content-center align-items-center"
                *ngIf="page.footer"
              >
                <div class="text-center">FOOTER</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="rendering = false"
          >
            {{ "Chiudi" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Context menu -->
<div *ngIf="contextmenu">
  <div
    class="contextmenu"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <div *ngIf="contextmenu == 'copy'" (click)="copyElement()">
      {{ "Copia" | translate }}
    </div>
    <div *ngIf="contextmenu == 'paste'" (click)="pasteElement()">
      {{ "Incolla" | translate }}
    </div>
  </div>
</div>
