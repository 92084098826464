import { Component, OnInit } from '@angular/core';
import { generateId } from 'src/app/models/notification/notification';
import { WsProject } from 'src/app/models/ws-project/ws-project';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { WsProjectsService } from 'src/app/services/web-services/ws-projects.service';

@Component({
  selector: 'app-web-services',
  templateUrl: './web-services.component.html',
  styleUrls: ['./web-services.component.scss']
})
export class WebServicesComponent implements OnInit {
  searchValue: string;
  wsProjectsList: Array<WsProject>;
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  contextElement;
  isAdmin: boolean;

  constructor(
    private readonly wsProjectsService: WsProjectsService,
    private readonly notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.isAdmin = localStorage.getItem('HechRomeoRole') == 'Manager';

    this.wsProjectsService.getAllWsProjects(localStorage.getItem('HechRomeoAccount')).subscribe(res => {
      this.wsProjectsList = res;
    }, err => {
      console.log(err);
    });
  }

  //activates the menu with the coordinates
  onrightClick(event, element?, project?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    if (element) {
      this.contextmenu = 'copy';
    } else {
      this.contextmenu = 'paste';
    }
    this.contextElement = element;
    if (this.contextElement) {
      this.contextElement.vendor = localStorage.getItem('HechRomeoAccount');
      if (project) this.contextElement.project = true;
    }
  }

  deleteWsProject(wsProjectId: string): void {
    this.wsProjectsService.deleteWsProject(wsProjectId).subscribe(res => {
      this.wsProjectsList.splice(this.wsProjectsList.findIndex(project => project.id === wsProjectId), 1);

      const notification = {
        id: generateId(),
        image: undefined,
        title: "Completato",
        body: `Cancellazione avvenuta con successo`,
      };
      this.notificationService.addNotification(notification);
    }, err => {
      console.log(err);
    })
  }
}
