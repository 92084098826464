import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { Calendar } from '@fullcalendar/core';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
declare var $: any;
import * as moment from 'moment';
import { Filters } from 'src/app/models/filters/filters';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import {
  Category,
  CategoryTree,
  Service,
} from 'src/app/models/categoryTree/category-tree';
import { LabelInfo, Reservation } from 'src/app/models/reservation/reservation';
import { Label } from 'src/app/models/label/label';
import { LabelService } from 'src/app/services/label/label.service';
import { Cart, CartItems } from 'src/app/models/cart/cart';
import { DepartmentService } from '../../../services/department/department.service';
import { Department } from '../../../models/department/department';
import { Router } from '@angular/router';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';
import { AccountService } from 'src/app/services/account/account.service';
import { element } from 'protractor';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-calendar-report',
  templateUrl: './calendar-report.component.html',
  styleUrls: ['./calendar-report.component.scss'],
})
export class CalendarReportComponent implements OnInit, AfterViewInit {
  account: any = localStorage.getItem('HechRomeoAccount');
  isUser: boolean = localStorage.getItem('HechRomeoRole') === 'User' ? true : false;
  events: any = [];
  services: any = [];
  filter = undefined;
  calendar;
  previousDate;
  previousEvent;
  eventSelected;
  reservationToCancel = {
    reservationid: undefined,
    servicedate: undefined,
    servicetime: undefined,
    email: undefined,
    serviceid: undefined,
  };
  tzoffset = new Date().getTimezoneOffset() * 60000;

  filters: Filters = new Filters();
  newFilters: Filters = new Filters();
  categoryTree: CategoryTree;
  allCategories: Category[] = [];
  allServices: Service[] = [];
  cartServices: Service[] = [];
  cartServicesNotActivated: Service[] = [];
  newReservation: Reservation = new Reservation();
  newCart: Cart = new Cart();
  selectedService;
  selectedLabel;
  allLabels: Label[] = [];
  availableLabels: Label[] = [];
  categoriesSelect = {
    selectedCategory0: undefined,
    selectedCategory1: undefined,
    selectedCategory2: undefined,
    selectedCategory3: undefined,
    categoryObj0: { categories: [] },
    categoryObj1: { categories: [] },
    categoryObj2: { categories: [] },
    categoryObj3: { categories: [] },
    currentServiceList: [],
    selectedServiceInList: undefined,
  };
  filterDate = { from: '', to: '' };
  newFilterDate;
  newCategoriesSelect;
  hideCalendar = false;
  cartLabelsList = [];
  isDetailsService: boolean = false;
  listSearchRequest = [];
  listSearchRequestShopping = [];
  totalSearchRequest: number = 0;
  totalFee: number = 0;
  totalServices: number = 0;
  totalNumber: number = 0;
  timeslots = [];

  allDepartments: Department[];
  lastEvSelected: any = undefined;
  vendorList = [];
  virtualCategories = [];
  monthView = false;

  eventsSubject: Subject<void> = new Subject<void>();

  viewDeleteReservation: boolean = false;

  @ViewChild('schedulerObj') schedulerObj: any;

  constructor(
    private calendarService: CalendarService,
    private categoriesService: CategoriesService,
    private labelService: LabelService,
    private departmentService: DepartmentService,
    private router: Router,
    private adminService: AdminServiceService,
    public accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    const that = this;
    this.listSearchRequest = [];
    this.listSearchRequestShopping = [];
    this.totalSearchRequest = 0;
    this.totalFee = 0;
    this.totalServices = 0;
    this.totalNumber = 0;
    this.filters.account = this.account;
    this.newFilters.account = this.account;
    const calendarEl = document.getElementById('calendar-report');
    this.newCategoriesSelect = { ...this.categoriesSelect };
    this.newFilterDate = { ...this.filterDate };
    this.isDetailsService = false;
    if(this.accountService.isUser) {
      this.accountService.getLinkedVendors(this.accountService.account).subscribe(res => {
        const vendorAccount = res.users[0].account;
        this.getVendorList(vendorAccount);
        this.getAllCategories(vendorAccount);
        this.getAllLabels();
        this.getAllDepartments();
      });
    } else {
      this.getVendorList(this.accountService.account);
      this.getAllCategories(this.accountService.account);
      this.getAllLabels();
      this.getAllDepartments();
    }

    // this.calendar = new Calendar(calendarEl, {
    //   plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    //   locales: itLocale,
    //   locale: 'it',
    //   header: {
    //     left: 'prev,next today',
    //     center: 'title',
    //     right:
    //       'listYear, resourceTimelineYear, dayGridMonth,timeGridWeek,timeGridDay',
    //   },
    //   buttonText: {
    //     today: 'Periodo Attuale',
    //   },
    //   viewSkeletonRender: (info) => {
    //     if (info.view.type == 'dayGridMonth') {
    //       this.monthView = true;
    //     } else {
    //       this.monthView = false;
    //     }
    //     this.getDataForCalendar();
    //   },
    //   dateClick: (info) => {
    //     // alert('Clicked on: ' + info.dateStr);
    //     // alert('Current view: ' + info.view.type);
    //     // change the day's background color just for fun
    //     // if (this.previousDate) {
    //     //   this.previousDate.dayEl.style.backgroundColor = 'white';
    //     // }
    //     // info.dayEl.style.backgroundColor = 'lightGrey';
    //     // this.previousDate = info;
    //   },
    //   eventClick: (info) => {
    //     // const eventclicked = new Date(
    //     //   info.event.start.getTime() - this.tzoffset
    //     // )
    //     //   .toISOString()
    //     //   .substring(0, 10)
    //     //   .split('-')
    //     //   .reverse()
    //     //   .join('/');
    //
    //     that.eventSelected = info.event.extendedProps;
    //     // console.log('event', eventclicked, info.event.extendedProps);
    //
    //     // that.getDaily(that.account, eventclicked, '');
    //     if (
    //       !this.isDetailsService ||
    //       !this.lastEvSelected ||
    //       this.lastEvSelected.id !== that.eventSelected.ev.id
    //     ) {
    //       if (that.eventSelected.cart) {
    //         this.getShoppingCartDetails(
    //           that.eventSelected.ev.vendor,
    //           that.eventSelected.ev
    //         );
    //       } else {
    //         that.getReservationDetails(
    //           that.eventSelected.ev.vendor,
    //           that.eventSelected.ev
    //         );
    //       }
    //
    //       // change the border color just for fun
    //       if (this.previousEvent) {
    //         this.previousEvent.el.style.borderColor = '';
    //       }
    //       info.el.style.borderColor = 'red';
    //       this.previousEvent = info;
    //
    //       this.isDetailsService = true;
    //       this.lastEvSelected = that.eventSelected.ev;
    //     } else {
    //       this.isDetailsService = false;
    //       this.lastEvSelected = undefined;
    //       info.el.style.borderColor = '';
    //       this.searchRequest();
    //     }
    //   },
    //   defaultView: 'timeGridWeek',
    //   selectable: true,
    //   navLinks: true,
    // });
    //
    // this.calendar.render();
    // this.calendar.setOption('locale', 'it');
    //
    // $(document).on('click', '.fc-today-button', () => {
    //   that.calendar.removeAllEvents();
    //
    //   const notbefore = new Date(that.calendar.view.activeStart)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //   const notafter = new Date(that.calendar.view.activeEnd)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //
    //   that.filters.period.from = notbefore;
    //   that.filters.period.to = notafter;
    //   that.searchRequest();
    // });
    //
    // $(document).on('click', '.fc-prev-button', () => {
    //   console.log('PREV-----------');
    //   that.calendar.removeAllEvents();
    //
    //   const notbefore = new Date(that.calendar.view.activeStart)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //   const notafter = new Date(that.calendar.view.activeEnd)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //   that.filters.period.from = notbefore;
    //   that.filters.period.to = notafter;
    //   that.searchRequest();
    // });
    //
    // $(document).on('click', '.fc-next-button', () => {
    //   that.calendar.removeAllEvents();
    //
    //   const notbefore = new Date(that.calendar.view.activeStart)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //   const notafter = new Date(that.calendar.view.activeEnd)
    //     .toISOString()
    //     .substring(0, 10)
    //     .split('-')
    //     .reverse()
    //     .join('/');
    //   that.filters.period.from = notbefore;
    //   that.filters.period.to = notafter;
    //   that.searchRequest();
    // });
    //
    // $(document).on('click', '.fc-dayGridMonth-button', () => {
    //   if (this.lastEvSelected) {
    //     this.getDataForCalendar();
    //   }
    // });
    //
    // $(document).on('click', '.fc-timeGridWeek-button', () => {
    //   if (this.lastEvSelected) {
    //     this.getDataForCalendar();
    //   }
    // });
    //
    // $(document).on('click', '.fc-timeGridDay-button', () => {
    //   if (this.lastEvSelected) {
    //     this.getDataForCalendar();
    //   }
    // });

    // mini
    // const today: any = new Date();
    // let currentMonth: any = today.getMonth();
    // let currentYear: any = today.getFullYear();
    // const selectYear: any = document.getElementById('year');
    // const selectMonth: any = document.getElementById('month');
    //
    // const months = [
    //   'Gen',
    //   'Feb',
    //   'Mar',
    //   'Apr',
    //   'Mag',
    //   'Giu',
    //   'Lug',
    //   'Ago',
    //   'Set',
    //   'Ott',
    //   'Nov',
    //   'Dic',
    // ];
    //
    // const monthAndYear = document.getElementById('monthAndYear');
    // // showCalendar(currentMonth, currentYear);
    //
    // // let mininextButton: any = document.getElementById('next');
    // // mininextButton.addEventListener('click', function () {
    // //   next();
    // // });
    //
    // function next() {
    //   currentYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    //   currentMonth = (currentMonth + 1) % 12;
    //   // showCalendar(currentMonth, currentYear);
    // }
    //
    // function previous() {
    //   currentYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    //   currentMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    //   // showCalendar(currentMonth, currentYear);
    // }

    // function showCalendar(month, year) {
    //   let firstDay = new Date(year, month).getDay();
    //   let daysInMonth = 32 - new Date(year, month, 32).getDate();

    //   let tbl = document.getElementById('calendar-body'); // body of the calendar

    //   // clearing all previous cells
    //   tbl.innerHTML = '';

    //   // filing data about month and in the page via DOM.
    //   monthAndYear.innerHTML = months[month] + ' ' + year;
    //   selectYear.value = year;
    //   selectMonth.value = month;

    //   // creating all cells
    //   let date: any = 1;
    //   for (let i = 0; i < 6; i++) {
    //     // creates a table row
    //     let row = document.createElement('tr');

    //     //creating individual cells, filing them up with data.
    //     for (let j = 0; j < 7; j++) {
    //       if (i === 0 && j < firstDay) {
    //         let cell = document.createElement('td');
    //         let cellText = document.createTextNode('');
    //         cell.appendChild(cellText);
    //         row.appendChild(cell);
    //       } else if (date > daysInMonth) {
    //         break;
    //       } else {
    //         let cell = document.createElement('td');
    //         let cellText = document.createTextNode(date);
    //         if (
    //           date === today.getDate() &&
    //           year === today.getFullYear() &&
    //           month === today.getMonth()
    //         ) {
    //           cell.classList.add('bg-info');
    //         } // color today's date
    //         cell.appendChild(cellText);
    //         row.appendChild(cell);
    //         date++;
    //       }
    //     }

    //     tbl.appendChild(row); // appending each row into calendar body.
    //   }
    // }

    // mini

    // var notbefore = new Date(this.calendar.view.activeStart)
    //   .toISOString()
    //   .substring(0, 10)
    //   .split('-')
    //   .reverse()
    //   .join('/');
    // var notafter = new Date(this.calendar.view.activeEnd)
    //   .toISOString()
    //   .substring(0, 10)
    //   .split('-')
    //   .reverse()
    //   .join('/');

    // this.getCalendar(this.account, notbefore, notafter);

    // setInterval(() => {
    //   if (this.router.url === '/calendar') {
    //     this.searchRequest();
    //   }
    // }, 300000);
  }

  ngAfterViewInit() {
    this.searchRequest();

    setInterval(() => {
      if (this.router.url === '/calendar') {
        this.searchRequest();
      }
    }, 300000);
  }

  getVendorList(account: string) {
    this.accountService
      .getVedorAccount(account)
      .subscribe((data) => {
        this.vendorList = data;
      });
  }

  makeReservation() {
    if (this.selectedService.isadvanced) {
      this.newReservation.account = localStorage.getItem('HechRomeoAccount');
      this.newReservation.servicedate = new Date(
        this.newReservation.servicedate
      )
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      this.newReservation.reservationdate = new Date()
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      var d = new Date();
      var h = this.addZero(d.getHours());
      var m = this.addZero(d.getMinutes());
      this.newReservation.reservationtime = h + ':' + m;

      if (this.selectedLabel) {
        // this.newReservation.labelinfos.labelid = this.selectedLabel.id;
      } else {
        this.newReservation.labelinfos = undefined;
      }

      console.log(
        'risultati',
        $('#timeslots option:selected').map(function (a, item) {
          return item.value;
        })
      );

      let selectedIds = $('#timeslots option:selected').map(function (a, item) {
        return item.value;
      });
      let selectedSlots = [];
      for (let element of selectedIds) {
        let tempSlot = this.timeslots.find((obj) => obj.id == element);
        selectedSlots.push({
          timeslot: tempSlot.timeslottext,
          quantity: this.newReservation.quantity,
        });
      }

      let reservation = {
        account: this.selectedService.parentaccount,
        serviceid: this.newReservation.serviceid,
        servicedate: this.newReservation.servicedate,
        name: this.newReservation.name,
        lastname: this.newReservation.lastname,
        email: this.newReservation.email,
        transactionId: '',
        labelinfo: this.newReservation.labelinfos
          ? this.newReservation.labelinfos
          : null,
        slots: selectedSlots,
        isfrompartner: true,
        partner: localStorage.getItem('HechRomeoAccount'),
      };

      this.calendarService
        .advancedServicesMenagerReservation(reservation)
        .subscribe((data) => {
          this.resetReservation();
          this.searchRequest();
        });

      // this.calendarService
      //   .makeReservation(this.newReservation)
      //   .subscribe((data) => {
      //     this.resetReservation();
      //     this.searchRequest();
      //   });
    } else {
      console.log(this.selectedService);
      this.newReservation.account = this.selectedService.parentaccount;
      this.newReservation.isfrompartner = true;
      this.newReservation.partner = localStorage.getItem('HechRomeoAccount');
      this.newReservation.servicedate = new Date(
        this.newReservation.servicedate
      )
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      this.newReservation.reservationdate = new Date()
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      var d = new Date();
      var h = this.addZero(d.getHours());
      var m = this.addZero(d.getMinutes());
      this.newReservation.reservationtime = h + ':' + m;

      if (this.selectedLabel) {
        // this.newReservation.labelinfos.labelid = this.selectedLabel.id;
      } else {
        this.newReservation.labelinfos = undefined;
      }

      this.calendarService
        .makeReservation(this.newReservation)
        .subscribe((data) => {
          this.resetReservation();
          this.searchRequest();
        });
    }
  }

  insertCart() {
    let err = false;
    for (let element of this.newCart.items) {
      if (element.quantity < 1) {
        err = true;
        document
          .getElementById('err-' + element.serviceid)
          .classList.remove('d-none');
      } else {
        document
          .getElementById('err-' + element.serviceid)
          .classList.add('d-none');
      }
    }
    if (!err) {
      this.newCart.account = localStorage.getItem('HechRomeoAccount');
      // this.newCart.labelinfos.labelid = this.selectedLabel;
      this.calendarService
        .insertShoppingCart(this.newCart)
        .subscribe((data) => {
          this.resetCart();
          this.searchRequest();
        });
      document.getElementById('confirm-cart').click();
    }
  }

  getAllLabels(): void {
    if (this.isUser) {
      this.labelService.getLinkedVendorLabels(this.accountService.vendor).subscribe((data) => {
        this.allLabels = data.labels;
      });
    } else {
      this.labelService.getLabels().subscribe((data) => {
        this.allLabels = data.labels;
      });
    }
  }

  getAllDepartments(): void {
    if (this.isUser) {
      this.departmentService.getLinkedVendorDepartments(this.accountService.vendor).subscribe(data => {
        this.allDepartments = data.departments;
      });
    } else {
      this.departmentService.getDepartments().subscribe((data) => {
        this.allDepartments = data.departments;
      });
    }
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  changeSelectedService() {
    this.newReservation.serviceid = this.selectedService.id;
    this.newReservation.decreasequantity =
      this.selectedService.decreasequantity;
    this.selectedLabel = undefined;
    if (this.selectedService.labels != null) {
      this.availableLabels = JSON.parse(
        JSON.stringify(this.selectedService.labels)
      );
      console.log('AVAILABLE LABELS', this.availableLabels);
    } else {
      this.availableLabels = [];
    }

    if (this.selectedService.isadvanced) {
      console.log('AVANZATO');

      $('#timeslots').multiselect();
      $('#timeslots').multiselect('dataprovider', []);
      if (this.newReservation.servicedate) {
        this.newReservationChangedDate();
      }
    }
  }

  addItemToCart() {
    let err = false;
    console.log('Selezionato', this.selectedService);
    if (this.selectedService.labels) {
      if (this.cartLabelsList.length == 0) {
        for (let element of this.selectedService.labels) {
          this.cartLabelsList.push(element);
        }
      } else {
        const filteredArray = this.selectedService.labels.filter(
          (value) =>
            this.cartLabelsList.filter((obj) => value.id == obj.id).length > 0
        );
        if (filteredArray.length == 0) {
          err = true;
        } else {
          this.cartLabelsList = [];
          for (let element of filteredArray) {
            this.cartLabelsList.push(element);
          }
        }
      }
    } else {
      err = true;
    }

    if (err) {
      document.getElementById('err-label').classList.remove('d-none');
    } else {
      document.getElementById('err-label').classList.add('d-none');
    }
    if (!err) {
      let newItem: CartItems = new CartItems();
      newItem.serviceid = this.selectedService.id;
      newItem.title = this.selectedService.title.it;
      this.newCart.items.push(newItem);
      this.cartServicesNotActivated = this.cartServicesNotActivated.filter(
        (obj) => obj.id != this.selectedService.id
      );
      this.selectedService = undefined;
    }

    // this.newReservation.serviceid = this.selectedService.id;
    // this.newReservation.decreasequantity = this.selectedService.decreasequantity;
    // this.selectedLabel = undefined;
    // if (this.selectedService.idlabels != null) {
    //   this.availableLabels = this.allLabels.filter(obj => {
    //     let check = false;
    //     for (let element of this.selectedService.idlabels) {
    //       if (obj.id == element) {
    //         check = true;
    //       }
    //     }
    //     return check;
    // })
    // } else {
    //   this.availableLabels = [];
    // }
  }

  getLabelName(label) {
    return this.allLabels.find((obj) => obj.id == label).name.it;
  }

  deleteItemFromCart(item) {
    this.newCart.items = this.newCart.items.filter(
      (obj) => obj.serviceid != item.serviceid
    );
    this.cartServicesNotActivated.push(
      this.cartServices.find((obj) => obj.id == item.serviceid)
    );
  }

  toggleCalendar() {
    document.getElementById('calendar-report').style.display = this.hideCalendar
      ? 'none'
      : 'block';
    document
      .getElementById('minicalendar')
      .classList.remove(!this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
    document
      .getElementById('minicalendar')
      .classList.add(this.hideCalendar ? 'col-lg-12' : 'col-lg-4');
  }

  getDataForCalendar() {
    this.calendar.removeAllEvents();
    var notbefore = new Date(this.calendar.view.activeStart)
      .toISOString()
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/');
    var notafter = new Date(this.calendar.view.activeEnd)
      .toISOString()
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/');

    // this.getCalendar(this.account, notbefore, notafter);

    this.filters.period.from = notbefore;
    this.filters.period.to = notafter;
    this.newFilters.period.from = notbefore;
    this.newFilters.period.to = notafter;
    this.searchRequest();
  }

  getReservationDetails(account, event) {
    let reservationids = [];
    for (let element of event.reservations) {
      reservationids.push(element.reservationid);
    }
    this.services = [];
    let searchObj = {
      account,
      serviceid: event.serviceid,
      reservationids: reservationids,
      servicedate: event.servicedate,
      servicetime: event.servicetime,
    };
    this.calendarService.getReservationDetails(searchObj).subscribe(
      (calendar) => {
        this.services = calendar;
        this.services.account = account;
        this.totalSearchRequest = 0;
        this.totalFee = 0;
        this.totalServices = 0;
        this.totalNumber = 0;

        for (let ev of this.services.reservations) {
          this.totalNumber += 1;

          if (ev.quantity) {
            this.totalServices += ev.quantity;
          } else {
            this.totalServices += 1;
          }

          if (ev.amount) {
            if (!ev.isdiscount) {
              if (ev.quantity > 0) {
                this.totalFee = this.totalFee + ev.fee * ev.quantity;
              } else {
                this.totalFee = this.totalFee + ev.fee;
              }
            } else {
              this.totalFee = this.totalFee + (ev.fee * ev.amount) / 100;
            }
            this.totalSearchRequest = this.totalSearchRequest + ev.amount;
          }
        }
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  getShoppingCartDetails(account, event) {
    let reservationids = [];

    this.services = {};
    let searchObj = {
      account,
      shoppingcartid: event.id,
    };
    this.calendarService.getShoppingCartDetails(searchObj).subscribe(
      (calendar) => {
        this.services = calendar;
        this.services.cart = true;
        this.totalSearchRequest = 0;
        this.totalFee = 0;
        this.totalServices = 0;
        this.totalNumber = 0;

        if (!this.filters.service) {
          if (this.services.amount) {
            this.totalSearchRequest =
              this.totalSearchRequest + this.services.amount;
          }

          this.totalNumber += this.services.services.length;

          for (let item of this.services.services) {
            if (item.quantity) {
              this.totalServices += item.quantity;
            } else {
              this.totalServices += 1;
            }
          }
        } else {
          for (let item of this.services.services) {
            if (item.serviceid === this.filters.service) {
              this.totalNumber += 1;

              if (item.quantity) {
                this.totalServices += item.quantity;
              } else {
                this.totalServices += 1;
              }

              if (item.amount) {
                this.totalSearchRequest += item.amount;
              }
            }
          }
        }
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  selectCategory(currentObj) {
    this.clearOtherObjects(currentObj);
    if (currentObj != 3) {
      this.newCategoriesSelect['categoryObj' + (currentObj + 1)] =
        this.newCategoriesSelect['categoryObj' + currentObj].categories.find(
          (obj) =>
            obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
        );
      this.newCategoriesSelect.currentServiceList =
        this.newCategoriesSelect['categoryObj' + (currentObj + 1)].services;
    } else {
      this.newCategoriesSelect.currentServiceList = this.newCategoriesSelect[
        'categoryObj' + currentObj
      ].categories.find(
        (obj) =>
          obj.id == this.newCategoriesSelect['selectedCategory' + currentObj]
      ).services;
    }
  }

  clearOtherObjects(currentObj) {
    for (let x = currentObj + 1; x < 4; x++) {
      this.newCategoriesSelect['selectedCategory' + x] = undefined;
      this.newCategoriesSelect['categoryObj' + x] = { categories: [] };
    }
    this.newCategoriesSelect.selectedServiceInList = undefined;
    this.newCategoriesSelect.currentServiceList = [];
  }

  getAllCategories(account: string) {
    let request = {
      account: account,
      language: 'it',
    };
    this.categoriesService.publicCategoryGet(request).subscribe((data) => {
      this.chechVirtual(data.categories.categories);
      this.allCategories = data.categories.categories;
      this.categoryTree = data.categories;
      this.categoriesSelect.categoryObj0 = { ...data };
      this.newCategoriesSelect = { ...this.categoriesSelect };
      let tree = '';
      this.exporeCategories(this.allCategories, tree);
    });
  }

  chechVirtual(data) {
    let parentVendor;
    for (let element of data) {
      parentVendor = element.parentaccount;
      if (parentVendor) {
        this.virtualCategories.push(element);
      }
      this.setVirtualServices(element, parentVendor);
    }
  }

  setVirtualServices(list, parentVendor) {
    for (let element of list.services) {
      if (parentVendor) {
        element.parentaccount = parentVendor;
      }
    }
    for (let element of list.categories) {
      if (!element.parentaccount) {
        element.parentaccount = parentVendor;
      } else {
        this.virtualCategories.push(element);
      }
      this.setVirtualServices(element, element.parentaccount);
    }
  }

  exporeCategories(array, tree) {
    for (let element of array) {
      if (tree.length > 0) {
        tree = tree.concat(' > ');
      }
      tree = tree.concat(element.title.it);
      this.exporeCategories(element.categories, tree);
      for (let item of element.services) {
        item.tree = tree;
        if (
          item.deleted == false &&
          item.cartenabled == false &&
          item.visible == true &&
          item.parentaccount
        ) {
          this.allServices.push(item);
        }

        if (
          item.deleted == false &&
          item.cartenabled == true &&
          item.parentaccount
        ) {
          this.cartServices.push(item);
          this.cartServicesNotActivated.push(item);
        }
      }
      let i = tree.lastIndexOf('>');
      tree = tree.substring(0, i - 1);
    }
  }

  resetReservation() {
    this.newReservation = new Reservation();
    this.selectedService = undefined;
    this.selectedLabel = undefined;
  }
  resetCart() {
    this.newCart = new Cart();
    this.cartServicesNotActivated = [];
    for (let element of this.cartServices) {
      this.cartServicesNotActivated.push(element);
    }
    this.selectedService = undefined;
    this.selectedLabel = undefined;
    this.cartLabelsList = [];
  }

  async searchRequest() {
    this.isDetailsService = false;
    this.lastEvSelected = undefined;
    this.calendar.removeAllEvents();
    this.filters = { ...this.newFilters };
    this.categoriesSelect = { ...this.newCategoriesSelect };
    this.filterDate = { ...this.newFilterDate };
    if (
      this.filterDate.from &&
      this.filterDate.from.length > 0 &&
      this.filterDate.to &&
      this.filterDate.to.length > 0
    ) {
      let notbefore = new Date(this.filterDate.from)
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      let notafter = new Date(this.filterDate.to)
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
      this.filters.period.from = notbefore;
      this.filters.period.to = notafter;
    }

    for (let x = 0; x < 4; x++) {
      if (this.categoriesSelect['selectedCategory' + x]) {
        if (x == 0) {
          this.filters.categoryroot =
            this.categoriesSelect.categoryObj0.categories.find(
              (obj) => obj.id == this.categoriesSelect.selectedCategory0
            ).id;
        } else {
          this.filters['categoryL' + x] = this.categoriesSelect[
            'categoryObj' + x
          ].categories.find(
            (obj) => obj.id == this.categoriesSelect['selectedCategory' + x]
          ).id;
        }
      } else {
        this.filters['categoryL' + x] = undefined;
      }
    }
    if (this.categoriesSelect.selectedServiceInList) {
      this.filters.service = this.categoriesSelect.selectedServiceInList;
    }

    // this.filters.period.from = "";
    // this.filters.period.to = "";
    let data;
    let otherData;
    let advancedData;
    let advancedOtherData;
    if (
      this.filters.type == -1 ||
      this.filters.type == undefined ||
      this.filters.type == 1
    ) {
      data = await this.calendarService
        .myReportSearch(this.filters)
        .toPromise();
      advancedData = await this.calendarService
        .myReportAdvancedSearch(this.filters)
        .toPromise();
    }

    if (
      this.filters.type == -1 ||
      this.filters.type == undefined ||
      this.filters.type == 0
    ) {
      otherData = await this.calendarService
        .reportSearch(this.filters)
        .toPromise();
      advancedOtherData = await this.calendarService
        .reportAdvancedSearch(this.filters)
        .toPromise();
    }

    if (this.filters.type == -1 || this.filters.type == undefined) {
      for (let element in otherData.days) {
        if (data.days[element]) {
          data.days[element] = data.days[element].concat(
            otherData.days[element]
          );
        } else {
          data.days[element] = otherData.days[element];
        }
      }
      if (!data.shoppingcarts.days) {
        data.shoppingcarts = { days: {} };
      }
      if (!otherData.shoppingcarts.days) {
        otherData.shoppingcarts = { days: {} };
      }
      for (let element in otherData.shoppingcarts.days) {
        if (data.shoppingcarts.days[element]) {
          data.shoppingcarts.days[element] = data.shoppingcarts.days[
            element
          ].concat(otherData.shoppingcarts.days[element]);
        } else {
          data.shoppingcarts.days[element] =
            otherData.shoppingcarts.days[element];
        }
      }

      for (let element in advancedOtherData.days) {
        if (advancedData.days[element]) {
          advancedData.days[element] = advancedData.days[element].concat(
            advancedOtherData.days[element]
          );
        } else {
          advancedData.days[element] = advancedOtherData.days[element];
        }
      }
    } else if (this.filters.type == 1) {
    } else if (this.filters.type == 0) {
      data = otherData;
      advancedData = advancedOtherData;
    }

    this.listSearchRequest = [];
    this.listSearchRequestShopping = [];
    this.totalSearchRequest = 0;
    this.totalFee = 0;
    this.totalServices = 0;
    this.totalNumber = 0;
    this.events = data.days;

    const that = this;
    Object.keys(this.events).forEach((key) => {
      const dataevento = key.split('/').reverse().join('-');

      that.events[key].forEach((ev) => {
        const oravento = ev.servicetime;

        this.totalNumber += ev.reservations.length;
        let total = 0;
        if (ev.reservations.length > 0) {
          for (let reservation of ev.reservations) {
            if (reservation.quantity) {
              this.totalServices += reservation.quantity;
              total += reservation.quantity;
            } else {
              this.totalServices += 1;
              total += 1;
            }
          }
        }

        if (ev.amount) {
          if (ev.isdiscount) {
            this.totalFee = this.totalFee + (ev.fee * ev.amount) / 100;
          } else {
            this.totalFee = this.totalFee + ev.fee * total;
          }

          this.totalSearchRequest = this.totalSearchRequest + ev.amount;
        }

        let className: string;
        let status = Math.min.apply(
          null,
          ev.reservations.map((reservation) => reservation.orederstatus)
        );

        switch (status) {
          case 0:
            className = 'submitted-event';
            break;
          case 1:
            className = 'accepted-event';
            break;
          case 2:
            className = 'refused-event';
            break;
          case 3:
            className = 'worked-event';
            break;
          case 4:
            className = 'aborted-event';
            break;
        }
        if (oravento && oravento != '') {
          that.calendar.addEvent({
            title: ev.servicename,
            start: dataevento + 'T' + oravento,
            extendedProps: { ev, cart: false },
            // color: 'rgb(174, 202, 65)',
            className,
          });
        } else {
          that.calendar.addEvent({
            title: ev.servicename,
            allDay: true,
            start: dataevento,
            className,
            extendedProps: { ev, cart: false },
          });
        }

        this.listSearchRequest.push(ev);
      });
    });

    this.events = data.shoppingcarts.days;
    if (this.events) {
      Object.keys(this.events).forEach((key) => {
        const dataevento = key.split('/').reverse().join('-');

        that.events[key].forEach((ev) => {
          const oravento = ev.shoppimgcarttime;

          if (!this.filters.service) {
            if (ev.amount) {
              this.totalSearchRequest = this.totalSearchRequest + ev.amount;
            }

            if (ev.fee) {
              if (ev.isdiscount) this.totalFee += (ev.amount * ev.fee) / 100;
              else this.totalFee += ev.fee;
            }

            this.totalNumber += ev.items.length;

            for (let item of ev.items) {
              if (item.quantity) {
                this.totalServices += item.quantity;
              } else {
                this.totalServices += 1;
              }
            }
          } else {
            for (let item of ev.items) {
              if (item.serviceid === this.filters.service) {
                this.totalNumber += 1;

                if (item.quantity) {
                  this.totalServices += item.quantity;
                } else {
                  this.totalServices += 1;
                }

                if (item.amount) {
                  this.totalSearchRequest += item.amount;
                }
              }
            }
          }

          let className: string;
          let status;
          // for (let element of ev.orederstatus) {
          //   if (element.orederstatus == 4) {
          //     status = 4;
          //     break;
          //   } else if (element.orederstatus == 2 || status == 2) {
          //     status = 2;
          //     continue;
          //   } else if (element.orederstatus == 0 || status == 0) {
          //     status = 0;
          //     break;
          //   } else if (element.orederstatus == 1 || status == 1) {
          //     status = 1;
          //     break;
          //   } else if (element.orederstatus == 3 || status == 3) {
          //     status = 3;
          //     break;
          //   }
          // }
          switch (ev.orederstatus) {
            case 0:
              className = 'submitted-event';
              break;
            case 1:
              className = 'accepted-event';
              break;
            case 2:
              className = 'refused-event';
              break;
            case 3:
              className = 'worked-event';
              break;
            case 4:
              className = 'aborted-event';
              break;
          }
          if (oravento && oravento != '') {
            that.calendar.addEvent({
              title: 'CARRELLO',
              start: dataevento + 'T' + oravento,
              // color: 'rgb(174, 202, 65)',
              className,
              extendedProps: { ev, cart: true },
            });
          } else {
            that.calendar.addEvent({
              title: 'CARRELLO',
              allDay: true,
              start: dataevento,
              className,
              extendedProps: { ev, cart: true },
            });
          }

          this.listSearchRequestShopping.push(ev);
        });
      });
    }

    this.events = advancedData.days;

    Object.keys(this.events).forEach((key) => {
      const dataevento = key.split('/').reverse().join('-');

      that.events[key].forEach((ev) => {
        const oravento = ev.servicetime;

        this.totalNumber += ev.reservations.length;
        let total = 0;
        if (ev.reservations.length > 0) {
          for (let reservation of ev.reservations) {
            if (reservation.quantity) {
              this.totalServices += reservation.quantity;
              total += reservation.quantity;
            } else {
              this.totalServices += 1;
              total += 1;
            }
          }
        }

        if (ev.amount) {
          if (ev.isdiscount) {
            this.totalFee = this.totalFee + (ev.fee * ev.amount) / 100;
          } else {
            this.totalFee = this.totalFee + ev.fee * total;
          }

          this.totalSearchRequest = this.totalSearchRequest + ev.amount;
        }

        let className: string;
        let status = Math.min.apply(
          null,
          ev.reservations.map((reservation) => reservation.orederstatus)
        );

        switch (status) {
          case 0:
            className = 'submitted-event';
            break;
          case 1:
            className = 'accepted-event';
            break;
          case 2:
            className = 'refused-event';
            break;
          case 3:
            className = 'worked-event';
            break;
          case 4:
            className = 'aborted-event';
            break;
        }
        let reservations = [];

        for (let element of ev.reservations) {
          if (
            reservations.filter((obj) =>
              this.arraysEqual(obj.slottext, element.slottext)
            ).length == 0
          ) {
            reservations.push(element);
          }
        }

        for (let element of reservations) {
          if (ev.serviceenddate) {
            let days = moment(ev.serviceenddate, 'DD/MM/YYYY').diff(
              moment(ev.servicedate, 'DD/MM/YYYY'),
              'days'
            );
            let x = 0;
            do {
              let currentDay = moment(ev.servicedate, 'DD/MM/YYYY');
              if (x > 0) {
                currentDay.add(x, 'days');
              }
              that.calendar.addEvent({
                title: ev.servicename,
                start:
                  currentDay.format('YYYY-MM-DD') +
                  'T' +
                  element.slottext[0].split('-')[0],
                end: this.monthView
                  ? moment(ev.serviceenddate, 'DD/MM/YYYY').format(
                      'YYYY-MM-DD'
                    ) +
                    'T' +
                    element.slottext[element.slottext.length - 1].split('-')[1]
                  : currentDay.format('YYYY-MM-DD') +
                    'T' +
                    element.slottext[element.slottext.length - 1].split('-')[1],
                extendedProps: { ev, cart: false },
                className,
              });
              x++;
              if (this.monthView) {
                x = days + 1;
              }
            } while (x <= days);
          } else {
            that.calendar.addEvent({
              title: ev.servicename,
              start: dataevento + 'T' + element.slottext[0].split('-')[0],
              end:
                dataevento +
                'T' +
                element.slottext[element.slottext.length - 1].split('-')[1],
              extendedProps: { ev, cart: false },
              className,
            });
          }
        }

        // if (oravento && oravento != '') {
        //   that.calendar.addEvent({
        //     title: ev.servicename,
        //     start: dataevento + 'T' + oravento,
        //     extendedProps: { ev, cart: false },
        //     // color: 'rgb(174, 202, 65)',
        //     className,
        //   });
        // } else {
        //   that.calendar.addEvent({
        //     title: ev.servicename,
        //     allDay: true,
        //     start: dataevento,
        //     className,
        //     extendedProps: { ev, cart: false },
        //   });
        // }

        this.listSearchRequest.push(ev);
      });
    });

    this.isDetailsService = false;
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  newReservationChangedDate() {
    if (this.selectedService.isadvanced) {
      console.log(this.newReservation.servicedate);
      this.categoriesService
        .getAdvancedServiceData(
          this.selectedService.id,
          moment(this.newReservation.servicedate, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          ),
          this.selectedService.parentaccount
        )
        .subscribe((data) => {
          this.timeslots = data.servicedata.timeslots;
          let options = [];
          for (let element of this.timeslots) {
            element.id = this.generateId();
            options.push({
              label: element.timeslottext,
              title: element.timeslottext,
              value: element.id,
            });
          }
          $('#timeslots').multiselect('dataprovider', options);
        });
    }
  }

  generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  getCalendar(account, notbefore, notafter) {
    this.calendarService.getCalendar(account, notbefore, notafter).subscribe(
      (calendar) => {
        this.events = calendar;
        this.events = this.events.days;

        const that = this;
        Object.keys(this.events).forEach((key) => {
          const dataevento = key.split('/').reverse().join('-');

          that.events[key].forEach((ev) => {
            const oravento = ev.servicetime;

            let className: string;
            switch (ev.workstatus) {
              case 0:
                className = 'submitted-event';
                break;
              case 1:
                className = 'accepted-event';
                break;
              case 2:
                className = 'refused-event';
                break;
              case 3:
                className = 'worked-event';
                break;
              case 4:
                className = 'aborted-event';
                break;
            }
            if (oravento && oravento != '') {
              that.calendar.addEvent({
                title: ev.title,
                start: dataevento + 'T' + oravento,
                // color: 'rgb(174, 202, 65)',
                className,
              });
            } else {
              that.calendar.addEvent({
                title: ev.title,
                allDay: true,
                start: dataevento,
                className,
              });
            }
          });
        });
      },
      (err) => {
        console.log('calendar err', err);
      }
    );
  }

  getDaily(account, notbefore, notafter) {
    this.services = [];
    this.calendarService.getDaily(account, notbefore, notafter).subscribe(
      (calendar) => {
        if (this.filter != undefined && this.filter != 'undefined') {
          for (let element of calendar.serviceReservation) {
            element.reservations = element.reservations.filter(
              (obj) => obj.orederstatus == this.filter
            );
          }

          calendar.shoppingCartList = calendar.shoppingCartList.filter(
            (obj) => obj.orederstatus == this.filter
          );
        }
        for (let element of calendar.shoppingCartList) {
          element.active = moment(
            element.shoppingcartdate,
            'DD-MM-YYYY'
          ).isSame(moment(), 'day');
        }
        this.services = calendar;
      },
      (err) => {
        console.log('calendar daily err', err);
      }
    );
  }

  changeReservationStatus(reservation, service, cart?) {
    if (cart) {
      if (parseInt(reservation.status) == 4) {
        let data = {
          owner: localStorage.getItem('HechRomeoAccount'),
          reservationid: '',
          servicedate: reservation.reservationdate,
          servicetime: '',
          accountemail: reservation.email,
          serviceid: reservation.shoppingcartid,
          ordertype: 1,
        };
        this.calendarService.cancelCart(data).subscribe(() => {
          this.getDataForCalendar();
        });
      } else {
        this.calendarService
          .changeStatusCart(
            reservation.shoppingcartid,
            localStorage.getItem('HechRomeoAccount'),
            parseInt(reservation.status)
          )
          .subscribe(() => {
            this.getDataForCalendar();
          });
      }
    } else {
      if (parseInt(reservation.status) == 4) {
        let ordertype = 0;
        this.cancelReservation(
          reservation.reservationid,
          service.servicedate,
          service.servicetime,
          reservation.email,
          service.serviceid,
          ordertype
        );
      } else {
        let changeStatus = {
          account: localStorage.getItem('HechRomeoAccount'),
          reservationid: reservation.reservationid,
          servicedate: service.servicedate,
          servicetime: service.servicetime,
          serviceid: service.serviceid,
          workedout: reservation.workedout,
          orederstatus: parseInt(reservation.status),
        };

        this.calendarService
          .changeReservationStatus(changeStatus)
          .subscribe(() => {
            this.getDataForCalendar();
          });
      }
    }
  }

  updateDaily() {
    if (this.eventSelected) {
      // this.getDaily(this.account, this.eventSelected, '');
      if (this.eventSelected.cart) {
        this.getShoppingCartDetails(this.account, this.eventSelected.ev);
      } else {
        this.getReservationDetails(this.account, this.eventSelected.ev);
      }
    }
    // this.getDataForCalendar();
  }

  clearFilter() {
    this.newFilters = new Filters();
    this.newFilters.account = this.account;
    this.newCategoriesSelect = {
      selectedCategory0: undefined,
      selectedCategory1: undefined,
      selectedCategory2: undefined,
      selectedCategory3: undefined,
      categoryObj0: { categories: [] },
      categoryObj1: { categories: [] },
      categoryObj2: { categories: [] },
      categoryObj3: { categories: [] },
      currentServiceList: [],
      selectedServiceInList: undefined,
    };
    this.newFilterDate = { from: '', to: '' };
    this.newCategoriesSelect.categoryObj0 = this.categoriesSelect.categoryObj0;
    this.newCategoriesSelect.selectedServiceInList =
      this.categoriesSelect.selectedServiceInList;
  }

  clearNewFilter() {
    this.newFilters = { ...this.filters };
    this.newCategoriesSelect = { ...this.categoriesSelect };
    this.newFilterDate = { ...this.filterDate };
  }

  print(): void {
    window.print();
  }

  cancelReservation(
    reservationid,
    servicedate,
    servicetiontime,
    accountemail,
    serviceid,
    ordertype
  ) {
    this.calendarService
      .cancelReservation(
        this.account,
        reservationid,
        servicedate,
        servicetiontime,
        accountemail,
        serviceid,
        ordertype,
        false,
        ''
      )
      .subscribe(
        () => {
          // L'elenco si aggiorna una volta eliminata la prenotazione
          // this.getDaily(this.account, this.eventSelected, '');
          if (this.eventSelected.cart) {
            this.getShoppingCartDetails(this.account, this.eventSelected.ev);
          } else {
            this.getReservationDetails(this.account, this.eventSelected.ev);
          }
          this.getDataForCalendar();
        },
        (err) => {
          console.log('cancel reservation error', err);
        }
      );
  }
  setReservationToCancel(
    reservationid,
    servicedate,
    servicetime,
    email,
    serviceid
  ) {
    this.reservationToCancel.reservationid = reservationid;
    this.reservationToCancel.servicedate = servicedate;
    this.reservationToCancel.servicetime = servicetime;
    this.reservationToCancel.email = email;
    this.reservationToCancel.serviceid = serviceid;
  }

  getStatusOrder(orderstatus: number): string {
    if (orderstatus >= 0) {
      switch (orderstatus) {
        case 0:
          return 'In Attesa';
        case 1:
          return 'Accettata';
        case 2:
          return 'Rifiutata';
        case 3:
          return 'Processata';
        case 4:
          return 'Cancellata';
        default:
          return '';
      }
    } else {
      return '';
    }
  }
  reservationModalOpened() {
    this.eventsSubject.next();
  }
}
