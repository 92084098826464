import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Channel, Clone, Frimware, Tv, TvSettings } from 'src/app/models/tv/tv';
import { AccountService } from 'src/app/services/account/account.service';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-tv-list',
  templateUrl: './tv-list.component.html',
  styleUrls: ['./tv-list.component.scss'],
})
export class TvListComponent implements OnInit {
  tvs: Array<Tv> = [];
  channels: Array<Channel> = [];
  clones: Array<Clone> = [];
  firmwares: Array<Frimware> = [];
  settings: Array<TvSettings> = [];
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  copiedElement;
  contextElement;
  searchValue: string;
  tvToDelete;

  constructor(
    private tvService: TvService,
    private router: Router,
    private translateService: TranslateService,
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.getTvs();
    this.getChannelsLists();
    this.getClones();
    this.getFirmwares();
    this.getSettings();
  }

  getTvs() {
    this.tvService.getTvs(this.accountService.vendor).subscribe((data: Tv[]) => {
      this.tvs = data;
    });
  }

  getChannelsLists() {
    this.tvService.getChannels(this.accountService.vendor).subscribe((data) => {
      this.channels = data;
    });
  }

  getClones() {
    this.tvService.getClones(this.accountService.vendor).subscribe((data) => {
      this.clones = data;
    });
  }

  getFirmwares() {
    this.tvService.getFirmwares().subscribe((data) => {
      this.firmwares = data;
    });
  }

  getSettings() {
    this.tvService.getSettings(this.accountService.vendor).subscribe((data) => {
      this.settings = data;
    });
  }

  editTv(id) {
    this.router.navigate(['editTv'], { queryParams: { id } });
  }

  nameOfElementInList(id: string, list: Array<any>) {
    let name = '';
    if (id) {
      name = list.find((obj) => obj.id === id)?.name;
    }
    return name;
  }

  deleteTv() {
    this.tvService.deleteTv(this.tvToDelete.id).subscribe((data) => {
      this.getTvs();
      this.getChannelsLists();
      this.getClones();
      this.getFirmwares();
      this.getSettings();
    });
  }

  onrightClick(event, element?, project?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    console.log('context menu', element);
    if (element) { this.contextmenu = 'copy'; }
    else { this.contextmenu = 'paste'; }
    this.contextElement = element;
    if (this.contextElement) {
      this.contextElement.vendor = localStorage.getItem('HechRomeoAccount');
      if (project) { this.contextElement.project = true; }
    }
  }

  disableContextMenu() {
    console.log('disable');
    this.contextmenu = undefined;
  }

  handlerToggleVisible(tv: Tv){
    if( tv.nameguest && tv.surnameguest && tv.message ){
      tv.visible =  !tv.visible;
      this.tvService.updateTv(tv).subscribe((data) => this.router.navigate(['tvList']));
    } else alert(this.translateService.instant('WELCOME_MSG.ATTENZIONE_CAMPI_VUOTI'))
  }
}
